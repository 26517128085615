import { React, useEffect, useState } from "react";

export const MinutesSince = ({ since }) => {

  const [minutes, setMinutes] = useState(0);

  function getElapsedInMinutes() {
    const elapsed = Date.now() - since;
    const inMinutes = elapsed / 1000 / 60;
    return inMinutes;
  }

  useEffect(() => {
    setMinutes(getElapsedInMinutes())
  })

  useEffect(() => {
    const myInterval = setInterval(() => {
      setMinutes(getElapsedInMinutes())
    }, 6000)
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <> Last refreshed
      {minutes < 1 && <> just now.</>}

      {minutes > 1 && <> {parseInt(minutes)} minute{minutes >= 2 ? 's' : ''} ago.</>}
    </>
  );

}