import React from "react";
import { connect } from "react-redux";

import { logErrorToServer } from "../services/Utilities";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logErrorToServer(
      -1,
      error,
      errorInfo,
      "From ErrorBoundary",
      this.props.appState,
      this.props.reduxState
    );
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong. Please refresh the app.</h1>;
    }

    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  return {
    reduxState: state,
  };
};

export default connect(mapStateToProps, null)(ErrorBoundary);
