import React from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { connect } from "react-redux";
import { doModifyLineItem } from "../actions/elog";

import Autocomplete from "./Autocomplete";

import {
  calculateDuration,
  getUom,
  getSprFields,
  isNumeric,
  logErrorToServer,
} from "../services/Utilities";

//applyAllSprs isn't removing fields with duplicate property values. So running more than it has to

class ProductFields extends React.Component {
  prodFieldRefs = [];

  constructor(props) {
    super(props);

    //const listX = this.props.listX

    if (this.props.fieldMap.length) {
      this.props.fieldMap.map((field, index) => {
        this.prodFieldRefs[index] = React.createRef();
      });
    }

    var fieldArray = {};
    const priorEntries = Object.assign({}, this.props.priorEntries);
    const fieldMapClone = Array.from(this.props.fieldMap);

    //if is editing line item
    if (this.props.editing) {
      const existingValues = this.props.editing;

      fieldMapClone.map((field) => {
        const fieldName = field.FieldName;
        const entryVal = existingValues.fieldValues[fieldName];

        if (entryVal) {
          fieldArray[field.FieldName] = entryVal;
        } else {
          fieldArray[field.FieldName] = undefined;
        }
      });
    }

    //if is creating new LI
    else {
      fieldMapClone.map((fieldx) => {
        let field = Object.assign({}, fieldx);

        const fieldName = field.FieldName;
        const fieldType = field.FieldType;
        let defaultType = field.DefaultType;

        if (defaultType) {
          defaultType = defaultType.toUpperCase();
        }

        if (fieldName === "ProdDesc") {
          field.Supplied = this.props.productDescription;
        }

        if (fieldName === "Product") {
          field.Supplied = this.props.currentProd;
        }

        if (fieldType === "DATEX") {
          field.Supplied = Date.now();
        }

        if (fieldType === "9") {
          if (field.Decimals) {
            if (field.Supplied && field.Supplied.charAt(0) !== "@") {
              field.Supplied = parseFloat(field.Supplied).toFixed(
                field.Decimals
              );
            }
          }
        } else if (fieldType === "LISTX") {
          if (
            fieldName &&
            fieldName.toUpperCase() === "UOM" &&
            this.props.currentProd
          ) {
            const defaultValue = getUom(
              this.props.prodMap,
              this.props.currentProd
            );
            field.Supplied = defaultValue;
          }
        }

        if (priorEntries) {
          const suppliedValEmpty = !field.Supplied && field.Supplied !== 0;

          if (
            (defaultType === "P" || defaultType === "PRIORENTRY") &&
            suppliedValEmpty
          ) {
            const priorEntry = priorEntries[fieldName];

            if (priorEntry) {
              field.Supplied = priorEntry;
            }
          }

          if (field.Supplied && field.Supplied.charAt(0) === "@") {
            const suppliedKey = field.Supplied.replace("@", "");
            if (suppliedKey) {
              const priorEntryVal = this.props.priorEntries[suppliedKey];

              if (!(!priorEntryVal && priorEntryVal !== 0)) {
                field.Supplied = priorEntryVal;
              } else {
                field.Supplied = undefined;
              }
            }
          }
        }

        fieldArray[field.FieldName] = field.Supplied;
      });
    }

    this.state = {
      currentPage: this.props.currentPage,
      fieldMap: fieldMapClone,
      fieldValues: fieldArray,
      listX: this.props.listX,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevState.fieldValues) !==
      JSON.stringify(this.state.fieldValues)
    ) {
      this.props.onModifyLineItem(this.state.fieldValues);
    }
  }

  componentDidMount() {
    //const listX = this.props.listX

    let firstTabStoppedField = null;

    this.props.fieldMap.map((field, index) => {
      if (field.TabStop === "Y" && firstTabStoppedField === null) {
        firstTabStoppedField = index + 2;
      }
    });

    if (this.prodFieldRefs.length > 0) {
      if (this.prodFieldRefs[firstTabStoppedField].current.firstInput) {
        this.prodFieldRefs[
          firstTabStoppedField
        ].current.firstInput.current.focus();
        this.prodFieldRefs[
          firstTabStoppedField
        ].current.firstInput.current.scrollIntoView();
      } else {
        this.prodFieldRefs[firstTabStoppedField].current.focus();
        this.prodFieldRefs[firstTabStoppedField].current.scrollIntoView();
      }
    }

    //this.setState({
    //currentPage: this.props.currentPage,
    //fieldMap: this.state.fieldMap,
    //}, function() {
    this.applyAllSprs(this.state.currentPage, this.state.fieldMap);
    //});
  }

  roundTimeTo = (time, roundTime) => {
    const hours = time.substring(0, 2);
    const minutes = time.substring(2, 4);
    const roundedTo = 60 * roundTime;

    let timeToReturn = new Date(1776, 6, 4, hours, minutes, 0, 0);

    timeToReturn.setMilliseconds(
      Math.round(timeToReturn.getMilliseconds() / 1000) * 1000
    );
    timeToReturn.setSeconds(
      Math.round(timeToReturn.getSeconds() / 60) * 60
    );
    timeToReturn.setMinutes(
      Math.round(timeToReturn.getMinutes() / roundedTo) * roundedTo
    );

    const timeString =
      String(timeToReturn.getHours()).padStart(2, "0") +
      String(timeToReturn.getMinutes()).padStart(2, "0");
    return timeString;
  }

  handleDateChange = (date, fieldName) => {
    let currentFieldVals = Object.assign({}, this.state.fieldValues);
    currentFieldVals[fieldName] = date;

    this.setState({
      fieldValues: currentFieldVals,
    });

    this.props.updateFieldValues(currentFieldVals);
  };

  handleInputChange = (event, allCaps) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    if (allCaps) {
      event.target.value = value.toUpperCase();
      value = value.toUpperCase();
    }

    let currentFieldVals = Object.assign({}, this.state.fieldValues);
    currentFieldVals[name] = value;

    this.setState({
      fieldValues: currentFieldVals,
    });

    this.props.updateFieldValues(currentFieldVals);
  };

  handleAutocomplete = (name, obj) => {
    this.incrementFocus(name);

    let currentFieldVals = Object.assign(this.state.fieldValues);
    currentFieldVals[name] = obj;

    this.setState(
      {
        fieldValues: currentFieldVals,
      },
      () => {
        this.props.onModifyLineItem(this.state.fieldValues);
      }
    );

    this.props.updateFieldValues(currentFieldVals);
  };

  onBlur = (type, event, field) => {
    let fieldValues;

    if (field.Required) {
      if (event.target.value) {
        if (event.target.classList.contains("is-invalid")) {
          event.target.classList.remove("is-invalid");
        }
      } else {
        if (!event.target.classList.contains("is-invalid")) {
          event.target.classList.add("is-invalid");
          alert(`You must enter a value for ${field.Caption}`);
        }
      }
    }

    switch (type) {
      case "TIME":
        fieldValues = this.formatTime(event, field);
        break;

      case "DECIMAL":
        fieldValues = this.formatDecimal(event, field);
        break;

      default:
        fieldValues = Object.assign({}, this.state.fieldValues);
        break;
    }

    const fieldsAfterSprs = this.applySprs(field, fieldValues);
    this.setState({ fieldValues: fieldsAfterSprs });
  };

  formatTime = (event, field) => {
    const fieldName = field.FieldName;
    let currentFieldState = Object.assign({}, this.state.fieldValues);
    let fieldVal = event.target.value;

    if (fieldVal.length == 1) {
      currentFieldState[fieldName] = "0" + fieldVal + "00";
    } else if (fieldVal.length == 2) {
      currentFieldState[fieldName] = fieldVal + "00";
    } else if (fieldVal.length == 3) {
      currentFieldState[fieldName] = "0" + fieldVal;
    } else {
      currentFieldState[fieldName] = fieldVal;
    }

    if (currentFieldState[fieldName] == "2400") {
      currentFieldState[fieldName] = "0000";
    }

    if (this.props.roundTime) {
      const roundTime = this.props.roundTime;

      currentFieldState[fieldName] = this.roundTimeTo(
        currentFieldState[fieldName],
        roundTime
      );
    }

    if (
      Object.prototype.hasOwnProperty.call(currentFieldState, "Hours") &&
      currentFieldState["TimeStart"] &&
      currentFieldState["TimeFinish"]
    ) {
      currentFieldState["Hours"] = calculateDuration(
        currentFieldState["TimeStart"],
        currentFieldState["TimeFinish"]
      );
    }

    return currentFieldState;
  };

  // formatTime = (event, field) => {
  //   let currentFieldState = Object.assign({}, this.state.fieldValues)
  //   const fieldName = field.FieldName
  //   const fieldVal = event.target.value;

  //   if (fieldVal.length === 1) {
  //     currentFieldState[fieldName] = '0' + fieldVal + '00';
  //   }
  //   else if (fieldVal.length === 2) {
  //     currentFieldState[fieldName] = fieldVal + '00';
  //   }
  //   else {
  //     currentFieldState[fieldName] = fieldVal;
  //   }

  //   if (currentFieldState[fieldName] === '2400') {
  //     currentFieldState[fieldName] = '0000';
  //   }

  //   return currentFieldState;
  // }

  incrementFocus = (currentFieldName = null) => {
    const fieldRefCount = this.prodFieldRefs.length - 1;
    const fieldMap = this.props.fieldMap;

    let currentFocus = fieldMap.findIndex(
      (field) => field.FieldName === document.activeElement.name
    );

    if (currentFieldName) {
      currentFocus = fieldMap.findIndex(
        (field) => field.FieldName === currentFieldName
      );
    }

    for (let i = currentFocus + 1; i <= fieldRefCount; i++) {
      if (fieldMap[i].TabStop === "Y" && this.prodFieldRefs[i].current) {
        this.prodFieldRefs[i].current.focus();
        this.prodFieldRefs[i].current.scrollIntoView();
        return;
      }
    }
  };

  onCommentKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.props.onSubmit();
    }
  };

  applyAllSprs(page, fieldMap) {
    const sprFields = getSprFields(page, fieldMap);
    let lastSprRun;

    if (sprFields > 0) {
      sprFields.map(async (field) => {
        const fieldsAfterSprs = this.applySprs(field, lastSprRun);

        if (fieldsAfterSprs) {
          lastSprRun = fieldsAfterSprs;
        }
      });
      this.setState({ fieldValues: lastSprRun });
    }
  }

  applySprs = (field, currentFields) => {
    let currentFieldState = Object.assign({}, this.state.fieldValues);

    if (currentFields) {
      currentFieldState = Object.assign({}, currentFields);
    }

    const fieldMap = this.props.fieldMap;
    let updatedFields = Object.assign({}, currentFieldState);

    if (!field) {
      return;
    }

    let equations = [];

    try {
      if (Object.prototype.hasOwnProperty.call(field, "SpecProc")) {
        let specProc = field.SpecProc;

        if (specProc) {
          //remove whitespace
          specProc = specProc.replace(/\s+/g, "");
          if (!specProc.includes("=")) {
            return;
          }

          equations = specProc.split(";");
          //console.log('SPR Equations')
          equations.map((equation) => {
            //console.log(equation)
            if (equation) {
              const components = equation.split("=");
              const left = components[0];
              const right = components[1];

              const rightComponents = right.split(/[/*+-]/);

              let rightSubstituted = right;
              let isTime = false;

              if (rightComponents.length === 1 && rightComponents[0] === "") {
                currentFieldState[left] = "";
                updatedFields = Object.assign({}, currentFieldState);
              } else {
                let equationHasEmptyVal = false;

                if (!equationHasEmptyVal) {
                  rightComponents.map((fieldName) => {
                    let val;
                    if (fieldName.includes("@")) {
                      const priorKey = fieldName.replace("@", "");
                      val = this.props.priorEntries[priorKey];
                    } else {
                      const numeric = isNumeric(fieldName);

                      if (numeric) {
                        val = fieldName;
                      } else {
                        val = currentFieldState[fieldName];
                      }
                    }

                    if ((!val && val !== 0) || val === "NaN") {
                      equationHasEmptyVal = true;
                    } else {
                      if (
                        fieldMap
                          .find((field) => field.FieldName === fieldName)
                          ?.FieldType?.toUpperCase() === "TIMEX"
                      ) {
                        isTime = true;
                      }
                      rightSubstituted = rightSubstituted.replace(
                        fieldName,
                        val.toString()
                      );
                    }
                  });

                  if (!equationHasEmptyVal) {
                    let result = null;

                    if (rightSubstituted.includes("--")) {
                      rightSubstituted = rightSubstituted.replace("--", "+");
                    }

                    if (rightSubstituted.includes("+-")) {
                      rightSubstituted = rightSubstituted.replace("+-", "-");
                    }

                    if (rightSubstituted.includes("-+")) {
                      rightSubstituted = rightSubstituted.replace("-+", "-");
                    }

                    if (isTime) {
                      //console.log('isTime')
                      //console.log(rightSubstituted)
                      const timeVals = rightSubstituted.split("-");
                      result = calculateDuration(timeVals[1], timeVals[0]);
                      if (timeVals.length > 2) {
                        result = eval(
                          rightSubstituted.replace(
                            timeVals[0] + "-" + timeVals[1],
                            result
                          )
                        );
                      }
                    } else {
                      result = eval(rightSubstituted);
                    }

                    const leftField = fieldMap.find(
                      (field) => field.FieldName === left
                    );

                    if (leftField && leftField.Decimals) {
                      result = parseFloat(result).toFixed(leftField.Decimals);
                    }

                    currentFieldState[left] = result;
                    updatedFields = Object.assign({}, currentFieldState);
                  }
                }
              }
            }
          });
          return updatedFields;
        }
      }

      return updatedFields;
    } catch (e) {
      logErrorToServer(-1, e, {}, "ProductFields.js: applySprs", {
        fieldMap,
        updatedFields,
      });
    }
  };

  formatDecimal = (event, field) => {
    let currentFieldState = Object.assign({}, this.state.fieldValues);
    let fieldVal = event.target.value;
    const decimalPlaces = field.Decimals;
    const fieldName = field.FieldName;

    if (fieldVal) {
      currentFieldState[fieldName] =
        parseFloat(fieldVal).toFixed(decimalPlaces);
    }

    return currentFieldState;
  };

  render() {
    const { listX } = this.state;
    const fieldMapProp = [...this.props.fieldMap];

    return (
      <React.Fragment key="ProductFieldsParent">
        {fieldMapProp.map((field, index) => {
          let tabIndex = -1;
          let allCaps = false;
          let step = "1";

          const fieldDecimals = field.Decimals;

          if (fieldDecimals) {
            const padding = "0.";
            const decimalInt = parseInt(fieldDecimals);

            if (fieldDecimals > 0) {
              step = padding + (decimalInt - 1) * "0" + "1";
            }
          }

          if (field.TabStop && field.TabStop.toUpperCase() === "Y") {
            tabIndex = 0;
          }

          if (field.AllCaps) {
            allCaps = true;
          }

          // if (field.FieldName === "ProdDesc") {
          //   //field.Supplied = this.props.productDescription;
          // }

          if (field.FieldName === "Product") {
            return;
          }

          let newLine = (
            <React.Fragment key={"newLine-" + index}></React.Fragment>
          );

          if (field.NewLine) {
            newLine = (
              <React.Fragment key={"newLine-" + index}>
                <hr />
              </React.Fragment>
            );
          }

          if (field.FieldType === "NoteX") {
            return (
              <React.Fragment key={index}>
                <div className="paragraph-entry form-group">
                  <label htmlFor={field.FieldName}>{field.Caption}:</label>
                  <textarea
                    key={index}
                    className="form-control input-lg"
                    type="text"
                    id={field.FieldName}
                    name={field.FieldName}
                    //defaultValue={field.Supplied}
                    onKeyDown={this.onCommentKeyDown}
                    onChange={this.handleInputChange}
                    tabIndex={tabIndex}
                    ref={this.prodFieldRefs[index]}
                    value={this.state.fieldValues[field.FieldName]}
                    onFocus={(e) => e.target.select()}
                  />
                </div>
              </React.Fragment>
            );
          } else if (field.FieldType === "LISTX") {
            let values = listX[field.FieldName];

            return (
              <React.Fragment key={index}>
                {newLine}
                <Autocomplete
                  //key={index}
                  incrementFocus={this.incrementFocus}
                  suggestions={values}
                  showSuggestions={true}
                  onSelection={(obj) => {
                    this.handleAutocomplete(field.FieldName, obj);
                  }}
                  maxLength={field.MaxLen}
                  size={Math.round(field.MaxLen * 0.5)}
                  label={field.Caption}
                  fieldName={field.FieldName}
                  tabIndex={tabIndex}
                  ref={this.prodFieldRefs[index]}
                  value={this.state.fieldValues[field.FieldName]}
                  default={this.state.fieldValues[field.FieldName]}
                  onFocus={(e) => e.target.select()}
                />
              </React.Fragment>
            );
          } else if (field.FieldType === "PRODX") {
            return (
              <React.Fragment key={index}>
                {newLine}
                <div className="form-group">
                  <label htmlFor={field.FieldName}>{field.Caption}:</label>
                  <input
                    key={index}
                    className="form-control input-lg"
                    type="text"
                    id={field.FieldName}
                    name={field.FieldName}
                    //defaultValue={field.Supplied}
                    onChange={this.handleInputChange}
                    maxLength={field.MaxLen}
                    size={Math.round(field.MaxLen * 0.5)}
                    tabIndex={tabIndex}
                    ref={this.prodFieldRefs[index]}
                    value={this.state.fieldValues[field.FieldName]}
                    label="Product"
                    autoComplete="none"
                  />
                </div>
              </React.Fragment>
            );
          } else if (field.FieldType.toUpperCase() === "DATEX") {
            if (this.state.fieldValues[field.FieldName]) {
              return (
                <React.Fragment key={index}>
                  {newLine}
                  <div className="form-group date-select">
                    <label htmlFor={field.FieldName}>{field.Caption}:</label>
                    <DatePicker
                      key={index}
                      className="form-control input-lg"
                      id={field.FieldName}
                      name={field.FieldName}
                      dateFormat="dd/MM/yyyy"
                      selected={this.state.fieldValues[field.FieldName]}
                      onChange={(date) =>
                        this.handleDateChange(date, field.FieldName)
                      }
                      tabIndex={tabIndex}
                      ref={this.prodFieldRefs[index]}
                      value={this.state.fieldValues[field.FieldName]}
                    />
                  </div>
                </React.Fragment>
              );
            }
          } else if (field.FieldType === "TIMEX") {
            return (
              <React.Fragment key={index}>
                {newLine}
                <div className="form-group">
                  <label htmlFor={field.FieldName}>{field.Caption}:</label>
                  <input
                    key={index}
                    className="form-control input-lg number-input"
                    type="number"
                    id={field.FieldName}
                    name={field.FieldName}
                    //defaultValue={field.Supplied}
                    onChange={this.handleInputChange}
                    maxLength={field.MaxLen}
                    min="0"
                    max="2400"
                    onBlur={(e) => {
                      this.onBlur("TIME", e, field);
                    }}
                    tabIndex={tabIndex}
                    ref={this.prodFieldRefs[index]}
                    value={this.state.fieldValues[field.FieldName] || ""}
                    onFocus={(e) => e.target.select()}
                    autoComplete="none"
                  />
                </div>
              </React.Fragment>
            );
          } else if (field.FieldType === "9") {
            return (
              <React.Fragment key={index}>
                {newLine}
                <div className="form-group">
                  <label htmlFor={field.FieldName}>{field.Caption}:</label>
                  <input
                    key={index}
                    className="form-control input-lg number-input"
                    type="number"
                    id={field.FieldName}
                    name={field.FieldName}
                    //defaultValue={field.Supplied}
                    onChange={(evt) => {
                      this.handleInputChange(evt, allCaps);
                    }}
                    maxLength={field.MaxLen}
                    min={-1 * Math.pow(10, field.MaxLen - 1)}
                    max={Math.pow(10, field.MaxLen - 1)}
                    tabIndex={tabIndex}
                    ref={this.prodFieldRefs[index]}
                    step={step}
                    onBlur={(e) => {
                      this.onBlur("DECIMAL", e, field);
                    }}
                    value={this.state.fieldValues[field.FieldName] || ""}
                    readOnly={field.ReadOnly}
                    onFocus={(e) => e.target.select()}
                    autoComplete="none"
                  />
                </div>
              </React.Fragment>
            );
          } else if (field.FieldType === "EMPX") {
            return (
              <React.Fragment key={index}>
                {newLine}
                <Autocomplete
                  key={index}
                  suggestions={this.props.employeeStrings}
                  onSelection={(obj) => {
                    this.handleAutocomplete(field.FieldName, obj);
                  }}
                  maxLength={field.MaxLen}
                  size={Math.round(field.MaxLen * 0.5)}
                  label="Employee:"
                  tabIndex={tabIndex}
                  ref={this.prodFieldRefs[index]}
                  value={this.state.fieldValues[field.FieldName]}
                  showSuggestions={false}
                  //onBlur={this.handleInputChange}
                  default={this.state.fieldValues[field.FieldName]}
                  onFocus={(e) => e.target.select()}
                />
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={index}>
                {newLine}
                <div className="form-group">
                  <label htmlFor={field.FieldName}>{field.Caption}:</label>
                  <input
                    key={index}
                    className="form-control input-lg"
                    type="text"
                    id={field.FieldName}
                    name={field.FieldName}
                    //defaultValue={field.Supplied}
                    onChange={(evt) => {
                      this.handleInputChange(evt, allCaps);
                    }}
                    maxLength={field.MaxLen}
                    size={Math.round(field.MaxLen * 0.5)}
                    tabIndex={tabIndex}
                    ref={this.prodFieldRefs[index]}
                    value={this.state.fieldValues[field.FieldName]}
                    onFocus={(e) => e.target.select()}
                    onBlur={(e) => {
                      this.onBlur("OTHER", e, field);
                    }}
                    autoComplete="none"
                  />
                </div>
              </React.Fragment>
            );
          }
        })}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onModifyLineItem: (payload) => dispatch(doModifyLineItem(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    priorEntries: state.priorEntryState.priorEntries,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductFields);
