import { put, select } from "redux-saga/effects";
import { doUpdateUserEmailPermissions, doUpdateUserId } from "../actions/auth";
import { doUploadElog } from "../actions/elog";
import { doCloseLoginPrompt, doShowLoginPrompt } from "../actions/global";
import { doFetchRefsGraphql } from "../actions/refFile";
// import { fetchUserDataGraphql } from "../api/auth";
import { PlodDb } from "../services/DBService";
import { decodeJwt, getNextRefId, logErrorToServer } from "../services/Utilities";

const getAuthState = (state) => state.authState.auth;
const getLoginPromptStatus = (state) => state.globalState.showLoginPrompt;
const getElogs = (state) => state.elogState.elogs;
const getRefs = (state) => state.refFileState.refFiles;

function* handleAuthUpdate(action) {
  if (action.auth.isLoading === false) {
    if (action.auth.isAuthenticated === false) {
      yield put(doShowLoginPrompt());
    } else {
      const loginPromptVisible = yield select(getLoginPromptStatus);

      if (loginPromptVisible) {
        yield put(doCloseLoginPrompt());
      }
    }
  }
}

function* handleLogin(action) {
  try {
    const auth = yield select(getAuthState);

    if (auth.isAuthenticated && auth.user) {
      const email = auth.user.email;

      if (email) {
        const jwt = decodeJwt(action.token);
        //const result = yield call(fetchUserDataGraphql, action.token, email);
        //if (result && result?.data?.users?.length > 0) {
        // const userId = result.data.users[0].user_id;
        // const userEmailActive = result.data.users[0].email_active;

        const userId = jwt?.["https://hasura.io/jwt/claims"]?.["x-hasura-user-id"];
        const userEmailActive = jwt?.["https://hasura.io/jwt/claims"]?.["x-hasura-user-email-active"];

        if (userId) {
          yield put(doUpdateUserId(userId.toString()));
          yield put(doUpdateUserEmailPermissions(userEmailActive));
        }
        //}
      }

      //yield put(doFetchRefCombinationsGraphql({ token: action.token }));//
      const refFiles = yield select(getRefs);

      yield put(doFetchRefsGraphql({
        token: action.token,
        newId: getNextRefId(refFiles),
      }))

      //Check elogs that need uploading
      const elogState = yield select(getElogs);
      const elogs = Object.values(elogState);

      for (let i = 0; i < elogs.length; i++) {
        const elog = elogs[i];

        if (elog.needsUpdateServer) {
          //create elog and get id
          yield put(doUploadElog({ elog }));
        }
      }

      //Get previous errors from IDB and attempt upload
      PlodDb.getAll("errors").then((errors) => {
        errors.map((error) => {
          const id = error.id;
          const objString = error.errorObj;
          const obj = JSON.parse(objString);

          logErrorToServer(
            id,
            obj.error,
            obj.info,
            obj.moreInfo,
            obj.reduxState
          );
        });
      });
    }
  } catch (error) {
    //yield put(doFetchErrorRefFile(error));
  }
}

export { handleLogin, handleAuthUpdate };
