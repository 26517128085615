import {
  PRIORENTRY_UPDATE,
  PRIORENTRY_BATCH_UPDATE,
  PRIORENTRIES_FROM_IDB,
  PRIORENTRIES_FROM_IDB_SUCCESS,
  PRIORENTRIES_FETCH_ERROR,
  PRIORENTRIES_TO_IDB,
  PRIORENTRIES_TO_IDB_SUCCESS,
  PRIORENTRIES_SAVE_ERROR,
  IMPORT_PRIOR_ENTRY_STATE,
} from "../constants/actionTypes";

const doGetPriorEntriesError = (error) => ({
  type: PRIORENTRIES_FETCH_ERROR,
  error,
});

const doGetPriorEntriesIdb = () => ({
  type: PRIORENTRIES_FROM_IDB,
});

const doGetPriorEntriesIdbSuccess = (priorEntries) => ({
  type: PRIORENTRIES_FROM_IDB_SUCCESS,
  priorEntries,
});

const doSavePriorEntriesIdb = (priorEntries) => ({
  type: PRIORENTRIES_TO_IDB,
  priorEntries: priorEntries,
});

const doSavePriorEntriesIdbSuccess = () => ({
  type: PRIORENTRIES_TO_IDB_SUCCESS,
});

const doSavePriorEntriesError = (error) => ({
  type: PRIORENTRIES_SAVE_ERROR,
  error,
});

const doUpdatePriorEntry = (payload) => ({
  type: PRIORENTRY_UPDATE,
  payload,
});

const doBatchUpdatePriorEntry = (payload) => ({
  type: PRIORENTRY_BATCH_UPDATE,
  payload,
});

const doImportPriorEntryState = (payload) => ({
  type: IMPORT_PRIOR_ENTRY_STATE,
  payload,
});

export {
  doGetPriorEntriesIdb,
  doGetPriorEntriesIdbSuccess,
  doGetPriorEntriesError,
  doSavePriorEntriesIdb,
  doSavePriorEntriesIdbSuccess,
  doSavePriorEntriesError,
  doUpdatePriorEntry,
  doBatchUpdatePriorEntry,
  doImportPriorEntryState,
};
