import { call, put } from "redux-saga/effects";
import {
  doGetPriorEntriesIdbSuccess,
  doGetPriorEntriesError,
  doSavePriorEntriesError,
  doSavePriorEntriesIdbSuccess,
} from "../actions/priorEntry";
import {
  fetchPriorEntriesFromIdb,
  savePriorEntriesToIdb,
} from "../api/priorEntry";

/*eslint-disable */
function* handleFetchPriorEntries(action) {
  /*eslint-enable */
  try {
    const result = yield call(fetchPriorEntriesFromIdb);
    yield put(doGetPriorEntriesIdbSuccess(result));
  } catch (error) {
    yield put(doGetPriorEntriesError(error));
  }
}

function* handleSavePriorEntries(action) {
  try {
    yield call(savePriorEntriesToIdb, action.priorEntries);
    yield put(doSavePriorEntriesIdbSuccess());
  } catch (error) {
    yield put(doSavePriorEntriesError(error));
  }
}

export { handleFetchPriorEntries, handleSavePriorEntries };
