import React from "react";

class Confirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      caption,
      onClose,
      onClick,
      affirmativeText,
      declineText,
    } = this.props;

    const imgPath = process.env.PUBLIC_URL + "/driller-thumbs.png";

    return (
      <div className="react-confirm-alert-body custom-alert-body">
        <img src={imgPath} />
        {/* <h1>{title}</h1> */}
        <h4>{caption}</h4>
        <div className="react-confirm-alert-button-group alert-buttons">
          <button onClick={onClose}>{declineText}</button>
          <button onClick={onClick}>{affirmativeText}</button>
        </div>
      </div>
    );
  }
}

export default Confirmation;
