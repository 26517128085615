import React from "react";
import { logErrorToServer } from "../services/Utilities";

class ElogGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      labels: [],
      data: [],
      horizontalBarRef: null,
      imgString: "",
    };
  }

  componentDidMount() {
    let shiftTicks = [];
    let displayData = {};

    try {
      const prodMap = this.props.prodMap;

      let analysisProducts = [];

      prodMap.map((prod) => {
        if (prod.Activity) {
          analysisProducts.push(prod.ProdCode);
        }
      });

      const header = this.props.header;

      if (this.props?.header?.TimeStart) {
        const shiftTimestamp = header.ShiftDate;
        const shiftDate = new Date(shiftTimestamp);

        let lineItems = this.props.lineItems;

        const shiftStartHour = Math.round(
          header.TimeStart[0] + header.TimeStart[1]
        );
        const shiftStartMinute = Math.round(
          header.TimeStart[2] + header.TimeStart[3]
        );

        const shiftEndHour = Math.round(
          header.TimeFinish[0] + header.TimeFinish[1]
        );
        const shiftEndMinute = Math.round(
          header.TimeFinish[2] + header.TimeFinish[3]
        );

        let shiftStartDateObj = new Date(
          shiftDate.getFullYear(),
          shiftDate.getMonth(),
          shiftDate.getDate(),
          shiftStartHour,
          shiftStartMinute
        );
        let shiftEndDateObj = new Date(
          shiftDate.getFullYear(),
          shiftDate.getMonth(),
          shiftDate.getDate(),
          shiftEndHour,
          shiftEndMinute
        );

        const shiftStartDatetime = Math.floor(
          shiftStartDateObj.getTime() / 1000
        );
        let shiftEndDatetime = Math.floor(shiftEndDateObj.getTime() / 1000);

        if (shiftEndDateObj < shiftStartDateObj) {
          shiftEndDatetime = Math.floor(
            (shiftEndDateObj.getTime() + 24 * 60 * 60000) / 1000
          );
        }

        //taskEndDatetime = new Date(taskStartDatetime.getTime() + 5*60000);

        var graphData = [];
        var graphLabels = [];
        let finalData = {};

        lineItems = lineItems.sort((a, b) => {
          if (a.fieldValues.TimeFinish == null && b.fieldValues.TimeFinish) {
            return 1;
          }

          if (b.fieldValues.TimeFinish == null && a.fieldValues.TimeFinish) {
            return -1;
          }

          if (a.fieldValues.TimeStart > b.fieldValues.TimeStart) {
            return 1;
          } else {
            return -1;
          }
        });

        lineItems.map((lineItem) => {
          let showOnAnalysis = false;
          const fieldValues = lineItem.fieldValues;

          if (
            analysisProducts.includes(lineItem.product) &&
            fieldValues.TimeStart
          ) {
            showOnAnalysis = true;
          }

          if (showOnAnalysis) {
            let startMinutes = parseInt(
              fieldValues.TimeStart[2] + fieldValues.TimeStart[3]
            );
            let startHours = parseInt(
              fieldValues.TimeStart[0] + fieldValues.TimeStart[1]
            );

            let endMinutes = null;
            let endHours = null;

            let taskStartDateObj = new Date(
              shiftDate.getFullYear(),
              shiftDate.getMonth(),
              shiftDate.getDate(),
              startHours,
              startMinutes
            );
            let taskEndDateObj = null;

            let taskEndDatetime;
            let taskStartDatetime;

            if (fieldValues.TimeFinish) {
              endMinutes = parseInt(
                fieldValues.TimeFinish[2] + fieldValues.TimeFinish[3]
              );
              endHours = parseInt(
                fieldValues.TimeFinish[0] + fieldValues.TimeFinish[1]
              );
              taskEndDateObj = new Date(
                shiftDate.getFullYear(),
                shiftDate.getMonth(),
                shiftDate.getDate(),
                endHours,
                endMinutes
              );
              taskEndDatetime = Math.floor(taskEndDateObj.getTime() / 1000);
            }

            //if lineitem start is less then 1 hour before shift start, this means that we have gone over the midnight mark, so add a day to the line item start
            if (taskStartDateObj.getTime() < shiftStartDateObj.getTime() - 60 * 60 * 1000) {
              taskStartDatetime = Math.floor((taskStartDateObj.getTime() + 24 * 60 * 60000) / 1000);
            }

            if (taskEndDateObj && taskEndDateObj < shiftStartDateObj) {
              taskEndDatetime = Math.floor(
                (taskEndDateObj.getTime() + 24 * 60 * 60000) / 1000
              );
            }

            if (!taskStartDatetime) {
              taskStartDatetime = Math.floor(taskStartDateObj.getTime() / 1000);
            }

            graphLabels.push(lineItem.fieldValues.ProdDesc.substring(0, 14)); //cut label lengths to 14 chars
            graphData.push([taskStartDatetime, taskEndDatetime]);

            const dataLabel = lineItem.fieldValues.ProdDesc.substring(0, 14);

            let dataArray;

            if (Object.prototype.hasOwnProperty.call(finalData, dataLabel)) {
              dataArray = [
                ...finalData[dataLabel],
                [taskStartDatetime, taskEndDatetime],
              ];
            } else {
              dataArray = [[taskStartDatetime, taskEndDatetime]];
            }

            finalData[dataLabel] = dataArray;
          }
        });

        //3600 to add one hour buffer around shift
        for (
          let i = shiftStartDatetime - 3600;
          i < shiftEndDatetime + 3600 + 1 /*so it displays last time point */;
          i = i + 900
        ) {
          shiftTicks.push(i);
        }

        Object.entries(finalData).map(([label, timePoints]) => {
          if (timePoints[0] || timePoints[1]) {
            let timePlot = [];
            let colour = "red";
            let duration = 0;
            let isCircle = false;

            timePoints.map((timePoint) => {
              if (timePoint[0] && timePoint[1]) {
                duration += (timePoint[1] - timePoint[0]) / 3600;
              } else if (timePoint[0]) {
                isCircle = true;
                colour = "blue";
              }
            });

            shiftTicks.map((tick, tickIndex) => {
              let onTick = false;

              timePoints.map((timePoint) => {
                if (timePoint[0] <= tick && timePoint[1] > tick) {
                  onTick = true;
                } else if (
                  !timePoint[1] &&
                  timePoint[0] <= tick &&
                  timePoint[0] + 900 > tick
                ) {
                  //when doesn't have timefinish eg consumable
                  onTick = true;
                }
              });

              if (shiftTicks.length - 1 > tickIndex) {
                //if last loop to remove addl data rows to create last time point in header
                if (onTick) {
                  timePlot.push(15);
                } else {
                  timePlot.push(0);
                }
              }
            });

            displayData[label] = { timePlot, colour, duration, isCircle };
          }
        });

        this.setState({
          shiftTicks,
          displayData,
        });
      }
    } catch (e) {
      logErrorToServer(-1, e, {}, "static activity analysis", {
        displayData,
        shiftTicks,
      });
    }
  }

  formatTime(s) {
    const dtFormat = new Intl.DateTimeFormat("en-AU", {
      timeStyle: "short",
      //timeZone: 'Australia/Sydney',
      hour12: false,
    });

    return dtFormat.format(new Date(s * 1e3));
  }

  render() {
    const { shiftTicks, displayData } = this.state;

    let data = [];

    if (displayData) {
      data = Object.entries(displayData);
    }

    if (data.length <= 0) {
      return <div></div>;
    }

    return (
      <>
        <h4 className="heading" style={this.props.headingFontSize}>
          Activity Analysis
        </h4>
        <div className="activity-analysis-chart">
          <table className="chart-table">
            <thead>
              <tr>
                <th className="label"></th>
                {shiftTicks &&
                  shiftTicks.map((tick, index) => {
                    if (tick % 3600 === 0) {
                      return <th key={"tick-" + index}>{this.formatTime(tick).replace(":", "")}</th>;
                    }
                    return <th key={"tick-" + index}></th>;
                  })}
                <th className="label">Hrs</th>
              </tr>
            </thead>
            <tbody>
              {data.map(([label, displayData], index) => {
                let timePlot = displayData.timePlot;
                return (
                  <tr key={"timerow-" + index}>
                    <td className="label">{label}</td>
                    {timePlot.map((cell, cellIndex) => {
                      if (cell) {
                        return (
                          <td
                            key={"cell-" + cellIndex}
                            className={
                              displayData?.isCircle ? "circle" : "filled"
                            }
                          >
                            {displayData?.isCircle ? (
                              <span className="dot"></span>
                            ) : (
                              <div className="bar"></div>
                            )}
                          </td>
                        );
                      }
                      return (
                        <td className="not-filled" key={"cell-" + cellIndex}>
                          <div className="bar"></div>
                        </td>
                      );
                    })}
                    <td className="label"></td>
                    {displayData.isCircle && <td className="label"></td>}

                    {!displayData.isCircle && (
                      <>
                        <td className="label">
                          {displayData?.duration?.toFixed(3)}
                        </td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default ElogGraph;
