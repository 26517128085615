import {
  PRIORENTRIES_FROM_IDB,
  PRIORENTRIES_FROM_IDB_SUCCESS,
  PRIORENTRIES_TO_IDB,
  PRIORENTRIES_TO_IDB_SUCCESS,
  PRIORENTRIES_FETCH_ERROR,
  PRIORENTRIES_SAVE_ERROR,
  PRIORENTRY_UPDATE,
  PRIORENTRY_BATCH_UPDATE,
  IMPORT_PRIOR_ENTRY_STATE,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  priorEntries: {},
  fetchingPriorEntries: false,
  savingPriorEntries: false,
  errorState: false,
  needsUpdateIdb: false,
};

const applyGetAllPriorEntries = (state) => ({
  ...state,
  fetchingPriorEntries: true,
  errorState: false,
});

const applySavePriorEntries = (state) => ({
  ...state,
  savingPriorEntries: true,
  errorState: false,
});

const applyGetAllPriorEntriesSuccess = (state, action) => {
  return {
    ...state,
    priorEntries: action.priorEntries,
    fetchingPriorEntries: false,
  };
};

const applySavePriorEntriesSuccess = (state) => {
  return {
    ...state,
    savingPriorEntries: false,
  };
};

const applyPriorEntriesError = (state, action) => {
  return {
    ...state,
    savingPriorEntries: false,
    errorState: action.error,
  };
};

const applyUpdatePriorEntry = (state, action) => ({
  ...state,
  priorEntries: {
    ...state.priorEntries,
    [action.payload.key]: action.payload.value,
  },
});

const applyBatchUpdatePriorEntry = (state, action) => {
  let fieldValues = Object.entries(action.payload);

  let priorEntries = Object.assign({}, state.priorEntries);

  fieldValues.forEach(([key, value]) => {
    priorEntries[key] = value;
  });

  return {
    ...state,
    priorEntries,
  };
};

const applyImportState = (action) => {
  return action.payload;
};

function priorEntryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PRIORENTRIES_FROM_IDB: {
      return applyGetAllPriorEntries(state, action);
    }
    case PRIORENTRIES_FROM_IDB_SUCCESS: {
      return applyGetAllPriorEntriesSuccess(state, action);
    }
    case PRIORENTRIES_TO_IDB: {
      return applySavePriorEntries(state, action);
    }
    case PRIORENTRIES_TO_IDB_SUCCESS: {
      return applySavePriorEntriesSuccess(state, action);
    }
    case PRIORENTRY_UPDATE: {
      return applyUpdatePriorEntry(state, action);
    }
    case PRIORENTRY_BATCH_UPDATE: {
      return applyBatchUpdatePriorEntry(state, action);
    }
    case PRIORENTRIES_FETCH_ERROR: {
      return applyPriorEntriesError(state, action);
    }
    case PRIORENTRIES_SAVE_ERROR: {
      return applyPriorEntriesError(state, action);
    }

    case IMPORT_PRIOR_ENTRY_STATE: {
      return applyImportState(action);
    }

    default:
      return state;
  }
}

export default priorEntryReducer;
