import React from "react";
import { connect } from "react-redux";

import { Button, Modal } from "react-bootstrap";
import {
  getRefRecipients,
  getRefReplyTo,
  getRefEmailBody,
} from "../services/Utilities";
import {
  doCloseRecipientPrompt,
  doShowRecipientPrompt,
} from "../actions/global";
import { doCompleteElog, doSendElog } from "../actions/elog";

class RecipientPrompt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emails: [],
      currentEmail: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.showRecipientPrompt && this.props.showRecipientPrompt) {
      this.getDefaultRecipients();
      this.getDefaultReplyTo();
      this.getDefaultEmailBody();
    }
  }

  componentDidMount() {
    this.getDefaultRecipients();
    this.getDefaultReplyTo();
    this.getDefaultEmailBody();
  }

  //get interested parties from ref file
  getDefaultRecipients() {
    const elogToSend = this.props.elogToSend;

    //if not using ref files to populate recipients
    if (elogToSend) {
      if (elogToSend.recipients) {
        this.setState({ emails: elogToSend.recipients });
      }
    } else {
      const currentRefFile = this.props?.refFiles[this?.props?.refFileId];
      const prefMap = currentRefFile?.refFile?.PREFfile;

      if (prefMap) {
        const defaultRecipients = getRefRecipients(prefMap);
        this.setState({ emails: defaultRecipients });
      }
    }
  }

  getDefaultReplyTo() {
    const currentRefFile = this.props?.refFiles[this?.props?.refFileId];
    const prefMap = currentRefFile?.refFile?.PREFfile;

    if (prefMap) {
      const defaultReplyTo = getRefReplyTo(prefMap);
      this.setState({ replyTo: defaultReplyTo });
    }
  }

  getDefaultEmailBody() {
    const elogToSend = this.props.elogToSend;

    //if not using ref files to populate
    if (elogToSend) {
      if (elogToSend.emailBody) {
        this.setState({ emailBody: elogToSend.emailBody });
      }
    } else {
      const currentRefFile = this.props?.refFiles[this?.props?.refFileId];
      const prefMap = currentRefFile?.refFile?.PREFfile;

      if (prefMap) {
        const defaultEmailBody = getRefEmailBody(prefMap);
        this.setState({ emailBody: defaultEmailBody });
      }
    }
  }

  changeRecipients(e) {
    this.setState({ emails: e.target.value });
  }

  changeEmailBody(e) {
    this.setState({ emailBody: e.target.value });
  }

  isValidEmail(val) {
    /* eslint-disable */
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    /* eslint-enable */
    if (!regEmail.test(val)) {
      return false;
    }
    return true;
  }

  handleRecipient = (e) => {
    const emailVal = e.target.value;
    this.setState({ currentEmail: emailVal });

    if ((e.keyCode == 13 || e.keyCode == 32) && !this.isValidEmail(emailVal)) {
      alert("invalid email");
      return;
    }

    if (e.keyCode == 13 && emailVal) {
      const emails = [...this.state.emails, emailVal];
      this.setState({ emails, currentEmail: "" });
    } else if (e.keyCode == 32 && emailVal) {
      const emails = [...this.state.emails, emailVal];
      this.setState({ emails, currentEmail: "" });
    }
  };

  addEmailButton = () => {
    const emailVal = this.state.currentEmail;

    if (!this.isValidEmail(emailVal)) {
      alert("invalid email");
      return;
    }

    const emails = [...this.state.emails, emailVal];
    return this.setState({ emails, currentEmail: "" });
  };

  removeEmail = (index) => {
    const emailArray = [...this.state.emails];
    emailArray.splice(index, 1);
    this.setState({ emails: emailArray });
  };

  sendEmailToRecipients = () => {
    const elogToSend = this.props.elogToSend;

    if (elogToSend) {
      this.props.onSendElog({
        id: elogToSend.elog_id,
        recipients: this.state.emails,
        replyTo: this.state.replyTo,
        emailBody: this.state.emailBody,
      });
    } else {
      this.props.onCompleteElog({
        id: this.props.currentElogId,
        recipients: this.state.emails,
        attemptSend: true,
        replyTo: this.state.replyTo,
        emailBody: this.state.emailBody,
      });
    }

    this.props.onCloseRecipientPrompt();
  };

  render() {
    return (
      <>
        <Modal
          className="recipient-prompt"
          animation={false}
          show={this.props.showRecipientPrompt}
          onHide={this.props.onCloseRecipientPrompt}
        >
          <Modal.Header closeButton>
            <Modal.Title>eLog Recipients</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Please confirm the recipients below.</p>

            {this.state.emails.length === 0 && (
              <p>
                <i>No recipients.</i>
              </p>
            )}

            {this.state.emails.length > 0 && (
              <p>
                <i>Tap recipients to remove.</i>
              </p>
            )}

            <div className="edit-recipients">
              <div className="recipient-tags">
                {this.state.emails.map((email, index) => {
                  return (
                    <div
                      key={email}
                      className="btn recipient-tag"
                      onClick={() => this.removeEmail(index)}
                    >
                      {email}
                    </div>
                  );
                })}
              </div>

              <div className="recipient-input">
                <input
                  type="email"
                  value={this.state.currentEmail}
                  onChange={this.handleRecipient}
                  onKeyDown={this.handleRecipient}
                  placeholder="email address"
                />
                <div
                  className="btn btn-info add-recipient"
                  onClick={this.addEmailButton}
                >
                  Add
                </div>
              </div>

              <div className="email-body-input">
                <label htmlFor="email-body-input-field">Message:</label>
                <textarea
                  id="email-body-input-field"
                  type="text"
                  value={this.state.emailBody}
                  onChange={(e) => this.changeEmailBody(e)}
                  placeholder="email body"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="info" onClick={this.sendEmailToRecipients}>
              Complete & Send
            </Button>
            <Button variant="info" onClick={this.props.onCloseRecipientPrompt}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseRecipientPrompt: () => dispatch(doCloseRecipientPrompt()),
    onShowRecipientPrompt: () => dispatch(doShowRecipientPrompt()),

    onCompleteElog: (payload) => dispatch(doCompleteElog(payload)),
    onSendElog: (payload) => dispatch(doSendElog(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    showRecipientPrompt: state.globalState.showRecipientPrompt,
    refFiles: state.refFileState.refFiles,
    refFileId: state.refFileState.currentRefId,

    currentElogId: state.elogState.currentElogId,

    elogToSend: state.globalState.elogToSend,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipientPrompt);
