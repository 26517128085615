import { call, put, select } from "redux-saga/effects";
import {
  doGetElogsIdbSuccess,
  doSaveElogIdbSuccess,
  doSaveElogIdbError,
  doDeleteElogIdbSuccess,
  doDeleteElogIdbError,
  doUploadElogSuccess,
  doUploadElogError,
  doSaveElogIdb,
  doModifyHeader,
  doUpdateElogHtml,
  doUploadElog,
  doCancelElogUpload,
} from "../actions/elog";
import {
  deleteElogIdb,
  fetchElogsFromIdb,
  saveElogToIdb,
  uploadElogsGraphql,
  getPlodNoFromServer,
  emailElog,
} from "../api/elog";
import {
  generateElogForExport,
  generateElogHtml,
  logErrorToServer,
} from "../services/Utilities";
import metadata from "../metadata.json";

const getEmailPermissions = (state) => state.authState?.userEmailPermissions;
const getElogs = (state) => state.elogState?.elogs;
const getAuthToken = (state) => state.authState?.token;

const getRefFiles = (state) => state.refFileState?.refFiles;
const getRefId = (state) => state.refFileState?.currentRefId;

// const getElogId = (state) => state.elogState.currentElogId;
// const getRefFiles = (state) => state.refFileState.refFiles;
// const getRefId = (state) => state.refFileState.currentRefId;

/*eslint-disable */
function* handleFetchAllElogs(action) {
  /*eslint-enable */
  try {
    const result = yield call(fetchElogsFromIdb);
    yield put(doGetElogsIdbSuccess(result));
  } catch (error) {
    //yield put(doFetchErrorRefFile(error));
  }
}

// function* updateElogPlodno(elog, plodNo) {

//   let elogClone = Object.assign({}, elog);

//   let elogHtml = elogClone.html;

//   if (!elogHtml) {
//     const refFiles = yield select(getRefFiles);
//     const refFileId = yield select(getRefId);

//     if (Object.keys(refFiles).length > 0 && refFileId >= 0) {
//       const currentRefFile = refFiles[refFileId]
//       const html = generateElogHtml(elog, currentRefFile)
//       yield put(doUpdateElogHtml({id: elog.id, html}));
//       elogHtml = html;
//     }

//   }

//bug: not ideal in case of existing PlodNo existing as an LI value
//   const newHtml = elogHtml.replaceAll(elog.header.PlodNo, plodNo);
//   elogClone.html = newHtml;

//   elogClone.header.PlodNo = plodNo;

//   const updatedElog = Object.assign({}, elogClone)

//   return updatedElog;
// }

// function updateElogPlodno(elog, plodNo) {

//   let elogClone = Object.assign({}, elog);

//   const elogHtml = elogClone.html;

//         if (!elogHtml) {
//         const refFiles = yield select(getRefFiles);
//         const refFileId = yield select(getRefId);

//         if (Object.keys(refFiles).length > 0 && refFileId >= 0) {
//           const currentRefFile = refFiles[refFileId]
//           const html = generateElogHtml(elog, currentRefFile)

//           if ( html && (typeof(html) === 'string' || html instanceof String) ) {
//             elogHtml = html;
//             yield put(doUpdateElogHtml({id: elogId, html}));
//           }
//         }
//       }

//   //not ideal in case of existing PlodNo existing as an LI value
//   const newHtml = elogHtml.replace(elog.header.PlodNo, plodNo);
//   elogClone.html = newHtml;

//   elogClone.header.PlodNo = plodNo;

//   const updatedElog = Object.assign({}, elogClone)

//   return updatedElog;
// }

function* handleUploadElogGraphql(action) {
  const elog = JSON.parse(JSON.stringify(action.payload.elog)); //Object.assign({}, action.payload.elog);
  let elogForIdb = JSON.parse(JSON.stringify(elog)); //Object.assign({}, elog)
  const authToken = yield select(getAuthToken);

  try {
    if (Object.prototype.hasOwnProperty.call(elog, "standingLiCount")) {
      if (Object.keys(elog.lineItems).length <= elog.standingLiCount) {
        yield put(doCancelElogUpload(elog.id));
        return;
      }
    }

    let elogName;
    const elogId = elog.id;
    const elogDate = new Date(elog.created).toISOString();
    let elogData;
    const elogRefFile = elog.refFile;
    let elogHtml;
    let elogCompleted = false;
    const appVersion =
      metadata.buildMajor +
      "." +
      metadata.buildMinor +
      "." +
      metadata.buildRevision;

    let sendEmailQuery = "";
    let recipients = elog.recipients;
    let replyTo = elog.replyTo;
    let emailBody = elog.emailBody;

    let result;

    let elogUserData = "{}";

    // if (process.env.REACT_APP_HOST_ENV === "dev") {
    //   elogUserData = "{user_id: 7}";
    // }

    if (elog.completed) {
      elogCompleted = true;

      const emailPermissions = yield select(getEmailPermissions);

      if (emailPermissions) {
        if (elog.pendingEmail) {
          sendEmailQuery = ", _inc: {times_emailed: 1}";
        }
      }
    }

    if (elog.existsOnServer && !action.payload.anew) {
      //updating elog

      elogName = elog.header.PlodNo;
      elogData = generateElogForExport(elog);
      elogHtml = elog.html;

      //ELOG HTML REGEN IF NEEDED
      if (!elogHtml) {
        const refFiles = yield select(getRefFiles);
        const refFileId = yield select(getRefId);

        if (Object.keys(refFiles).length > 0 && refFileId >= 0) {
          const currentRefFile = refFiles[refFileId];
          const html = generateElogHtml(elog, currentRefFile);

          if (html && (typeof html === "string" || html instanceof String)) {
            elogHtml = html;
            yield put(doUpdateElogHtml({ id: elogId, html }));
          }
        }
      }

      const query = `mutation UpdateElog($userEmail: String, $elogName: String!, $elogData: jsonb!, $elogHtml: String!, $elogCompleted: Boolean, $appVersion: String, $recipients: jsonb, $replyTo: String, $emailBody: String ) {
        update_elogs(where: { elog_name: {_eq: $elogName}}, _set: {elog_html: $elogHtml, elog_data: $elogData, elog_completed: $elogCompleted, times_exported: 0, elog_app_ver: $appVersion, recipients: $recipients, reply_to: $replyTo, email_body: $emailBody }${sendEmailQuery}) {
          affected_rows
        }
      }`;

      const variables = {
        elogData,
        elogName,
        elogHtml,
        elogCompleted,
        appVersion,
        recipients,
        replyTo,
        emailBody,
      };

      result = yield call(uploadElogsGraphql, authToken, query, variables);
    } else {
      //inserting elog

      const query = `mutation AddElog($elogDate: timestamptz!, $elogName: String!, $elogRefFile: String!, $elogData: jsonb!, $elogHtml: String!, $elogCompleted: Boolean, $appVersion: String, $recipients: jsonb, $replyTo: String, $emailBody: String ) {
        insert_elogs(objects: {created_at: $elogDate, elog_ref_file_name: $elogRefFile, elog_name: $elogName, elog_users: {data: ${elogUserData}}, elog_html: $elogHtml, elog_data: $elogData, elog_completed: $elogCompleted, elog_app_ver: $appVersion, recipients: $recipients, reply_to: $replyTo, email_body: $emailBody, times_emailed: ${sendEmailQuery ? 1 : 0
        }}) {
          returning {
            elog_name
          }
        }
      }`;

      const plodNoFromServer = yield call(getPlodNoFromServer, authToken);

      if (plodNoFromServer) {
        //const updatedElog = updateElogPlodno(elog, plodNoFromServer);
        ///////

        let elogClone = Object.assign({}, elog);
        let elogCloneHtml = elogClone.html;

        if (!elogCloneHtml) {
          const refFiles = yield select(getRefFiles);
          const refFileId = yield select(getRefId);

          if (Object.keys(refFiles).length > 0 && refFileId >= 0) {
            const currentRefFile = refFiles[refFileId];
            const html = generateElogHtml(elogClone, currentRefFile);

            if (html && (typeof html === "string" || html instanceof String)) {
              elogCloneHtml = html;
            }
          }
        }

        //not ideal in case of existing PlodNo existing as an LI value
        const newHtml = elogCloneHtml.replace(
          elogClone.header.PlodNo,
          plodNoFromServer
        );

        elogClone.html = newHtml;
        elogClone.header.PlodNo = plodNoFromServer;

        const updatedElog = Object.assign({}, elogClone);
        /////////

        yield put(
          doModifyHeader({ data: updatedElog.header, id: updatedElog.id })
        );
        yield put(
          doUpdateElogHtml({ id: updatedElog.id, html: updatedElog.html })
        );

        elogForIdb = Object.assign({}, updatedElog);

        elogName = updatedElog.header.PlodNo;
        elogData = generateElogForExport(updatedElog);
        elogHtml = updatedElog.html;

        const variables = {
          elogDate,
          elogRefFile,
          elogData,
          elogHtml,
          elogCompleted,
          appVersion,
          elogName,
          recipients,
          replyTo,
          emailBody,
        };

        result = yield call(uploadElogsGraphql, authToken, query, variables);
      }
    }

    if (result?.data === undefined) {
      yield put(doUploadElogError({ id: elogId, error: result }));
      elogForIdb.needsUpdateServer = true;
      elogForIdb.isUploading = false;
      elogForIdb.uploadError = result;
      logErrorToServer(-1, result, {}, "elog saga: undefined data", elog);
    } else {
      yield put(doUploadElogSuccess(elog.id));
      elogForIdb.needsUpdateServer = false;
      elogForIdb.isUploading = false;
      elogForIdb.uploadError = null;
      elogForIdb.existsOnServer = true;
    }

    yield put(doSaveElogIdb({ id: elog.id, elog: elogForIdb }));
  } catch (error) {
    yield put(doUploadElogError({ id: elog.id, error }));
    elogForIdb.needsUpdateServer = true;
    elogForIdb.isUploading = false;
    elogForIdb.uploadError = error;
    yield put(doSaveElogIdb({ id: elog.id, elog: elogForIdb }));
    logErrorToServer(
      -1,
      { msg: error.message, stack: error.stack },
      {},
      "elog saga: catch",
      elog
    );
  }
}

function* handleCompleteElog(action) {
  try {
    if (action.payload.id) {
      const elogs = yield select(getElogs);
      const completedElog = elogs[action.payload.id];

      //yield put(doSaveElogIdb({ id: action.payload.id, elog: completedElog }));
      yield put(doUploadElog({ elog: completedElog }));
    } else {
      logErrorToServer(-1, {}, {}, "handlecompleteelog has no id", action);
    }
  } catch (error) {
    logErrorToServer(-1, error, {}, "handlecompleteelog has catch", action);
  }
}

function* handleSendElog(action) {
  try {
    const query = `mutation EmailElog($recipients: jsonb!, $elogId: Int!, $replyTo: String!, $emailBody: String ) {
      update_elogs(where: {elog_id: {_eq: $elogId}}, _set: {recipients: $recipients, reply_to: $replyTo, email_body: $emailBody}, _inc: {times_emailed: 1}) {
        affected_rows
      }
    }`;

    const variables = {
      elogId: action.payload.id,
      recipients: action.payload.recipients,
      replyTo: action.payload.replyTo,
      emailBody: action.payload.emailBody,
    };

    const authToken = yield select(getAuthToken);
    yield call(emailElog, query, variables, authToken);

  } catch (error) {
    logErrorToServer(-1, error, {}, "handlesendelog catch", action);
  }
}

//elog to idb
function* handleSaveSingleElog(action) {
  try {
    if (action.payload && action.payload.elog && action.payload.id) {
      const result = yield call(saveElogToIdb, action.payload);

      if (result) {
        yield put(doSaveElogIdbSuccess(action.payload.id));
      }
    } else {
      yield put(
        doSaveElogIdbError({
          error: "no payload handlesavesingleelog",
          id: action.payload.id,
        })
      );
      logErrorToServer(-1, {}, {}, "elog to idb is undefined", action);
    }
  } catch (error) {
    yield put(doSaveElogIdbError({ error, id: action.payload.id }));
  }
}

function* handleDeleteElog(action) {
  try {
    yield call(deleteElogIdb, action.id);
    yield put(doDeleteElogIdbSuccess());
  } catch (error) {
    yield put(doDeleteElogIdbError(error));
  }
}

export {
  handleFetchAllElogs,
  handleSaveSingleElog,
  handleDeleteElog,
  handleUploadElogGraphql,
  handleCompleteElog,
  handleSendElog,
};
