import {
  UPDATE_AUTH,
  UPDATE_TOKEN,
  UPDATE_USER_EMAIL_PERMISSIONS,
  UPDATE_USER_ID,
} from "../constants/actionTypes";
import { isDev } from "../services/Utilities";

let INITIAL_STATE = {
  auth: {
    isLoading: true,
  },
  token: null,
  userId: "",
};

if (isDev()) {
  INITIAL_STATE = {
    auth: {
      isLoading: false,
      email: "services@patrickflynn.com.au",
      isAuthenticated: true,
      user: {
        email: "services@patrickflynn.com.au",
        ["https://hasura.io/jwt/claims/roles"]: ["admin"],
      },
    },
    token: "abc",
    userId: "7",
  };
}

const applyUpdateAuth = (state, action) => ({
  ...state,
  auth: action.auth,
});

const applyUpdateToken = (state, action) => ({
  ...state,
  token: action.token,
});

const applyUpdateUserId = (state, action) => ({
  ...state,
  userId: action.id,
});

const applyUpdateUserEmailPermissions = (state, action) => ({
  ...state,
  userEmailPermissions: action.allowed,
});

function authReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_AUTH: {
      return applyUpdateAuth(state, action);
    }
    case UPDATE_TOKEN: {
      return applyUpdateToken(state, action);
    }
    case UPDATE_USER_ID: {
      return applyUpdateUserId(state, action);
    }
    case UPDATE_USER_EMAIL_PERMISSIONS: {
      return applyUpdateUserEmailPermissions(state, action);
    }
    default:
      return state;
  }
}

export default authReducer;
