import React from "react";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menuExpanded: false,
    };
  }

  componentDidMount() { }

  closeAllMenus = () => {
    this.props.closeAllMenus();
  };

  render() {

    let menuOpen =
      this.props.homeMenuExpanded ||
      this.props.elogMenuExpanded ||
      this.props.refMenuExpanded;

    let menuSide = "left";
    if (menuOpen && !this.props.homeMenuExpanded) {
      menuSide = "right";
    }

    return (
      <>
        <div
          className={`bg-area ${menuOpen ? "active" : ""} ${menuSide === "left" ? "right" : "left"
            }`}
          onClick={() => {
            this.props.closeAllMenus();
          }}
        ></div>

        {
          //MAIN MENU
          this.props.homeMenuExpanded && (
            <div className="mobile-menu home-menu">
              <ul>
                <li
                  className={this.props.selectedRef ? "" : "disabled"}
                  onClick={() => {
                    if (this.props.selectedRef) {
                      this.closeAllMenus();
                      this.props.newElog();
                    }
                  }}
                >
                  New eLog
                </li>

                <li
                  onClick={() => {
                    this.closeAllMenus();
                    this.props.importElog();
                  }}
                >
                  Import eLog
                </li>

                <li
                  className={this.props.isAuthenticated ? "" : "disabled"}
                  onClick={() => {
                    if (this.props.isAuthenticated) {
                      this.closeAllMenus();
                      this.props.syncRefs();
                    }
                  }}
                >
                  Sync Refs
                </li>
                <li
                  onClick={() => {
                    this.closeAllMenus();
                    this.props.importRef();
                  }}
                >
                  Import Ref
                </li>

                <li
                  onClick={() => {
                    this.closeAllMenus();
                    this.props.debug();
                  }}
                >
                  Debug
                </li>
                {this.props.adminMenuVisible && (
                  <li
                    onClick={() => {
                      this.closeAllMenus();
                      this.props.admin();
                    }}
                  >
                    Admin
                  </li>
                )}
              </ul>
              <>{this.props.loginButton}</>
              <hr />
              <>{this.props.statusIndicator}</>
            </div>
          )
        }

        {
          //ELOG MENU
          this.props.elogMenuExpanded && (
            <div className="mobile-menu elog-menu">
              <div
                className="close-elog-menu"
                onClick={() => {
                  this.closeAllMenus();
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>

              <ul>
                <li
                  className={this.props.selectedElog ? "" : "disabled"}
                  onClick={() => {
                    if (this.props.selectedElog) {
                      this.closeAllMenus();
                      this.props.viewElog();
                    }
                  }}
                >
                  View/Edit
                </li>

                <li
                  className={
                    this.props.selectedElog && !this.props.elogCompleted
                      ? ""
                      : "disabled"
                  }
                  onClick={() => {
                    if (this.props.selectedElog && !this.props.elogCompleted) {
                      this.closeAllMenus();
                      this.props.markElogAsComplete();
                    }
                  }}
                >
                  Complete
                </li>

                <li
                  className={this.props.selectedElog ? "" : "disabled"}
                  onClick={() => {
                    if (this.props.selectedElog) {
                      this.closeAllMenus();
                      this.props.exportElog();
                    }
                  }}
                >
                  Export eLog
                </li>

                <li
                  className={
                    this.props.selectedElog && this.props.elogCompleted
                      ? ""
                      : "disabled"
                  }
                  onClick={() => {
                    if (this.props.selectedElog && this.props.elogCompleted) {
                      this.closeAllMenus();
                      this.props.resend();
                    }
                  }}
                >
                  Resend
                </li>

                <li
                  className={this.props.selectedElog ? "" : "disabled"}
                  onClick={() => {
                    if (this.props.selectedElog) {
                      this.closeAllMenus();
                      this.props.deleteSelectedElog();
                    }
                  }}
                >
                  Delete eLog
                </li>
              </ul>
            </div>
          )
        }

        {
          //REF FILE MENU
          this.props.refMenuExpanded && (
            <div className="mobile-menu ref-menu">
              <div
                className="close-elog-menu"
                onClick={() => {
                  this.closeAllMenus();
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </div>

              <ul>
                <li
                  className={this.props.selectedRef ? "" : "disabled"}
                  onClick={() => {
                    if (this.props.selectedRef) {
                      this.closeAllMenus();
                      this.props.exportRef();
                    }
                  }}
                >
                  Export Ref
                </li>

                <li
                  className={this.props.selectedRef ? "" : "disabled"}
                  onClick={() => {
                    if (this.props.selectedRef) {
                      this.closeAllMenus();
                      this.props.deleteRefFile();
                    }
                  }}
                >
                  Delete Ref File
                </li>
              </ul>
            </div>
          )
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appState: state,
    elogs: state.elogState.elogs,
    elogId: state.elogState.currentElogId,
  };
};

export default connect(mapStateToProps, null)(MobileMenu);
