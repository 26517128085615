import React from "react";
import { connect } from "react-redux";

import Table from "react-bootstrap/Table";

import { filterFieldMap } from "../services/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faSpinner } from "@fortawesome/free-solid-svg-icons";

class RefFileRepo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      refFiles: [],
      refsHidden: true,
    };
  }

  onClick = (refFile) => {
    this.props.onRefSelect(refFile);
  };

  toggleHiddenRefs = () => {
    this.setState((prevState) => {
      return { refsHidden: !prevState.refsHidden };
    });
  };

  render() {
    const refFiles = { ...this.props.refFiles };
    let refFileArray = Object.values(refFiles);

    if (refFileArray && refFileArray.length > 0) {
      refFileArray.sort((a, b) => (a.downloaded < b.downloaded ? 1 : -1));
    }

    const refsHidden = this.state.refsHidden;

    const isFetching = this.props.isFetchingGraphql || this.props.isFetchingIdb;

    return (
      <div className="reffile-repo">
        <Table bordered hover size="sm">
          <thead>
            <tr>
              <th>RefFiles</th>
              <th>Job</th>
              <th>Rig</th>
              <th>Last Changed</th>
            </tr>
          </thead>

          <tbody>
            {isFetching && (
              <tr>
                <td colSpan="4" className="spinner-container">
                  <FontAwesomeIcon icon={faSpinner} spin />
                </td>
              </tr>
            )}

            {!isFetching &&
              refFileArray.length > 0 &&
              refFileArray.map((refFile, index) => {
                let headerPage = filterFieldMap("0", refFile.refFile.FMAPfile);

                let job = "";
                let rig = "";
                let date = "";
                let currentlySelected = false;

                let isHidden = false;

                if (index >= 3 && refsHidden) {
                  isHidden = true;
                }

                if (headerPage) {
                  let jobObj = headerPage.find(
                    (field) =>
                      field.FieldName && field.FieldName.toUpperCase() === "JOB"
                  );
                  let rigObj = headerPage.find(
                    (field) =>
                      field.FieldName && field.FieldName.toUpperCase() === "RIG"
                  );
                  let dateObj = new Date(refFile.downloaded);

                  if (jobObj) {
                    job = jobObj.Supplied;
                  }

                  if (rigObj) {
                    rig = rigObj.Supplied;
                  }

                  if (dateObj) {
                    date = dateObj.toLocaleString("en-AU", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    });
                  }

                  if (refFile.id == this.props.selectedRef) {
                    currentlySelected = true;
                  }
                }

                return (
                  <tr
                    key={refFile.id}
                    // className={currentlySelected ? "bg-info" : null }
                    className={`${currentlySelected ? "bg-info" : ""} ${isHidden ? "collapse" : ""}`}
                    onClick={() => this.onClick(refFile.id)}
                  >
                    <td>{refFile.name}</td>
                    <td>{job}</td>
                    <td>{rig}</td>
                    <td>{date}</td>
                    {this.props.isMobile && (
                      <td
                        className="repo-mobile-expand"
                        onClick={() => {
                          this.props.toggleRefMenu();
                        }}
                      >
                        <FontAwesomeIcon icon={faEllipsisV} />
                      </td>
                    )}
                  </tr>
                );
              })}

            {refFileArray.length > 3 && !isFetching && (
              <tr
                key="showRefsBtn"
                onClick={() => {
                  this.toggleHiddenRefs();
                }}
              >
                <td colSpan="100%">{`${refsHidden ? "Show more" : "Show less"
                  }`}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //fieldMap: state.refFileState.refFiles[state.refFileState.currentRefId].refFile.PREFfile,
    refFiles: state.refFileState.refFiles,
    selectedRef: state.refFileState.currentRefId,
    isFetchingGraphql: state.refFileState.fetchingRefFiles,
    isFetchingIdb: state.refFileState.fetchingRefFilesIdb,
  };
};

export default connect(mapStateToProps, null)(RefFileRepo);

//export default RefFileRepo;
