import { openDB /* deleteDB, wrap, unwrap */ } from "idb";
import { getDbEnv, logErrorToServer } from "./Utilities";

const DB_VERSION = 4;
const DB_NAME = getDbEnv();

const dbPromise = openDB(DB_NAME, DB_VERSION, {
  /* eslint-disable */
  upgrade(db, oldVersion, newVersion, transaction) {
    switch (oldVersion) {
      case 0:

      case 1:
        //Placeholder for if DB doesn't exist
        //const refStore =
        db.createObjectStore("ref_files"); //, {keyPath: 'id', autoIncrement:true});
        //const txRef = db.transaction.objectStore('ref_files', 'readwrite');
        //refStore.createIndex('Downloaded', 'downloaded');
        //refStore.createIndex('Reference File', 'refFile');

        //const elogStore =
        db.createObjectStore("elogs" /*, {keyPath: 'id', autoIncrement:true}*/);
        //const txElog = db.transaction.objectStore('elogs', 'readwrite');
        //elogStore.createIndex('Created', 'created');
        //elogStore.createIndex('eLog', 'elog');
        db.createObjectStore(
          "prior_entries" /*, {keyPath: 'id', autoIncrement:true}*/
        );

      case 2:
        //const priorEntryStore =
        db.createObjectStore(
          "preferences" /*, {keyPath: 'id', autoIncrement:true}*/
        );

      case 3:
        db.createObjectStore("errors", { keyPath: "id", autoIncrement: true });
    }

    //For later db schema revisions
    //case 2:
  }
  /* eslint-enable */
});

class DBService {
  get(tablespace, key) {
    return dbPromise
      .then((db) => {
        return db.transaction(tablespace).objectStore(tablespace).get(key);
      })
      .catch((error) => {
        logErrorToServer(-1, error, {}, "DBService: get", {});
      });
  }

  getAllKeys(tablespace) {
    return dbPromise
      .then((db) => {
        return db.getAllKeys(tablespace);
      })
      .catch((error) => {
        logErrorToServer(-1, error, {}, "DBService: getAllKeys", {});
      });
  }

  getAll(tablespace) {
    return dbPromise
      .then((db) => {
        return db.getAll(tablespace);
      })
      .catch((error) => {
        logErrorToServer(-1, error, {}, "DBService: getAll", {});
      });
  }

  put(tablespace, object, key = null) {
    //key = parseInt(key)
    return dbPromise
      .then((db) => {
        if (key) {
          return db
            .transaction(tablespace, "readwrite")
            .objectStore(tablespace)
            .put(object, key);
        }
        return db
          .transaction(tablespace, "readwrite")
          .objectStore(tablespace)
          .put(object);
      })
      .catch((error) => {
        logErrorToServer(-1, error, {}, "DBService: put", {});
      });
  }

  putMany(tablespace, dataObject) {
    return dbPromise
      .then((db) => {
        const tx = db.transaction(tablespace, 'readwrite');
        const store = tx.objectStore(tablespace);
        Object.entries(dataObject).forEach(([key, val]) => {
          store.put(val, key)
        })
        return tx.complete;
      })
      .catch((error) => {
        logErrorToServer(-1, error, {}, "DBService: putMany", {});
      });
  }

  delete(tablespace, key) {
    dbPromise
      .then((db) => {
        const tx = db.transaction(tablespace, "readwrite");
        const store = tx.objectStore(tablespace);
        store.delete(key);
        return tx.complete;
      })
      .then(() => {
        console.log("Item deleted");
      })
      .catch((error) => {
        logErrorToServer(-1, error, {}, "DBService: delete", {});
      });
  }

  deleteAll(tablespace) {
    return dbPromise
      .then((db) => {
        return db
          .transaction(tablespace, "readwrite")
          .objectStore(tablespace)
          .clear();
      })
      .catch((error) => {
        logErrorToServer(-1, error, {}, "DBService: deleteAll", {});
      });
  }
}

export const PlodDb = new DBService();
