import {
  CHANGE_ADMIN_ELOG_FILTER,
  DELETE_ADMIN_ELOG_FILTER,
  SAVE_ELOG_FILTERS_IDB,
  LOAD_ELOG_FILTERS_IDB,
  SAVE_ELOG_FILTERS_IDB_SUCCESS,
  SAVE_ELOG_FILTERS_IDB_ERROR,
  LOAD_ELOG_FILTERS_IDB_ERROR,
  LOAD_ELOG_FILTERS_IDB_SUCCESS,
} from "../constants/actionTypes";

const doChangeFilter = (payload) => ({
  type: CHANGE_ADMIN_ELOG_FILTER,
  payload,
});

const doDeleteFilter = (filterKey) => ({
  type: DELETE_ADMIN_ELOG_FILTER,
  filterKey,
});

const doSaveFiltersToIdb = () => ({
  type: SAVE_ELOG_FILTERS_IDB,
});

const doLoadFiltersFromIdb = () => ({
  type: LOAD_ELOG_FILTERS_IDB,
});

const doSaveFiltersToIdbSuccess = () => ({
  type: SAVE_ELOG_FILTERS_IDB_SUCCESS,
});

const doLoadFiltersFromIdbSuccess = (activeFilters) => ({
  type: LOAD_ELOG_FILTERS_IDB_SUCCESS,
  activeFilters,
});

const doSaveFiltersToIdbError = () => ({
  type: SAVE_ELOG_FILTERS_IDB_ERROR,
});

const doLoadFiltersFromIdbError = () => ({
  type: LOAD_ELOG_FILTERS_IDB_ERROR,
});

export {
  doChangeFilter,
  doDeleteFilter,
  doSaveFiltersToIdb,
  doLoadFiltersFromIdb,
  doSaveFiltersToIdbSuccess,
  doLoadFiltersFromIdbSuccess,
  doSaveFiltersToIdbError,
  doLoadFiltersFromIdbError,
};
