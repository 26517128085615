import {
  ELOG_NEW,
  ELOG_DELETE,
  ELOG_TO_IDB,
  ELOG_TO_IDB_ERROR,
  ELOG_TO_IDB_SUCCESS,
  ELOGS_FROM_IDB,
  ELOGS_FROM_IDB_SUCCESS,
  ELOG_SELECT,
  ELOG_COMPLETE,
  LI_MODIFIED,
  CHANGE_CURRENT_LI,
  NEW_LI,
  DELETE_LI,
  HEADER_MODIFIED,
  ELOG_TO_GRAPHQL,
  ELOG_TO_GRAPHQL_ERROR,
  ELOG_TO_GRAPHQL_SUCCESS,
  IMPORT_ELOG_STATE,
  UPDATE_ELOG_HTML,
  ELOG_LOAD,
  CHANGE_FONT_SIZE,
  UPDATE_ELOG_RECIPIENTS,
  SEND_ELOG_PDF,
  ELOG_TO_GRAPHQL_CANCEL,
  SET_ELOG_ANEW,
} from "../constants/actionTypes";

import {
  getNextLineitemId,
  getLargestElogId,
  getNextElogId,
  getPageFromProduct,
  getProductDescription,
  getFontSize,
  getRefEmailBody,
} from "../services/Utilities";

const INITIAL_STATE = {
  elogs: {},
  currentElogId: null,
  currentLineItemId: null,
  elogSaving: false,
  errorState: false,
};

const applyAddElog = (state, action) => {
  const nextElogId = getNextElogId(state.elogs);
  const currentRefFile = action.refFile.refFile;

  const standingFile = currentRefFile.STANDINGfile;
  const fontSize = getFontSize(currentRefFile.PREFfile);
  const emailBody = getRefEmailBody(currentRefFile.PREFfile);

  let lineItems = {};
  let failCount = 0;

  if (standingFile) {
    standingFile.map((li, index) => {
      try {
        const id = index + 1 - failCount;
        const productCode = li.Product || li.ProdCode;

        const productDesc = getProductDescription(
          currentRefFile.PRODfile,
          productCode
        );
        const pageNo = getPageFromProduct(currentRefFile.PRODfile, productCode);

        const liClone = Object.assign({}, li);

        if (!li.ProdDesc) {
          liClone.ProdDesc = productDesc;
        }

        if (!li.Product) {
          liClone.Product = productCode;
        }

        lineItems[id] = {
          id: id,
          page: pageNo,
          fieldValues: liClone,
          product: productCode,
          description: productDesc,
        };
      } catch (e) {
        failCount++;
      }
    });
  }

  const standingLiCount = Object.keys(lineItems).length - failCount;

  const blankElog = {
    header: {},
    created: Date.now(),
    lineItems: lineItems,
    id: nextElogId,
    refFile: action.refFile.name,
    existsOnServer: false,
    fontSize: fontSize,
    completed: false,
    recipients: [],
    replyTo: "",
    pendingEmail: false,
    standingLiCount,
    emailBody: emailBody,
  };

  return {
    currentElogId: nextElogId,
    elogs: {
      ...state.elogs,
      [nextElogId]: blankElog,
    },
  };
};

const applyDeleteElog = (state, action) => {
  let clone = Object.assign({}, state.elogs);
  delete clone[action.id];
  return {
    ...state,
    elogs: clone,
    currentElogId: null,
  };
};

const applySelectElog = (state, action) => ({
  ...state,
  currentElogId: action.id,
});

const applyModifyLineItem = (state, action) => {
  //const elogs = Object.assign(state.elogs);
  //const elog = elogs[state.currentElogId];
  //const lineItems = elog.lineItems;
  //let clone = Object.assign({}, lineItems);
  //clone[state.currentLineItemId].fieldValues = Object.assign({}, action.payload)

  return {
    ...state,
    elogs: {
      ...state.elogs,
      [state.currentElogId]: {
        ...state.elogs[state.currentElogId],
        lineItems: {
          ...state.elogs[state.currentElogId].lineItems,
          [state.currentLineItemId]: {
            ...state.elogs[state.currentElogId].lineItems[
            state.currentLineItemId
            ],
            fieldValues: action.payload,
          },
        },
        needsUpdateIdb: true,
        needsUpdateServer: true,
      },
    },
  };
};

const applyCompleteElog = (state, action) => {
  return {
    ...state,
    elogs: {
      ...state.elogs,
      [state.currentElogId]: {
        ...state.elogs[state.currentElogId],
        completed: true,
        needsUpdateIdb: true,
        needsUpdateServer: true,
        recipients: action.payload.recipients,
        pendingEmail: action.payload.attemptSend,
        replyTo: action.payload.replyTo,
        emailBody: action.payload.emailBody,
      },
    },
  };
};

const applyModifyRecipients = (state, action) => {
  return {
    ...state,
    elogs: {
      ...state.elogs,
      [state.currentElogId]: {
        ...state.elogs[state.currentElogId],
        recipients: action.recipients,
      },
    },
  };
};

const applySendPdf = (state) => {
  return {
    ...state,
    elogs: {
      ...state.elogs,
      [state.currentElogId]: {
        ...state.elogs[state.currentElogId],
        pendingEmail: true,
      },
    },
  };
};

const applyModifyHeader = (state, action) => {
  const elogId = action.payload.id;
  //let clone = Object.assign({}, state.elogs[elogId].header)
  //clone = action.payload.header

  return {
    ...state,
    elogs: {
      ...state.elogs,
      [elogId]: {
        ...state.elogs[elogId],
        header: action.payload.data,
        needsUpdateIdb: true,
        needsUpdateServer: true,
      },
    },
  };
};

const applyAddLineItem = (state, action) => {
  let id = getNextLineitemId(state.elogs[state.currentElogId].lineItems);

  return {
    ...state,
    currentLineItemId: id,
    elogs: {
      ...state.elogs,
      [state.currentElogId]: {
        ...state.elogs[state.currentElogId],
        lineItems: {
          ...state.elogs[state.currentElogId].lineItems,
          [id]: {
            id: id,
            ...action.lineItem,
          },
        },
      },
    },
  };
};

const applyDeleteLineItem = (state) => {
  let clone = Object.assign({}, state.elogs[state.currentElogId].lineItems);
  delete clone[state.currentLineItemId];

  return {
    ...state,
    elogs: {
      ...state.elogs,
      [state.currentElogId]: {
        ...state.elogs[state.currentElogId],
        lineItems: clone,
        needsUpdateIdb: true,
        needsUpdateServer: true,
      },
    },
  };
};

const applyUploadElog = (state, action) => {
  return {
    ...state,
    elogs: {
      ...state.elogs,
      [action.payload.elog.id]: {
        ...state.elogs[action.payload.elog.id],
        isUploading: true,
      },
    },
  };
};

const applyCancelElogUpload = (state, action) => {
  return {
    ...state,
    elogs: {
      ...state.elogs,
      [action.elogId]: {
        ...state.elogs[action.elogId],
        isUploading: false,
      },
    },
  };
};

const applyUpdateElogHtml = (state, action) => {
  return {
    ...state,
    elogs: {
      ...state.elogs,
      [action.payload.id]: {
        ...state.elogs[action.payload.id],
        html: action.payload.html,
      },
    },
  };
};

const applyChangeFontSize = (state, action) => {
  return {
    ...state,
    elogs: {
      ...state.elogs,
      [action.payload.id]: {
        ...state.elogs[action.payload.id],
        fontSize: action.payload.fontSize,
        needsUpdateIdb: true,
        needsUpdateServer: true,
      },
    },
  };
};

const applyUploadElogSuccess = (state, action) => {
  return {
    ...state,
    elogs: {
      ...state.elogs,
      [action.id]: {
        ...state.elogs[action.id],
        needsUpdateServer: false,
        uploadError: null,
        isUploading: false,
        existsOnServer: true,
        pendingEmail: false,
      },
    },
  };
};

const applyUploadElogAnew = (state, action) => {
  return {
    ...state,
    elogs: {
      ...state.elogs,
      [action.payload.id]: {
        ...state.elogs[action.payload.id],
        needsUpdateServer: true,
        uploadError: null,
        isUploading: false,
        existsOnServer: false,
      },
    },
  };
};

const applyUploadElogError = (state, action) => {
  return {
    ...state,
    elogs: {
      ...state.elogs,
      [action.payload.id]: {
        ...state.elogs[action.payload.id],
        needsUpdateServer: true,
        uploadError: action.payload.error,
        isUploading: false,
      },
    },
  };
};

const applyGetAllElogs = (state) => ({
  ...state,
  fetchingElogs: true,
});

const applyChangeCurrentLineItem = (state, action) => ({
  ...state,
  currentLineItemId: action.id,
});

const applySaveElog = (state) => ({
  ...state,
  elogSaving: true,
  errorState: false,
});

const applySaveElogError = (state, action) => ({
  ...state,
  elogSaving: false,
  errorState: true,
  elogs: {
    ...state.elogs,
    [action.payload.id]: {
      ...state.elogs[action.payload.id],
      needsUpdateIdb: true,
      idbError: action.payload.error,
    },
  },
});

const applySaveElogSuccess = (state, action) => ({
  ...state,
  elogSaving: false,
  elogs: {
    ...state.elogs,
    [action.id]: {
      ...state.elogs[action.id],
      needsUpdateIdb: false,
      idbError: false,
    },
  },
});

const applyGetAllElogsSuccess = (state, action) => {
  //converts array of objects to single object with id as key
  const elogs = Object.assign(
    {},
    ...action.elogs.map((elog) => ({ [elog["id"]]: elog }))
  );
  const largestId = getLargestElogId(elogs);

  return {
    ...state,
    elogs: elogs,
    fetchingElogs: false,
    currentElogId: largestId,
  };
};

const applyImportState = (state, action) => {
  return action.payload;
};

const applyLoadElog = (state, action) => {
  return {
    currentElogId: action.payload.id,
    elogs: {
      ...state.elogs,
      [action.payload.id]: action.payload,
    },
  };
};

function elogReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ELOG_NEW: {
      return applyAddElog(state, action);
    }

    case SET_ELOG_ANEW: {
      return applyUploadElogAnew(state, action);
    }

    case ELOG_DELETE: {
      return applyDeleteElog(state, action);
    }

    case ELOG_TO_IDB: {
      return applySaveElog(state, action);
    }

    case ELOG_TO_IDB_ERROR: {
      return applySaveElogError(state, action);
    }

    case ELOG_TO_IDB_SUCCESS: {
      return applySaveElogSuccess(state, action);
    }

    case LI_MODIFIED: {
      return applyModifyLineItem(state, action);
    }

    case ELOGS_FROM_IDB: {
      return applyGetAllElogs(state, action);
    }

    case ELOGS_FROM_IDB_SUCCESS: {
      return applyGetAllElogsSuccess(state, action);
    }

    case CHANGE_CURRENT_LI: {
      return applyChangeCurrentLineItem(state, action);
    }

    case NEW_LI: {
      return applyAddLineItem(state, action);
    }

    case DELETE_LI: {
      return applyDeleteLineItem(state, action);
    }

    case ELOG_SELECT: {
      return applySelectElog(state, action);
    }

    case HEADER_MODIFIED: {
      return applyModifyHeader(state, action);
    }

    case ELOG_TO_GRAPHQL: {
      return applyUploadElog(state, action);
    }

    case ELOG_TO_GRAPHQL_CANCEL: {
      return applyCancelElogUpload(state, action);
    }

    case ELOG_TO_GRAPHQL_ERROR: {
      return applyUploadElogError(state, action);
    }

    case ELOG_TO_GRAPHQL_SUCCESS: {
      return applyUploadElogSuccess(state, action);
    }

    case UPDATE_ELOG_HTML: {
      return applyUpdateElogHtml(state, action);
    }

    case IMPORT_ELOG_STATE: {
      return applyImportState(state, action);
    }

    case ELOG_LOAD: {
      return applyLoadElog(state, action);
    }

    case CHANGE_FONT_SIZE: {
      return applyChangeFontSize(state, action);
    }

    case ELOG_COMPLETE: {
      return applyCompleteElog(state, action);
    }

    case SEND_ELOG_PDF: {
      return applySendPdf(state, action);
    }

    case UPDATE_ELOG_RECIPIENTS: {
      return applyModifyRecipients(state, action);
    }

    default:
      return state;
  }
}

export default elogReducer;
