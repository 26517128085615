import React from 'react';
import { connect } from 'react-redux';

import {
  DropdownButton,
  Dropdown,
  Tab,
  Tabs,
  Table,
  Button,
  Form
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faFileImport, faTrashAlt, faSync, faEye, faUserSlash, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons'
import { findRemoteRefFileByElog, generateCsvDataString, generateElogForImport, getApiHeaders, getApiUrl, getNextElogId, getPageFromProduct, getPageNameFromPageId, getRateFromProductCode, getRefFileIdForElog, getUniqueFieldNamesFromFieldMap, isDev } from '../services/Utilities';
import { VIEW_ELOG } from '../constants/screenTypes';
import { confirmAlert } from 'react-confirm-alert';
import Confirmation from './Confirmation';
import { doLoadRefFile, doSaveRefFile, doSelectRef } from '../actions/refFile';
import { doChangeScreen, doShowRecipientPrompt } from '../actions/global';
import { doDeleteElog, doLoadElog, doSaveElogIdb, doSelectElog } from '../actions/elog';
import { doChangeFilter, doDeleteFilter } from '../actions/adminPanel';
import { MinutesSince } from './MinutesSince';



class AdminPanel extends React.Component {

  MONTHS = [
    'January', 'February', 'March', 'April', 'May',
    'June', 'July', 'August', 'September',
    'October', 'November', 'December'
  ];

  constructor(props) {
    super(props);

    this.state = {
      elogs: [],
      users: [],
      refFiles: [],
      headers: {},
      currentElogHtml: '',
      refFilesLoading: false,
      elogsLoading: false,
      usersLoading: false,
      currentTab: 'elogs',
      filterDropdowns: {},
      filteredElogs: [],
      selectedElogs: [],
      elogFetchLimit: ', limit: 500',
      viewedElog: null
    }

  }

  API_URL = getApiUrl();


  componentDidMount() {

    this.setState({
      headers: getApiHeaders(this.props.authToken),
    }, () => {
      const currentDatetime = Date.now()
      const lastRefresh = this.props.lastRefresh;
      let timeSinceLastRefresh = 300000 // 5 minutes

      if (lastRefresh) {
        timeSinceLastRefresh = currentDatetime - lastRefresh
      }

      if (timeSinceLastRefresh >= 300000) {
        this.refreshData()
      }
    })

    this.setElogFilterValues()
    this.applyElogFilters()

  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.activeFilters) !== JSON.stringify(this.props.activeFilters)) {
      this.applyElogFilters();
    }
  }

  changeFilter(filterId, value) {

    //let activeFilters = this.state.activeFilters
    //activeFilters[filterId] = value;

    if (value === '') {
      this.props.onDeleteFilter(filterId);
      //delete activeFilters[filterId]
    } else {
      this.props.onChangeFilter({ key: filterId, val: value })
    }

    // this.setState( activeFilters, function() {
    //   this.applyElogFilters()
    // } )
  }

  setElogFilterValues() {

    let rigs = []
    let jobs = []
    let users = []

    const elogs = this.props.adminElogs;

    elogs.map(elog => {
      const elogData = elog.elog_data;

      if (elogData && elogData.length > 0) {
        const header = elogData[0]

        const rig = header.Rig
        const job = header.Job

        if (rig) {
          if (rigs.indexOf(rig) === -1) { //only if unique value
            rigs.push(rig)
          }
        }

        if (job) {
          if (jobs.indexOf(job) === -1) { //only if unique value
            jobs.push(job)
          }
        }

      }

      elog.elog_users.map(user => {
        const email = user.user.user_email
        if (users.indexOf(email) === -1) { //only if unique value
          users.push(email)
        }
      })


    })

    const filterDropdowns = {
      rigs,
      jobs,
      users
    }

    this.setState({
      filterDropdowns
    })

  }

  refreshData() {
    this.fetchUsers();
    this.fetchElogs();
    this.fetchRefFiles();
    this.props.setLastRefresh(Date.now())
  }

  fetchUsers = () => {

    this.setState({ usersLoading: true }, function () {
      fetch(this.API_URL, {
        method: 'POST',
        headers: this.state.headers,
        body: JSON.stringify({ query: '{ users(order_by: {role: desc, user_email: asc}) { role, user_email, company_id, user_id, email_active  } }' }),
      })
        .then(res => res.json())
        .then(res => {
          const users = res?.data?.users;
          if (users && users.length > 0) {
            this.setState({ users })
          }
          this.setState({ usersLoading: false })
        });
    });
  }

  fetchElogs = () => {
    this.setState({ elogsLoading: true }, function () {
      fetch(this.API_URL, {
        method: 'POST',
        headers: this.state.headers,
        body: JSON.stringify({
          query: `{ elogs( order_by: {elog_id: desc}${this.state.elogFetchLimit} ) { elog_id, elog_data, times_exported, elog_ref_file_name, elog_name, created_at, elog_html, elog_completed, elog_app_ver, recipients, elog_users {
          user {
            user_email
          }
        } } }` }),
      })
        .then(res => res.json())
        .then(res => {
          let elogs = res?.data?.elogs;
          if (elogs) {
            this.props.setAdminElogs(elogs)
            //this.setState({ elogs }, () => {
            setTimeout(() => {
              this.setElogFilterValues()
              this.applyElogFilters()
              this.setState({ elogsLoading: false })
            }, 1000)

            //})
          } else {
            this.setState({ elogsLoading: false })
          }



        });
    });
  }

  fetchRefFiles = () => {
    this.setState({ refFilesLoading: true }, function () {
      fetch(this.API_URL, {
        method: 'POST',
        headers: this.state.headers,
        body: JSON.stringify({
          query: `{ ref_files {
          ref_file_name
          created_at
          ref_file_id
          ref_file_data
          ref_file_job
          ref_file_rig
          ref_file_shift
          ref_file_users {
            user {
              user_email
              user_id
            }
          }
        } }` }),
      })
        .then(res => res.json())
        .then(res => {
          const refFiles = res?.data?.ref_files;

          if (refFiles && refFiles.length > 0) {
            this.setState({ refFiles })
          }
          this.setState({ refFilesLoading: false })
        });
    });
  }

  uploadRefFile = (refFileData, refFileName) => {

    let variables = {
      refFileData,
      refFileName
    };

    const refFileFieldmap = refFileData.FMAPfile;

    const jobField = refFileFieldmap.find((field) => field.FieldName.toUpperCase() === "JOB")
    const rigField = refFileFieldmap.find((field) => field.FieldName.toUpperCase() === "RIG")
    const shiftField = refFileFieldmap.find((field) => field.FieldName.toUpperCase() === "SHIFT")

    if (jobField && jobField.Supplied) {
      variables.refFileJob = jobField.Supplied;
    }

    if (rigField && rigField.Supplied) {
      variables.refFileRig = rigField.Supplied;
    }

    if (shiftField && shiftField.Supplied) {
      variables.refFileShift = shiftField.Supplied;
    }

    let query = `mutation AddRefFile($refFileData: jsonb!, $refFileName: String!, $refFileJob: String, $refFileRig: String, $refFileShift: String) {
      insert_ref_files(objects: {ref_file_data: $refFileData, ref_file_name: $refFileName, ref_file_job: $refFileJob, ref_file_rig: $refFileRig, ref_file_shift: $refFileShift}) {
        affected_rows
      }
    }`;

    fetch(this.API_URL, {
      method: 'POST',
      headers: this.state.headers,
      body: JSON.stringify({ query, variables }),
    })
      .then(res =>
        res.json()
      )
      .then(res => {
        if (!res.data) {
          alert('Error in ref file formatting.')
        } else {
          this.fetchRefFiles();
        }
      })
  }

  viewElog = (html) => {
    this.setState({ currentElogHtml: html })
  }

  clearAllFilters() {
    this.changeFilter('startdate', '');
    this.changeFilter('enddate', '');
    this.changeFilter('Job', '');
    this.changeFilter('Rig', '');
    this.changeFilter('user', '');
    this.changeFilter('exported', '');
    this.changeFilter('completed', '');
  }

  clearCurrentHtml = () => {
    this.setState({ currentElogHtml: '', viewedElog: null })
  }

  exportElog(elogData, elogName) {
    if (!elogData) {
      return;
    }

    const element = document.createElement("a");
    const file = new Blob([JSON.stringify(elogData)], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = elogName + '.ELOG';
    document.body.appendChild(element);
    element.click();



    let query = `mutation IncrementExportCounter($elogName: String!) {
      update_elogs(where: {elog_name: {_eq: $elogName}}, _inc: {times_exported: 1}) {
        affected_rows
      }
    }`;


    let variables = {
      elogName
    };

    fetch(this.API_URL, {
      method: 'POST',
      headers: this.state.headers,
      body: JSON.stringify({ query, variables }),
    })
      .then(res =>
        res.json()
      );




  }

  onRefFileChange = (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();

    reader.onload = (event) => {
      try {
        const refString = event.target.result
        const refObject = JSON.parse(refString)
        this.uploadRefFile(refObject, file.name)
      } catch (e) {
        alert('Error in RefFile')
      }
    }

    reader.readAsText(file);
  }

  deleteRefFile(id, name) {

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmation
            title='Are you sure?'
            caption={<>Are you sure you want to <b>delete</b> {name}?</>}
            affirmativeText='Yes, delete it!'
            declineText='No'
            onClose={onClose}
            onClick={() => {
              let query = `mutation DeleteRefFile($id: Int) {
                delete_users_ref_files(where: {ref_file_id: {_eq: $id}}) {
                  affected_rows
                }
        
                delete_ref_files(where: {ref_file_id: {_eq: $id}}) {
                  affected_rows
                }
              }`;
              onClose();

              //"Foreign key violation. update or delete on table "ref_files"  violates foreign key constraint "users_ref_files_ref_file_id_fkey" on table "users_ref_files""

              let variables = {
                id
              };

              fetch(this.API_URL, {
                method: 'POST',
                headers: this.state.headers,
                body: JSON.stringify({ query, variables }),
              })
                .then(res =>
                  res.json()
                )
                .then(() => {
                  this.fetchRefFiles();
                })

              onClose();
            }} />
        );
      }
    });

  }


  deleteElog(id) {

    let query = `mutation DeleteElog($id: Int) {
        delete_users_elogs(where: {elog_id: {_eq: $id}}) {
          affected_rows
        }
        delete_elogs(where: {elog_id: {_eq: $id}}) {
          affected_rows
        }
      }`;

    //"Foreign key violation. update or delete on table "ref_files" violates foreign key constraint "users_ref_files_ref_file_id_fkey" on table "users_ref_files""

    let variables = {
      id
    };

    fetch(this.API_URL, {
      method: 'POST',
      headers: this.state.headers,
      body: JSON.stringify({ query, variables }),
    })
      .then(res =>
        res.json()
      )
      .then(() => {
        this.fetchElogs();
      })

  }

  deleteRefFileUser(user, refFile) {

    const email = user.user_email
    const refFileName = refFile.ref_file_name

    const userId = user.user_id
    const refFileId = refFile.ref_file_id

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmation
            title='Are you sure?'
            caption={<>Do you want to <b>remove</b> {email} from {refFileName}?</>}
            affirmativeText='Yes, remove!'
            declineText='No'
            onClose={onClose}
            onClick={() => {
              let query = `mutation RemoveUserFromRef($userId: Int!, $refFileId: Int!) {
                delete_users_ref_files(where: {_and: {ref_file_id: {_eq: $refFileId}}, user_id: {_eq: $userId}}) {
                  affected_rows
                }
              }`;

              let variables = {
                userId,
                refFileId
              };

              fetch(this.API_URL, {
                method: 'POST',
                headers: this.state.headers,
                body: JSON.stringify({ query, variables }),
              })
                .then(res =>
                  res.json()
                )
                .then(() => {
                  this.fetchRefFiles();
                })

              onClose();
            }} />
        );
      }
    });
  }


  addRefFileUser(user, refFile) {

    const email = user.user_email
    const refFileName = refFile.ref_file_name

    const userId = user.user_id
    const refFileId = refFile.ref_file_id

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmation
            title='Are you sure?'
            caption={<>Do you want to <b>add</b> {email} to {refFileName}?</>}
            affirmativeText='Yes, add!'
            declineText='No'
            onClose={onClose}
            onClick={() => {

              let query = `mutation AddUserToRef($userId: Int!, $refFileId: Int!) {
                insert_users_ref_files(objects: {ref_file_id: $refFileId, user_id: $userId}) {
                  affected_rows
                }
              }`;

              let variables = {
                userId,
                refFileId
              };

              fetch(this.API_URL, {
                method: 'POST',
                headers: this.state.headers,
                body: JSON.stringify({ query, variables }),
              })
                .then(res =>
                  res.json()
                )
                .then(() => {
                  this.fetchRefFiles();
                })

              onClose();
            }} />
        );
      }
    });

  }

  updateEmailPermissions(userId, allowed) {

    if (!userId) {
      return;
    }

    let query = `mutation UpdateEmailPermissions($userId: Int!, $allowed: Boolean!) {
      update_users(where: {user_id: {_eq: $userId}}, _set: {email_active: $allowed}) {
        affected_rows
      }
    }`;

    let variables = {
      userId,
      allowed
    };

    fetch(this.API_URL, {
      method: 'POST',
      headers: this.state.headers,
      body: JSON.stringify({ query, variables }),
    })
      .then(res =>
        res.json()
      )
      .then(() => {
        this.fetchUsers();
      })
  }

  selectTab(key) {
    this.setState({ currentTab: key })
  }

  // Could split this out into multiple functions.
  editInUde(elogData, refFileName) {

    const deleteExistingElogs = (elogName) => {
      const currentElogs = Object.assign({}, this.props.elogs)
      const duplicates = Object.values(currentElogs).filter(elog => elog.header.PlodNo === elogName)

      duplicates.map(duplicate => {
        this.props.onDeleteElog(duplicate.id)
      })
    }

    const refResultId = getRefFileIdForElog(elogData, refFileName, this.props.refFiles);


    //found ref at this point

    if (refResultId != null) {
      this.props.onSelectRef(refResultId);

      const targetRef = Object.assign({}, this.props.refFiles[refResultId]);

      //START ELOG CREATE
      const newElogId = getNextElogId(this.props.elogs)
      const elogPayload = generateElogForImport(elogData, targetRef.refFile, newElogId, refFileName)

      if (elogPayload) {
        deleteExistingElogs(elogPayload.header.PlodNo)
        elogPayload.completed = false;
        this.props.onSaveElog({ id: elogPayload.id, elog: elogPayload })
        this.props.onLoadElog(elogPayload)
        this.props.onSelectElog(elogPayload.id)
        this.props.onChangeScreen(VIEW_ELOG)
        return;
      }
      //END ELOG CREATE
    }

    alert('Error importing elog. Contact administrator.')
  }

  // editInUdeWithCurrentRef(elogData) {

  //   const deleteExistingElogs = (elogName) => {
  //     const currentElogs = Object.assign({}, this.props.elogs)
  //     const duplicates = Object.values(currentElogs).filter(elog => elog.header.PlodNo === elogName)

  //     duplicates.map(duplicate => {
  //       this.props.onDeleteElog(duplicate.id)
  //     })
  //   }

  //   const currentlySelectedRef = this.props.refFiles[this.props.selectedRef]

  //   if (currentlySelectedRef && currentlySelectedRef?.name) {
  //     //START ELOG CREATE
  //     const newElogId = getNextElogId(this.props.elogs)
  //     const elogPayload = generateElogForImport(elogData, currentlySelectedRef.refFile, newElogId, currentlySelectedRef.name)

  //     if (elogPayload) {
  //       deleteExistingElogs(elogPayload.header.PlodNo)
  //       elogPayload.completed = false;
  //       this.props.onSaveElog({ id: elogPayload.id, elog: elogPayload })
  //       this.props.onLoadElog(elogPayload)
  //       this.props.onSelectElog(elogPayload.id)
  //       this.props.onChangeScreen(VIEW_ELOG)
  //       return;
  //     }
  //     //END ELOG CREATE
  //   }

  //   alert('Error importing elog. Please contact system administrator.')
  // }

  applyElogFilters() {
    const elogs = this.props.adminElogs;
    const filters = this.props.activeFilters;

    const filtered = elogs.filter(elog => {
      const elogData = elog.elog_data
      const elogUsers = elog.elog_users

      if (!elogData) {
        return false;
      }

      const header = elogData[0]

      for (let key in filters) {

        if ((key === 'startdate' || key === 'enddate') && header?.ShiftDate) {
          const shiftDateString = header.ShiftDate
          const dateSplit = shiftDateString.split(' ')
          const shiftDate = new Date(parseInt(dateSplit[2]), this.MONTHS.indexOf(dateSplit[1]), parseInt(dateSplit[0]))
          const compDateSplit = filters[key].split('-');
          const compDate = new Date(parseInt(compDateSplit[0]), parseInt(compDateSplit[1]) - 1, parseInt(compDateSplit[2]))

          if (key === 'startdate') {
            if (shiftDate < compDate) {
              return false;
            }
          } else if (key === 'enddate') {
            if (shiftDate > compDate) {
              return false;
            }
          }

        }

        else if (key === 'user') {
          if (elogUsers.length < 1 || elogUsers[0].user.user_email != filters[key]) {
            return false;
          }
        }

        else if (key === 'exported') {
          const exportCount = elog.times_exported;
          let beenExported = 'false';
          if (exportCount > 0) {
            beenExported = 'true';
          }

          if (beenExported != filters[key]) {
            return false;
          }

        }

        else if (key === 'completed') {
          const completed = elog.elog_completed
          const filter_val = (filters[key] === 'true')

          if (completed !== filter_val) {
            return false;
          }
        }

        else {

          if (header[key] === undefined || header[key] != filters[key]) {
            return false;
            // } else {
            //   return true;
          }

        }
      }

      return true;

    })

    this.setState({ filteredElogs: filtered })
    //return filtered;
  }

  elogCheckBox(e, targetId) {
    const checked = e.target.checked;

    if (checked) {
      this.setState((prevState) => ({
        selectedElogs: [...prevState.selectedElogs, targetId]
      }))
    } else {
      this.setState((prevState) => ({
        selectedElogs: prevState.selectedElogs.filter(id => id !== targetId)
      }))
    }
  }

  selectAllElog(e) {
    if (e.target.checked) {
      this.setState({ selectedElogs: this.state.filteredElogs.map((elog) => elog.elog_id) })
    } else {
      this.setState({ selectedElogs: [] })
    }
  }

  deleteSelectedItems() {
    const selectedElogIds = [...this.state.selectedElogs];

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmation
            title='Are you sure?'
            caption={<>Do you want to <b>delete</b> {selectedElogIds.length} elogs?</>}
            affirmativeText='Yes, delete them!'
            declineText='No'
            onClose={onClose}
            onClick={() => {

              selectedElogIds.map((elogId) => {
                this.deleteElog(elogId)
              })

              this.setState({ selectedElogs: [], elogsLoading: true })

              onClose();
            }} />
        );
      }
    });

  }


  viewSelectedItems() {
    const selectedElogIds = [...this.state.selectedElogs];
    const filteredElogs = this.state.filteredElogs;

    let combinedHtml = '<div class="multiple-plods">';

    filteredElogs.map((elog) => {
      if (selectedElogIds.includes(elog.elog_id)) {
        combinedHtml += elog.elog_html;
      }
    })
    combinedHtml += '</div>'

    this.viewElog(combinedHtml)

    //this.setState({ selectedElogs: [] })
  }

  removeElogLimit() {
    this.setState({ elogFetchLimit: ', limit: 2500' }, function () {
      this.fetchElogs();
    })
  }

  selectCsvReport = () => {
    const filteredElogs = [...this.state.filteredElogs]

    let totalColumnHeadings = []
    let allLineItems = []

    filteredElogs.forEach((elog) => {

      const refFileForReport = findRemoteRefFileByElog(elog.elog_name, elog.elog_data, this.state.refFiles);

      if (refFileForReport) {
        const fieldMap = refFileForReport?.ref_file_data?.FMAPfile;
        const prodMap = refFileForReport?.ref_file_data?.PRODfile;
        const columnHeadings = getUniqueFieldNamesFromFieldMap(fieldMap);

        if (!columnHeadings || columnHeadings.length <= 0) {
          alert(`Corrupted fieldmap.`);
        }
        totalColumnHeadings = [...new Set(totalColumnHeadings.concat(columnHeadings))]

        const elogData = elog.elog_data;

        if (Array.isArray(elogData)) {
          [...elogData].forEach((lineItem, index) => {

            let liPageName = 'Unknown'
            let liRate = 0;
            let liTotal = 0;

            //Page name isn't in elogData.
            if (Object.prototype.hasOwnProperty.call(lineItem, "Product")) {
              const rate = getRateFromProductCode(prodMap, lineItem.Product);
              const pageId = getPageFromProduct(prodMap, lineItem.Product);
              const pageName = getPageNameFromPageId(pageId, fieldMap);

              if (pageId) {
                if (rate && parseFloat(rate) > 0) {
                  liRate = rate;
                }

                if (pageName) {
                  liPageName = pageName;
                }
              } else {
                console.log(`Product ${lineItem.Product} does not exist in ref file ${refFileForReport.ref_file_name}.`)
              }



              if (Object.prototype.hasOwnProperty.call(lineItem, "Cust") && lineItem.Cust !== '') {
                if (!(parseFloat(liRate) <= 0 || parseFloat(lineItem.Cust)) <= 0) {
                  liTotal = parseFloat(liRate) * parseFloat(lineItem.Cust);
                }
              }

            } else if (index === 0) { //header page
              liPageName = 'Header';
            }

            let lineItemClone = Object.assign({}, lineItem);
            lineItemClone['PAGE'] = liPageName;
            lineItemClone['RATE'] = liRate;
            lineItemClone['TOTAL'] = liTotal;

            allLineItems.push(lineItemClone)

          });

        } else {
          alert(`Didn't process elog ${elog.elog_name}. Data corrupted.`)
        }
      } else {
        alert(`Didn't process elog ${elog.elog_name} because couldn't find associated ref file.`)
      }
    });

    totalColumnHeadings = [...totalColumnHeadings, 'RATE', 'TOTAL']

    const csvDataString = generateCsvDataString(totalColumnHeadings, allLineItems)

    var link = document.createElement("a");
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      var blob = new Blob([csvDataString], { type: 'text/csv;charset=utf-8;' });
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "elog-report");
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert("Your browser doesn't support this function");
    }

    //download csv
    // window.open(
    //   `data:text/csv;charset=utf-8,${csvDataString}`
    // );


  }


  selectCostReport = () => {

    //Total metres drilled
    //discern between hq3 and hq. Same with pq. This shouldn't count as two lots of drilling.
    //Metres per shift
    //Total activity hours 
    //Do they consider drilling as activity hours? If so, same issue as total metres drilled.
    //Total Shifts Elapsed
    //Total Revenue
    //Total Cost
    //Unaccounted for (cust 0 and tulla 0)
    //revenue per metre
    //cost per metre
    //Average hours per shift

    //Rig work total and cost
    //consumables summary
    //drilling/grouting summary
    //equipment hire summary

    const filteredElogs = [...this.state.filteredElogs]

    let totalCustDrillingMetres = 0;
    let totalCoyDrillingMetres = 0;
    let totalCrewHours = 0;

    let totalCustDrillingCost = 0;
    let totalCoyDrillingCost = 0;

    let totalShifts = 0;
    let rigsInReport = []
    let shiftDatesInReport = [];
    let jobsInReport = []
    let holesInReport = []
    let customersInReport = []

    let consumablesInReport = {}
    let totalCustActivityHours = 0
    let totalCoyActivityHours = 0
    let totalCustActivityCost = 0
    let totalCoyActivityCost = 0

    let totalCustHireDays = 0
    let totalCoyHireDays = 0
    let totalCustHireCost = 0
    let totalCoyHireCost = 0

    filteredElogs.forEach((elog) => {


      const refFileForReport = findRemoteRefFileByElog(elog.elog_name, elog.elog_data, this.state.refFiles);

      if (refFileForReport) {
        const prodMap = refFileForReport?.ref_file_data?.PRODfile;

        const elogData = elog.elog_data;

        if (Array.isArray(elogData)) {

          elogData.forEach((lineItem, index) => {

            const productCode = lineItem?.Product;

            if (productCode) {

              const pageId = getPageFromProduct(prodMap, lineItem.Product);
              const rate = getRateFromProductCode(prodMap, productCode)

              //show cost breakdown. Could be different rates for different refs

              //drilling line items
              if (pageId == 2) {
                if (lineItem?.MetresFrom && lineItem?.MetresTo) {
                  if (lineItem?.Cust && parseFloat(lineItem.Cust)) {
                    totalCustDrillingMetres += parseFloat(lineItem.Cust)

                    if (rate === 0 || rate) {
                      totalCustDrillingCost += rate * parseFloat(lineItem.Cust);
                    }
                  }

                  if (lineItem?.Coy && parseFloat(lineItem.Coy)) {
                    totalCoyDrillingMetres += parseFloat(lineItem.Coy)

                    if (rate === 0 || rate) {
                      totalCoyDrillingCost += rate * parseFloat(lineItem.Coy);
                    }
                  }
                }
              }

              //activity line items
              if (pageId == 6) {
                if (lineItem?.Cust && parseFloat(lineItem.Cust)) {
                  totalCustActivityHours += parseFloat(lineItem.Cust)

                  if (rate === 0 || rate) {
                    totalCustActivityCost += rate * parseFloat(lineItem.Cust);
                  }
                }

                if (lineItem?.Coy && parseFloat(lineItem.Coy)) {
                  totalCoyActivityHours += parseFloat(lineItem.Coy)

                  if (rate === 0 || rate) {
                    totalCoyActivityCost += rate * parseFloat(lineItem.Coy);
                  }
                }
              }

              //hire equipment line items
              if (pageId == 10) {
                if (lineItem?.Cust && parseFloat(lineItem.Cust)) {
                  totalCustHireDays += parseFloat(lineItem.Cust)

                  if (rate === 0 || rate) {
                    totalCustHireCost += rate * parseFloat(lineItem.Cust);
                  }
                }

                if (lineItem?.Coy && parseFloat(lineItem.Coy)) {
                  totalCoyHireDays += parseFloat(lineItem.Coy)

                  if (rate === 0 || rate) {
                    totalCoyHireCost += rate * parseFloat(lineItem.Coy);
                  }
                }
              }

              //consumables line item
              if (pageId == 5) {
                // if (lineItem?.Cust && parseFloat(lineItem.Cust)) {
                //   totalCustActivityHours += parseFloat(lineItem.Cust)

                //   if (rate === 0 || rate) {
                //     totalCustActivityCost += rate * parseFloat(lineItem.Cust);
                //   }
                // }

                // if (lineItem?.Coy && parseFloat(lineItem.Coy)) {
                //   totalCoyActivityHours += parseFloat(lineItem.Coy)

                //   if (rate === 0 || rate) {
                //     totalCoyActivityCost += rate * parseFloat(lineItem.Coy);
                //   }
                // }

                if (Object.prototype.hasOwnProperty.call(consumablesInReport, lineItem.Product)) {
                  consumablesInReport[lineItem.Product] += parseFloat(lineItem.Qty);
                } else {
                  consumablesInReport[lineItem.Product] = parseFloat(lineItem.Qty);
                }
              }

              //crew line items
              if (pageId == 1) {
                if (lineItem?.Hours && parseFloat(lineItem.Hours)) {
                  totalCrewHours += parseFloat(lineItem.Hours)
                }
              }
            }

            else if (index === 0) {
              if (lineItem?.Rig && rigsInReport.indexOf(lineItem.Rig) === -1) {
                rigsInReport.push(lineItem.Rig)
              }

              if (lineItem?.Job && jobsInReport.indexOf(lineItem.Job) === -1) {
                jobsInReport.push(lineItem.Job)
              }

              if (lineItem?.Hole && holesInReport.indexOf(lineItem.Hole) === -1) {
                holesInReport.push(lineItem.Hole)
              }

              if (lineItem?.CustName && customersInReport.indexOf(lineItem.CustName) === -1) {
                customersInReport.push(lineItem.CustName)
              }

              if (lineItem?.ShiftDate && shiftDatesInReport.indexOf(lineItem.ShiftDate) === -1) {
                shiftDatesInReport.push(lineItem.ShiftDate);
              }
            }

          })
        }

        totalShifts += 1;

      } else {
        alert(`Didn't process elog ${elog.elog_name} because couldn't find associated ref file.`)
      }



    })

    console.log(`Total customer metres drilled: ${totalCustDrillingMetres}`)
    console.log(`Total company metres drilled: ${totalCoyDrillingMetres}`)
    console.log(`Drilling charged to customer: ${new Intl.NumberFormat('en-US',
      { style: 'currency', currency: 'USD' }).format(totalCustDrillingCost)}`)
    console.log(`Drilling charged to company: ${new Intl.NumberFormat('en-US',
      { style: 'currency', currency: 'USD' }).format(totalCoyDrillingCost)}`)
    console.log(`Total crew hours: ${totalCrewHours}`)
    console.log(`Total shifts: ${totalShifts}`)
    console.log(`Rigs in report: ${rigsInReport}`);
    console.log(`Jobs in report: ${jobsInReport}`);
    console.log(`Customers in report: ${customersInReport}`);
    console.log(`Holes in report: ${holesInReport}`);
    console.log(`Dates in report: ${shiftDatesInReport}`);
    console.log(`Metres per shift: ${(totalCustDrillingMetres + totalCoyDrillingMetres) / totalShifts}`)
    console.log(`Consumables in report: ${JSON.stringify(consumablesInReport)}`)
    console.log(`Total customer activity hours: ${totalCustActivityHours}`)
    console.log(`Total company activity hours: ${totalCoyActivityHours}`)
    console.log(`Total customer activity cost: ${totalCustActivityCost}`)
    console.log(`Total company activity cost: ${totalCoyActivityCost}`)

    console.log(`Total customer hire days: ${totalCustHireDays}`)
    console.log(`Total company hire days: ${totalCoyHireDays}`)
    console.log(`Total customer hire cost: ${totalCustHireCost}`)
    console.log(`Total company hire cost: ${totalCoyHireCost}`)
  }

  render() {

    const { users, refFiles, filteredElogs, currentElogHtml, refFilesLoading, elogsLoading, usersLoading, currentTab, filterDropdowns, selectedElogs } = this.state;

    let userRole = null

    if (!isDev()) {
      if (this.props?.authInfo?.isAuthenticated && this.props.authInfo.user) {
        userRole = this.props.authInfo.user['https://hasura.io/jwt/claims/roles'][0]
      } else {
        return (<p>Not authenticated</p>)
      }

      if (userRole !== 'manager') {
        return (<p>Not authenticated</p>)
      }
    }

    const activeFilters = this.props.activeFilters;
    console.log(JSON.stringify(activeFilters))
    return (

      <div className='admin-panel'>

        {currentElogHtml &&
          <div>
            <div className='admin-title-bar'>
              <Button variant='info' onClick={() => this.clearCurrentHtml()}>Back</Button>
              {this.state.viewedElog &&
                <Button variant='info' onClick={() => {
                  this.props.onShowRecipientPrompt({ elog: Object.assign({}, this.state.viewedElog) })
                }}>Send</Button>
              }
            </div>
            <div dangerouslySetInnerHTML={{ __html: currentElogHtml }} />
          </div>
        }

        {!currentElogHtml &&
          <div>

            <div className='admin-title-bar'>
              <Button variant='info' onClick={() => this.refreshData()}>Refresh <FontAwesomeIcon icon={faSync} /></Button>

              {currentTab === 'refFiles' &&
                <Button variant='info' id='upload-ref-button' onClick={() => { document.getElementById('upload-ref').click() }}>Upload RefFile <FontAwesomeIcon icon={faFileImport} /></Button>
              }

              {currentTab === 'elogs' &&
                <>
                  {Object.keys(activeFilters).length > 0 &&
                    <Button variant='info' onClick={() => this.clearAllFilters()}>Clear Filters <FontAwesomeIcon icon={faTimes} /></Button>
                  }
                  {/* <Button variant='info' onClick={() => this.selectCostReport()}>Cost CSV ({filteredElogs.length}) <FontAwesomeIcon icon={faTable} /></Button>
                  <Button variant='info' onClick={() => this.selectCsvReport()}>Export CSV ({filteredElogs.length}) <FontAwesomeIcon icon={faTable} /></Button> */}
                </>
              }

              {currentTab === 'elogs' && selectedElogs.length > 0 &&
                <>
                  <Button variant='info' id='print-button' onClick={() => this.viewSelectedItems()}>View ({selectedElogs.length}) <FontAwesomeIcon icon={faEye} /></Button>
                  <Button variant='danger' id='bulk-delete-button' onClick={() => this.deleteSelectedItems()}>Delete ({selectedElogs.length}) <FontAwesomeIcon icon={faTrashAlt} /></Button>
                </>
              }
            </div>

            {currentTab === 'elogs' && !elogsLoading &&
              <div className='filters'>

                <div className='date-filters'>

                  <input type="date" id="date-start" name="date-start" value={activeFilters.startdate ? activeFilters.startdate : ''} onChange={e => this.changeFilter('startdate', e.target.value)}></input>

                  <p> to </p>

                  <input type="date" id="date-end" name="date-end" value={activeFilters.enddate ? activeFilters.enddate : ''} onChange={e => this.changeFilter('enddate', e.target.value)}
        /*value="2018-07-22"
        min="2018-01-01" max="2018-12-31"*/></input>
                </div>
                <div className='other-filters'>
                  <select name="rigs" id="rigs" value={activeFilters.Rig ? activeFilters.Rig : ''} onChange={e => this.changeFilter('Rig', e.target.value)}>
                    <option key='empty_rig' value="">Rig</option>
                    {filterDropdowns?.rigs?.length > 0 &&
                      filterDropdowns.rigs.map(rig => {
                        return (<option key={rig} value={rig}>{rig}</option>)
                      })
                    }
                  </select>

                  <select name="jobs" id="jobs" value={activeFilters.Job ? activeFilters.Job : ''} onChange={e => this.changeFilter('Job', e.target.value)}>
                    <option key='empty_job' value="">Job</option>
                    {filterDropdowns?.jobs?.length > 0 &&
                      filterDropdowns.jobs.map(job => {
                        return (<option key={job} value={job}>{job}</option>)
                      })
                    }
                  </select>

                  <select name="users" id="users" value={activeFilters.user ? activeFilters.user : ''} onChange={e => this.changeFilter('user', e.target.value)}>
                    <option key='empty_user' value="">User</option>
                    {filterDropdowns?.users?.length > 0 &&
                      filterDropdowns.users.map(user => {
                        return (<option key={user} value={user}>{user}</option>)
                      })
                    }
                  </select>

                  <select name="exported" id="exported" value={activeFilters.exported ? activeFilters.exported : ''} onChange={e => this.changeFilter('exported', e.target.value)}>
                    <option key='empty_status' value="">Export Status</option>
                    <option key='not_exported' value="false">Not exported</option>
                    <option key='exported' value="true">Exported</option>
                  </select>

                  <select name="completed" id="completed" value={activeFilters.completed ? activeFilters.completed : ''} onChange={e => this.changeFilter('completed', e.target.value)}>
                    <option key='empty_completed' value="">Completed Status</option>
                    <option key='incomplete' value='false'>In Progress</option>
                    <option key='complete' value='true'>Completed</option>
                  </select>

                </div>
              </div>
            }

            <div className='data-viewer'>
              <Tabs activeKey={this.state.currentTab} onSelect={(key) => this.selectTab(key)} id="top-tabs">
                <Tab eventKey="elogs" title="ELOGS">
                  <Table responsive striped hover borderless size='sm'>
                    <thead>
                      <tr>
                        <th><input onChange={(e) => { this.selectAllElog(e) }} type="checkbox"></input></th>
                        <th>PlodNo</th>
                        <th>Date</th>
                        <th>Shift</th>
                        <th>Job</th>
                        <th>Rig</th>
                        <th>Cust</th>
                        <th>User</th>
                        <th>Ver</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>

                      {!elogsLoading && filteredElogs.length <= 0 &&
                        <tr><td colSpan='100%'>No elogs. Try a different filter.</td></tr>
                      }

                      {!elogsLoading && filteredElogs.length > 0 &&
                        <tr><td colSpan='100%'>Showing {filteredElogs.length} elogs.
                          <MinutesSince since={this.props.lastRefresh} />
                          {this.state.elogFetchLimit &&
                            <a href='#' onClick={() => this.removeElogLimit()}> Click here to fetch more</a>
                          }
                        </td></tr>
                      }

                      {!elogsLoading && filteredElogs.map((elog, index) => {
                        const name = elog.elog_name;
                        const elogData = elog.elog_data;

                        let shiftDate = '';

                        if (elogData.length > 0) {
                          shiftDate = elogData[0]?.ShiftDate
                        }

                        const html = elog.elog_html;
                        const elogUsers = elog.elog_users//[0].user.user_email
                        const refFileName = elog.elog_ref_file_name;
                        const key = `elog-row-${index}`
                        const id = elog.elog_id;
                        const appVersion = elog.elog_app_ver;

                        let shift = "?";
                        let job = "?"
                        let rig = "?"
                        let cust = "?"

                        if (elogData && elogData[0]) {
                          if (elogData[0]?.Shift) {
                            shift = elogData[0]?.Shift;
                          }
                          if (elogData[0]?.Job) {
                            job = elogData[0]?.Job;
                          }
                          if (elogData[0]?.Rig) {
                            rig = elogData[0]?.Rig;
                          }
                          if (elogData[0]?.Customer) {
                            cust = elogData[0]?.Customer;
                          }
                        }

                        return (
                          <tr key={key}>
                            <td><input checked={selectedElogs.includes(id)} onChange={(e) => { this.elogCheckBox(e, id) }} type="checkbox"></input></td>
                            <td className='align-middle'><p>{name}</p></td>
                            <td>{shiftDate}</td>
                            <td>{shift}</td>
                            <td>{job}</td>
                            <td>{rig}</td>
                            <td>{cust}</td>
                            <td>{elogUsers.map(user => {
                              if (user.user.user_email) {
                                return user.user.user_email.substring(0, 10) + '...'
                              }
                            })}</td>
                            <td>{appVersion}</td>
                            <td>
                              <Button variant='info' onClick={() => this.editInUde(elogData, refFileName)}>Edit in UDE <FontAwesomeIcon icon={faFileExport} /></Button>
                              {/* <Button variant='info' onClick={() => this.editInUdeWithCurrentRef(elogData)}>Edit with Current Ref <FontAwesomeIcon icon={faFileExport} /></Button> */}
                              <Button variant='info' onClick={() => this.exportElog(elogData, name)}>Export to PLOD <FontAwesomeIcon icon={faFileExport} /></Button>
                              <Button variant='info' onClick={() => {
                                this.viewElog(html)
                                this.setState({ viewedElog: Object.assign({}, elog) })
                              }}>View <FontAwesomeIcon icon={faEye} /></Button>
                              <Button variant='danger' onClick={() => {

                                confirmAlert({
                                  customUI: ({ onClose }) => {
                                    return (
                                      <Confirmation
                                        title='Are you sure?'
                                        caption={<>Do you want to <b>delete</b> {name}?</>}
                                        affirmativeText='Yes, delete it!'
                                        declineText='No'
                                        onClose={onClose}
                                        onClick={() => {
                                          this.deleteElog(id)
                                          onClose();
                                        }} />
                                    );
                                  }
                                });


                              }}>Delete <FontAwesomeIcon icon={faTrashAlt} /></Button>
                            </td>
                          </tr>
                        )
                      })}
                      {elogsLoading &&
                        <tr>
                          <td className='spinner-container'>
                            <FontAwesomeIcon icon={faSpinner} spin />
                          </td>
                        </tr>
                      }
                    </tbody>
                  </Table>
                </Tab>

                <Tab eventKey="refFiles" title="REF FILES">
                  <Table responsive striped hover borderless size='sm'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Created</th>
                        <th>Users</th>
                        <th>Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {!refFilesLoading && refFiles.map((refFile, index) => {
                        const name = refFile.ref_file_name;
                        //const created_at = refFile.created_at;
                        const users = refFile.ref_file_users;
                        const id = refFile.ref_file_id;
                        const key = `ref-row-${index}`
                        const dateTime = new Date(refFile.created_at)
                        const created_at = dateTime.toLocaleTimeString("en-AU", { hour: '2-digit', minute: '2-digit' }) + ' ' + dateTime.toLocaleDateString("en-AU");

                        const usersString = JSON.stringify(users)

                        const unaddedUsers = this.state.users.filter(user => !usersString.includes(user.user_email))

                        return (
                          <tr key={key}>
                            <td>{name}</td>
                            <td>{created_at}</td>
                            <td className='emails'>
                              {
                                users.map((user, index) => {
                                  return (
                                    <React.Fragment key={key + '-' + index}>
                                      <Button variant='danger' onClick={() => this.deleteRefFileUser(user.user, refFile)}>{user.user.user_email} <FontAwesomeIcon icon={faUserSlash} /></Button>
                                      <br />
                                    </React.Fragment>
                                  )
                                })
                              }
                              {unaddedUsers.length > 0 &&
                                <DropdownButton variant='info' title='Add User '>
                                  {unaddedUsers.map((user, index) => {
                                    return (<Dropdown.Item key={key + '-' + index} onClick={() => this.addRefFileUser(user, refFile)}>{user.user_email}</Dropdown.Item>)
                                  })}
                                </DropdownButton>
                              }
                            </td>
                            <td>
                              <Button variant='danger' onClick={() => this.deleteRefFile(id, name)}>Delete <FontAwesomeIcon icon={faTrashAlt} /></Button>
                            </td>
                          </tr>
                        )
                      })}

                      {refFilesLoading &&
                        <tr>
                          <td className='spinner-container'>
                            <FontAwesomeIcon icon={faSpinner} spin />
                          </td>
                        </tr>
                      }
                    </tbody>

                  </Table>
                </Tab>

                <Tab eventKey="users" title="USERS">
                  <Table responsive striped hover borderless size='sm'>
                    <thead>
                      <tr>
                        <th>Email</th>
                        <th>Role</th>
                        <th>Emails Allowed?</th>
                      </tr>
                    </thead>

                    <tbody>
                      {!usersLoading && users.map((user, index) => {
                        const email = user.user_email;
                        const role = user.role;
                        const emailAllowed = user.email_active;

                        const key = `user-row-${index}`

                        return (
                          <tr key={key}>
                            <td>{email}</td>
                            <td>{role}</td>
                            <td><Form.Check type='switch' id={`user-email-switch-${index}`} checked={emailAllowed} onChange={e => {
                              this.updateEmailPermissions(user.user_id, e.target.checked)
                            }} /></td>
                          </tr>
                        )
                      })}
                      {usersLoading &&
                        <tr>
                          <td className='spinner-container'>
                            <FontAwesomeIcon icon={faSpinner} spin />
                          </td>
                        </tr>
                      }
                    </tbody>
                  </Table>
                </Tab>
              </Tabs>
            </div>
          </div>
        }

        <div className='ref-upload'>
          <input type='file' accept='.REF' id='upload-ref' name='upload-ref' onChange={this.onRefFileChange} />
        </div>

      </div>
    );
  }

}

const mapDispatchToProps = (dispatch) => {
  return ({
    onLoadRef: (payload) => dispatch(doLoadRefFile(payload)),
    onSaveRefFile: (payload) => dispatch(doSaveRefFile(payload)),
    onChangeScreen: screen => dispatch(doChangeScreen(screen)),
    onSaveElog: (payload) => dispatch(doSaveElogIdb(payload)),
    onLoadElog: (payload) => dispatch(doLoadElog(payload)),
    onSelectElog: id => dispatch(doSelectElog(id)),
    onSelectRef: id => dispatch(doSelectRef(id)),
    onDeleteElog: id => dispatch(doDeleteElog(id)),

    onChangeFilter: payload => dispatch(doChangeFilter(payload)),
    onDeleteFilter: filterKey => dispatch(doDeleteFilter(filterKey)),

    onShowRecipientPrompt: (payload) => dispatch(doShowRecipientPrompt(payload)),
  });
}

const mapStateToProps = (state) => {
  return ({
    authInfo: state.authState.auth,
    authToken: state.authState.token,

    refFiles: state.refFileState.refFiles,
    selectedRef: state.refFileState.currentRefId,
    elogs: state.elogState.elogs,

    activeFilters: state.adminPanelState.activeFilters,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPanel);
