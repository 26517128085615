import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

import Table from "react-bootstrap/Table";

import { connect } from "react-redux";
import { doSelectElog } from "../actions/elog";
import { doSelectRef } from "../actions/refFile";
import { generateElogForExport, getRefFileIdForElog } from "../services/Utilities";
//import { getRefFileIdForElog } from "../services/Utilities";

//When generating elog table line, error check for empty fields

class ElogRepo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      elogsHidden: true,
    };
  }

  componentDidMount() { }

  onClick = (key) => {
    const selectedElog = this.props.elogs[key];
    const refFileName = selectedElog.refFile;
    const elogData = generateElogForExport(selectedElog, 'json');
    const associatedRefId = getRefFileIdForElog(elogData, refFileName, this.props.refFiles);
    if (associatedRefId != null) {
      this.props.onSelectRef(associatedRefId);
    }
    this.props.onSelectElog(key);
  };

  toggleHiddenElogs = () => {
    this.setState((prevState) => {
      return { elogsHidden: !prevState.elogsHidden };
    });
  };

  render() {
    const elogs = Object.entries(this.props.elogs).reverse();
    const elogsHidden = this.state.elogsHidden;

    if (elogs.length < 1) {
      return (
        <div className="elog-repo">
          <Table bordered hover size="sm">
            <thead>
              <tr>
                <th>eLogs</th>
                <th>Date</th>
                <th>Shift</th>
                <th>Job</th>
                <th>Rig</th>
              </tr>
            </thead>
          </Table>
        </div>
      );
    }

    return (
      <div className="elog-repo">
        <Table bordered hover size="sm">
          <thead>
            <tr>
              <th>eLogs</th>
              <th>Date</th>
              <th>Shift</th>
              <th>Job</th>
              <th>Rig</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            {elogs.map(([key, elog], index) => {
              let currentlySelected = false;
              let elogRefCompatible = false;
              let selectClass = "bg-info";
              let currentRefName = "";
              let isHidden = false;

              if (index >= 3 && elogsHidden) {
                isHidden = true;
              }

              if (!elog.header) {
                return <></>;
              }

              if (key == this.props.selectedElog) {
                currentlySelected = true;
              }

              if (this.props.currentRefId) {
                currentRefName =
                  this.props.refFiles[this.props.currentRefId].name;
                if (elog.refFile === currentRefName) {
                  elogRefCompatible = true;
                } else {
                  selectClass = "bg-danger";
                }
              }

              var dateObj = new Date(elog.header?.ShiftDate);
              var utcString = dateObj.toLocaleString("en-AU", {
                year: "numeric",
                month: "short",
                day: "numeric",
              });

              const needsUpdateServer = elog.needsUpdateServer;
              const uploadError = elog.uploadError;
              const isUploading = elog.isUploading;
              const completed = elog.completed;

              let uploadValue = "";

              if (completed) {
                if (isUploading) {
                  uploadValue = "Uploading";
                } else if (uploadError) {
                  uploadValue = "Error";
                } else if (needsUpdateServer) {
                  uploadValue = "Not sent";
                } else {
                  uploadValue = "Completed & Sent";
                }
              } else {
                uploadValue = "Incomplete";
              }

              const elogRow = [
                elog.header.PlodNo,
                utcString,
                elog.header.Shift,
                elog.header.Job,
                elog.header.Rig,
                uploadValue,
              ];

              return (
                <tr
                  key={key}
                  className={`elogItem ${currentlySelected ? selectClass : ""
                    } ${isHidden ? "collapse" : ""} ${elogRefCompatible ? "" : "incompatible"
                    }`}
                  onClick={() => this.onClick(key, elogRefCompatible)}
                >
                  {elogRow.map((val, index) => {
                    return <td key={index}>{val}</td>;
                  })}
                  {this.props.isMobile && (
                    <td
                      className="repo-mobile-expand"
                      onClick={() => {
                        this.props.toggleElogMenu();
                      }}
                    >
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </td>
                  )}
                </tr>
              );
            })}

            {elogs.length > 3 && (
              <tr
                key="showElogsBtn"
                onClick={() => {
                  this.toggleHiddenElogs();
                }}
              >
                <td colSpan="100%">{`${elogsHidden ? "Show more" : "Show less"
                  }`}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onSelectElog: (id) => dispatch(doSelectElog(id)),
    onSelectRef: id => dispatch(doSelectRef(id)),
  };
};

const mapStateToProps = (state) => {
  return {
    elogs: state.elogState.elogs,
    refFiles: state.refFileState.refFiles,
    selectedElog: state.elogState.currentElogId,
    currentRefId: state.refFileState.currentRefId,
    //currentRefName: state.refFileState.refFiles[state.refFileState.currentRefId].name
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ElogRepo);
