import {
  ELOG_NEW,
  ELOG_DELETE,
  ELOGS_FROM_IDB,
  ELOGS_FROM_IDB_SUCCESS,
  ELOG_SELECT,
  ELOG_TO_IDB,
  ELOG_TO_IDB_SUCCESS,
  ELOG_TO_IDB_ERROR,
  ELOG_DELETE_IDB_ERROR,
  ELOG_DELETE_IDB_SUCCESS,
  ELOG_LOAD,
  ELOG_TO_RECIPIENTS,
  SET_ELOG_ANEW,
  LI_MODIFIED,
  NEW_LI,
  CHANGE_CURRENT_LI,
  DELETE_LI,
  HEADER_MODIFIED,
  ELOG_TO_GRAPHQL,
  ELOG_TO_GRAPHQL_SUCCESS,
  ELOG_TO_GRAPHQL_ERROR,
  IMPORT_ELOG_STATE,
  UPDATE_ELOG_HTML,
  CHANGE_FONT_SIZE,
  ELOG_COMPLETE,
  ELOG_TO_GRAPHQL_CANCEL,
} from "../constants/actionTypes";

const doChangeFontSize = (payload) => ({
  type: CHANGE_FONT_SIZE,
  payload,
});

const doChangeCurrentLineItem = (id) => ({
  type: CHANGE_CURRENT_LI,
  id,
});

const doAddNewLineItem = (lineItem) => ({
  type: NEW_LI,
  lineItem,
});

const doCompleteElog = (payload) => ({
  type: ELOG_COMPLETE,
  payload,
});

const doAddNewElog = (refFile) => ({
  type: ELOG_NEW,
  refFile,
});

const doDeleteElog = (id) => ({
  type: ELOG_DELETE,
  id,
});

const doDeleteElogIdbSuccess = () => ({
  type: ELOG_DELETE_IDB_SUCCESS
});

const doDeleteElogIdbError = (error) => ({
  type: ELOG_DELETE_IDB_ERROR,
  error,
});

const doSelectElog = (id) => ({
  type: ELOG_SELECT,
  id,
});

const doGetElogsIdb = () => ({
  type: ELOGS_FROM_IDB,
});

const doGetElogsIdbSuccess = (elogs) => ({
  type: ELOGS_FROM_IDB_SUCCESS,
  elogs,
});

const doModifyLineItem = (payload) => ({
  type: LI_MODIFIED,
  payload,
});

const doDeleteLineItem = () => ({
  type: DELETE_LI,
});

const doModifyHeader = (payload) => ({
  type: HEADER_MODIFIED,
  payload,
});

const doUploadElog = (payload) => ({
  type: ELOG_TO_GRAPHQL,
  payload,
});

const doCancelElogUpload = (elogId) => ({
  type: ELOG_TO_GRAPHQL_CANCEL,
  elogId,
});

const doSendElog = (payload) => ({
  type: ELOG_TO_RECIPIENTS,
  payload,
});

const doUploadElogError = (payload) => ({
  type: ELOG_TO_GRAPHQL_ERROR,
  payload,
});

const doUploadElogSuccess = (id) => ({
  type: ELOG_TO_GRAPHQL_SUCCESS,
  id,
});

const doSetElogAnew = (payload) => ({
  type: SET_ELOG_ANEW,
  payload,
});

const doSaveElogIdb = (payload) => ({
  type: ELOG_TO_IDB,
  payload,
});

const doSaveElogIdbSuccess = (id) => ({
  type: ELOG_TO_IDB_SUCCESS,
  id,
});

const doSaveElogIdbError = (payload) => ({
  type: ELOG_TO_IDB_ERROR,
  payload,
});

const doImportElogState = (payload) => ({
  type: IMPORT_ELOG_STATE,
  payload,
});

const doUpdateElogHtml = (payload) => ({
  type: UPDATE_ELOG_HTML,
  payload,
});

const doLoadElog = (payload) => ({
  type: ELOG_LOAD,
  payload,
});

export {
  doLoadElog,
  doAddNewElog,
  doDeleteElog,
  doGetElogsIdb,
  doGetElogsIdbSuccess,
  doModifyLineItem,
  doChangeCurrentLineItem,
  doSelectElog,
  doAddNewLineItem,
  doDeleteLineItem,
  doModifyHeader,
  doSaveElogIdb,
  doSaveElogIdbSuccess,
  doSaveElogIdbError,
  doDeleteElogIdbSuccess,
  doDeleteElogIdbError,
  doCompleteElog,
  doSendElog,
  doUploadElog,
  doUploadElogError,
  doUploadElogSuccess,
  doImportElogState,
  doUpdateElogHtml,
  doChangeFontSize,
  doCancelElogUpload,
  doSetElogAnew,
};
