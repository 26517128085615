import { takeEvery, all } from "redux-saga/effects";
import {
  REFFILES_FROM_IDB,
  ELOGS_FROM_IDB,
  PRIORENTRIES_FROM_IDB,
  PRIORENTRIES_TO_IDB,
  ELOG_TO_IDB,
  REFFILE_TO_IDB,
  ELOG_DELETE,
  REFFILE_DELETE,
  REFFILES_FROM_GRAPHQL,
  ELOG_TO_GRAPHQL,
  CHANGE_SCREEN,
  UPDATE_TOKEN,
  UPDATE_AUTH,
  REFFILE_COMBINATIONS_FROM_GRAPHQL,
  ELOG_COMPLETE,
  LOAD_ELOG_FILTERS_IDB,
  CHANGE_ADMIN_ELOG_FILTER,
  DELETE_ADMIN_ELOG_FILTER,
  ELOG_TO_RECIPIENTS,
  REFFILE_DELETE_ALL,
  REFFILE_BULK_ADD,
  ELOGS_FROM_IDB_SUCCESS,
  REFFILE_FETCH_SUCCESS,
} from "../constants/actionTypes";
import {
  handleFetchRefFilesIdb,
  handleSaveRefFileIdb,
  handleDeleteRefFile,
  handleFetchRefsGraphql,
  handleFetchRefCombinationsGraphql,
  handleDeleteAllRefFiles,
  handleSaveBulkRefFiles,
  handleRefFileSelectOnElogLoad,
  handleRefFileSelectOnRefSync,
} from "./refFile";
import {
  handleCompleteElog,
  handleDeleteElog,
  handleFetchAllElogs,
  handleSaveSingleElog,
  handleUploadElogGraphql,
  handleSendElog,
} from "./elog";
import { handleFetchPriorEntries, handleSavePriorEntries } from "./priorEntry";
import { handleScreenChangeSave } from "./global";
import { handleSaveAdminFilters, handleFetchAdminFilters } from "./adminPanel";
import { handleLogin, handleAuthUpdate } from "./auth";

function* watchAll() {
  yield all([
    //takeEvery(REFFILE_FETCH, handleFetchRefFileApi),
    takeEvery(REFFILES_FROM_GRAPHQL, handleFetchRefsGraphql),
    takeEvery(REFFILES_FROM_IDB, handleFetchRefFilesIdb),
    takeEvery(REFFILE_TO_IDB, handleSaveRefFileIdb),
    takeEvery(REFFILE_DELETE, handleDeleteRefFile),
    takeEvery(REFFILE_DELETE_ALL, handleDeleteAllRefFiles),
    takeEvery(REFFILE_BULK_ADD, handleSaveBulkRefFiles),
    takeEvery(ELOGS_FROM_IDB, handleFetchAllElogs),
    takeEvery(PRIORENTRIES_FROM_IDB, handleFetchPriorEntries),
    takeEvery(PRIORENTRIES_TO_IDB, handleSavePriorEntries),
    takeEvery(ELOG_TO_IDB, handleSaveSingleElog),
    takeEvery(ELOG_DELETE, handleDeleteElog),
    takeEvery(ELOG_TO_GRAPHQL, handleUploadElogGraphql),
    //takeEvery(SET_ELOG_ANEW, handleUploadElogGraphql),
    takeEvery(CHANGE_SCREEN, handleScreenChangeSave),
    takeEvery(UPDATE_TOKEN, handleLogin),
    takeEvery(UPDATE_AUTH, handleAuthUpdate),
    takeEvery(
      REFFILE_COMBINATIONS_FROM_GRAPHQL,
      handleFetchRefCombinationsGraphql
    ),
    takeEvery(ELOG_COMPLETE, handleCompleteElog),
    takeEvery(CHANGE_ADMIN_ELOG_FILTER, handleSaveAdminFilters),
    takeEvery(DELETE_ADMIN_ELOG_FILTER, handleSaveAdminFilters),
    takeEvery(LOAD_ELOG_FILTERS_IDB, handleFetchAdminFilters),
    takeEvery(ELOG_TO_RECIPIENTS, handleSendElog),

    takeEvery(ELOGS_FROM_IDB_SUCCESS, handleRefFileSelectOnElogLoad),
    takeEvery(REFFILE_FETCH_SUCCESS, handleRefFileSelectOnRefSync)
  ]);
}

export default watchAll;
