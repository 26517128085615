import { PlodDb } from "../services/DBService";

const fetchPriorEntriesFromIdb = () =>
  PlodDb.getAll("prior_entries") //, 'priorEntries')
    .then((priorEntryVals) =>
      PlodDb.getAllKeys("prior_entries").then((priorEntryKeys) =>
        priorEntryVals.reduce(function (result, field, index) {
          result[priorEntryKeys[index]] = field;
          return result;
        }, {})
      )
    );

const savePriorEntriesToIdb = (priorEntries) => {
  Object.entries(priorEntries).map(([key, val]) => {
    PlodDb.put("prior_entries", val, key);
  });
  //PlodDb.put('prior_entries', priorEntries, 'priorEntries')
};

const savePriorEntryToIdb = (key, val) => {
  PlodDb.put("prior_entries", val, key);
};

export { fetchPriorEntriesFromIdb, savePriorEntriesToIdb, savePriorEntryToIdb };
