import {
  REFFILE_FETCH,
  REFFILE_LOAD,
  REFFILE_FETCH_ERROR,
  REFFILE_FETCH_SUCCESS,
  REFFILES_FROM_IDB,
  REFFILES_FROM_IDB_SUCCESS,
  REFFILES_FROM_IDB_ERROR,
  REFFILE_COMBINATIONS_FROM_GRAPHQL,
  REFFILE_COMBINATIONS_FROM_GRAPHQL_SUCCESS,
  REFFILE_COMBINATIONS_FROM_GRAPHQL_ERROR,
  REFFILES_FROM_GRAPHQL,
  REFFILE_TO_IDB,
  REFFILE_TO_IDB_SUCCESS,
  REFFILE_TO_IDB_ERROR,
  REFFILE_SELECT,
  REFFILE_DELETE,
  REFFILE_DELETE_IDB_ERROR,
  REFFILE_DELETE_IDB_SUCCESS,
  CHANGE_PAGE,
  CHANGE_PRODUCT,
  IMPORT_REF_STATE,
  REFFILE_DELETE_ALL,
  REFFILE_DELETE_ALL_IDB_ERROR,
  REFFILE_DELETE_ALL_IDB_SUCCESS,
  REFFILE_BULK_ADD,
} from "../constants/actionTypes";

const doSaveRefFile = (payload) => ({
  type: REFFILE_TO_IDB,
  payload,
});

const doSaveRefFileSuccess = () => ({
  type: REFFILE_TO_IDB_SUCCESS,
});

const doSaveRefFileError = (error) => ({
  type: REFFILE_TO_IDB_ERROR,
  error,
});

const doLoadRefFile = (payload) => ({
  type: REFFILE_LOAD,
  payload,
});

const doAddBulkRefFiles = (payload) => ({
  type: REFFILE_BULK_ADD,
  payload,
});

const doFetchRefFile = () => {
  return {
    type: REFFILE_FETCH,
  };
};

const doFetchRefsGraphql = (payload) => ({
  type: REFFILES_FROM_GRAPHQL,
  token: payload.token,
  role: payload.role,
  newId: payload.newId,
  shiftDetails: payload.shiftDetails,
});

const doFetchRefCombinationsGraphql = (payload) => ({
  type: REFFILE_COMBINATIONS_FROM_GRAPHQL,
  token: payload.token,
  role: payload.role,
});

const doFetchRefCombinationsSuccess = (payload) => ({
  type: REFFILE_COMBINATIONS_FROM_GRAPHQL_SUCCESS,
  payload,
});

const doFetchRefCombinationsError = (payload) => ({
  type: REFFILE_COMBINATIONS_FROM_GRAPHQL_ERROR,
  payload,
});

const doFetchRefFileSuccess = () => {
  return {
    type: REFFILE_FETCH_SUCCESS,
  };
};

const doFetchErrorRefFile = (error) => ({
  type: REFFILE_FETCH_ERROR,
  error,
});

const doGetRefFilesIdb = () => ({
  type: REFFILES_FROM_IDB,
});

const doGetRefFilesIdbSuccess = (refFiles) => ({
  type: REFFILES_FROM_IDB_SUCCESS,
  refFiles,
});

const doGetRefFilesIdbError = (error) => ({
  type: REFFILES_FROM_IDB_ERROR,
  error,
});

const doSelectRef = (id) => ({
  type: REFFILE_SELECT,
  id,
});

const doDeleteRef = (id) => ({
  type: REFFILE_DELETE,
  id,
});

const doDeleteAllRefs = () => ({
  type: REFFILE_DELETE_ALL,
});

const doDeleteRefFileSuccess = () => ({
  type: REFFILE_DELETE_IDB_SUCCESS,
});

const doDeleteRefFileError = (error) => ({
  type: REFFILE_DELETE_IDB_ERROR,
  error,
});

const doDeleteAllRefFilesSuccess = () => ({
  type: REFFILE_DELETE_ALL_IDB_SUCCESS,
});

const doDeleteAllRefFilesError = (error) => ({
  type: REFFILE_DELETE_ALL_IDB_ERROR,
  error,
});

const doChangePage = (id) => ({
  type: CHANGE_PAGE,
  id,
});

const doChangeProduct = (id) => ({
  type: CHANGE_PRODUCT,
  id,
});

const doImportRefState = (payload) => ({
  type: IMPORT_REF_STATE,
  payload,
});

export {
  doLoadRefFile,
  doFetchRefFile,
  doFetchRefFileSuccess,
  doFetchErrorRefFile,
  doSaveRefFile,
  doSaveRefFileSuccess,
  doSaveRefFileError,
  doGetRefFilesIdb,
  doGetRefFilesIdbSuccess,
  doGetRefFilesIdbError,
  doSelectRef,
  doDeleteRef,
  doDeleteAllRefs,
  doChangePage,
  doChangeProduct,
  doDeleteRefFileError,
  doDeleteRefFileSuccess,
  doImportRefState,
  doFetchRefsGraphql,
  doFetchRefCombinationsGraphql,
  doFetchRefCombinationsError,
  doFetchRefCombinationsSuccess,
  doDeleteAllRefFilesError,
  doDeleteAllRefFilesSuccess,
  doAddBulkRefFiles
};
