/*

TODO:
  PropTypes for all components
  Library for accessing INdexedDB
  IndexedDB explorer for ref files and eLogs
  Fix props and state for each component - state is data managed by component. Props are passed from parent
  Add online/offline indicator
  Close IndexedDB after use
  New devices need ObjectStores created on first visit
  Use IndexedDB keys
  Page consts for handling field map pages
  For adding line item to elog we deleted elog and readded because put wasn't working. remedy this.
  Make sure dates are right time zone
  Form updating after product change not working
  Use date library for graph work
  Redraw product field after size is retrieved
  Combine headerpage and productfields
  App.js move render code to componentdidmount
  Move bottom functions to Utilities
  Generalise autocomplete
  eval in SPRs needs to be replaced
  ProdXField assumes that page numbers are consecutive. Uses them as indexes. 0-7.
  shiftSummary column totals should be in the state.
  Field Validation
  //test
*/

//error fix
//https://github.com/facebook/create-react-app/issues/4165

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import React from "react";
import "./App.css";

import * as serviceWorker from "./serviceWorkerRegistration";

import { connect } from "react-redux";
import {
  doGetRefFilesIdb,
  doSelectRef,
  doDeleteRef,
  doChangePage,
  doChangeProduct,
  doLoadRefFile,
  doSaveRefFile,
  doFetchRefsGraphql,
} from "./actions/refFile";
import {
  doDeleteElog,
  doAddNewElog,
  doGetElogsIdb,
  doChangeCurrentLineItem,
  doSelectElog,
  doAddNewLineItem,
  doDeleteLineItem,
  doSaveElogIdb,
  doUploadElog,
  doLoadElog,
  doChangeFontSize,
  doSetElogAnew,
} from "./actions/elog";
import {
  doBatchUpdatePriorEntry,
  doGetPriorEntriesIdb,
  doSavePriorEntriesIdb,
} from "./actions/priorEntry";
import {
  doAuthRedirect,
  doChangeScreen,
  doCloseLoginPrompt,
  doShowRecipientPrompt,
  doShowShiftInfoPrompt,
} from "./actions/global";

import ProductEntry from "./components/ProductEntry";

import RefFileRepo from "./components/RefFileRepo";
import ElogRepo from "./components/ElogRepo";
import HeaderPage from "./components/HeaderPage";
import ShiftSummary from "./components/ShiftSummary";

import TitleBar from "./components/TitleBar";
import HeaderBanner from "./components/HeaderBanner";
import ErrorBoundary from "./components/ErrorBoundary";
import RefFileViewer from "./components/RefFileViewer";

import ShiftPrompt from "./components/ShiftPrompt";

import { PlodDb } from "./services/DBService";

import {
  Container,
  Row,
  Button,
  ButtonGroup,
  Alert,
  Modal,
} from "react-bootstrap";

import {
  getNextElogId,
  getNextRefId,
  getNextLineitemId,
  generateElogForExport,
  generateElogForImport,
  logErrorToServer,
  isDev,
  generateRefFileForExport,
  decodeJwt,
  getCompanyNameFromJwt,
} from "./services/Utilities";

import {
  DEBUG,
  NEW_ELOG,
  VIEW_ELOG,
  EDIT_HEADER,
  EDIT_LINE_ITEM,
  HOME,
  NEW_LINE_ITEM,
  ADMIN_PANEL,
} from "./constants/screenTypes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import AdminPanel from "./components/AdminPanel";
import { doUpdateAuth, doUpdateToken } from "./actions/auth";
import { doLoadFiltersFromIdb } from "./actions/adminPanel";

import { withAuth0 } from "@auth0/auth0-react";

import RecipientPrompt from "./components/RecipientPrompt";
import Confirmation from "./components/Confirmation";
import MobileMenu from "./components/MobileMenu";

class App extends React.Component {
  constructor(props) {
    super(props);

    document.title = "PlodCo UDE";

    this.state = {
      selectedRef: null,
      selectedElog: null,
      refFiles: {},
      eLogs: {},
      selectedProduct: null,
      productDescription: null,
      currentPage: null,
      currentLineItemId: null,
      priorEntries: {},

      serviceWorkerInitialized: false,
      serviceWorkerRegistration: null,

      elogMenuExpanded: false,
      refMenuExpanded: false,
      homeMenuExpanded: false,

      lastAdminPanelRefresh: null,
      adminElogs: [],
    };
  }

  componentDidMount() {
    serviceWorker.register({
      onSuccess: () => this.setState({ serviceWorkerInitialized: true }),
      onUpdate: (reg) => this.setState({ serviceWorkerRegistration: reg }),
      onInit: (reg) => this.setState({ serviceWorkerRegistration: reg }),
    });

    //this.updateAuth();

    try {
      this.props.auth0.getAccessTokenSilently().then((token) => {
        this.props.onUpdateAuthToken(token);
      });
    } catch (e) {
      console.log(e)
    }

    this.checkDesktop();

    this.props.onFetchAllElogs();

    this.props.onFetchAllRefFiles();

    this.props.onFetchPriorEntries();

    this.props.onFetchAdminElogFilters();

    window.addEventListener("resize", this.checkDesktop);
  }

  updateServiceWorker = () => {
    //updates UDE
    const registrationWaiting = this.state.serviceWorkerRegistration?.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });

      registrationWaiting.addEventListener("statechange", (e) => {
        if (e.target.state === "activated") {
          window.location.reload(); //hmm
        }
      });
    }
  };

  setAdminElogs(elogs) {
    const elogArrClone = JSON.parse(JSON.stringify(elogs));
    this.setState({ adminElogs: elogArrClone });
  }

  fakeLogin = () => {
    Object.values(this.props.elogs).forEach((elog) => {
      if (elog.needsUpdateServer) {
        //create elog and get id
        this.props.onUploadElog({ elog });
      }
    });

    //Get previous errors from IDB and attempt upload
    PlodDb.getAll("errors").then((errors) => {
      errors.forEach((error) => {
        const id = error.id;
        const objString = error.errorObj;
        const obj = JSON.parse(objString);

        logErrorToServer(id, obj.error, obj.info, obj.moreInfo, obj.reduxState);
      });
    });
  };

  // onServiceWorkerUpdate = registration => {
  //   this.setState({
  //       waitingWorker: registration && registration.waiting,
  //       newVersionAvailable: true
  //   })
  // }

  // updateServiceWorker = () => {
  //   const { waitingWorker } = this.state
  //   if (waitingWorker) {
  //     waitingWorker.addEventListener("statechange", event => {
  //       if (event.target.state === "activated") {
  //         this.setState({ newVersionAvailable: false })
  //         window.location.reload()
  //       }
  //     });
  //     waitingWorker.postMessage({ type: 'SKIP_WAITING' })
  //   }
  // }

  updateAuth = () => {
    //alert('UPDATE AUTH')
    const auth = Object.assign({}, this.props.auth0);
    this.props.onUpdateAuth(auth);

    // if (auth.isAuthenticated) {
    //   auth.getAccessTokenSilently().then((token) => {
    //     console.log("WOOOOOOOFY")
    //     console.log(Date.now())
    //     this.props.onUpdateAuthToken(token);
    //   });
    // }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.auth0) !== JSON.stringify(this.props.auth0)) {
      this.updateAuth();
    }
  }

  deleteRefFile = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmation
            title="Are you sure?"
            caption={
              <>
                Do you want to <b>delete</b> this Ref File?
              </>
            }
            affirmativeText="Yes, delete it!"
            declineText="No"
            onClose={onClose}
            onClick={() => {
              const key = parseInt(this.props.selectedRef);
              this.props.onDeleteRefFile(key);
              onClose();
            }}
          />
        );
      },
    });
  };

  deleteSelectedElog = () => {
    let elogs = this.props.elogs;
    const selectedElog = this.props.selectedElog;

    let header = elogs[selectedElog].header;
    let elogDate = new Date(header.ShiftDate).toLocaleString("en-AU", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmation
            title="Are you sure?"
            caption={
              <>
                Do you want to <b>delete</b> eLog {header.PlodNo} {elogDate}{" "}
                {header.Shift} Shift?
              </>
            }
            affirmativeText="Yes, delete it!"
            declineText="No"
            onClose={onClose}
            onClick={() => {
              const key = parseInt(selectedElog);
              this.props.onDeleteElog(key);
              onClose();
            }}
          />
        );
      },
    });
  };

  setLastAdminRefresh(datetime) {
    this.setState({ lastAdminPanelRefresh: datetime });
  }

  changeCurrentRef = (refFileId) => {
    //save existing prior entry defaults.
    //reinit prior entry defaults refreshPriorEntries()
    //this.refreshPriorEntries()
    this.props.onSelectRef(refFileId);
    //this.setState({ selectedRef: refFileId });
  };

  viewDebug = () => {
    this.props.onChangeScreen(DEBUG);
  };

  changeProductPage = (page, product) => {
    this.props.onChangePage(page);
    this.props.onChangeProduct(product);
  };

  downloadRefFiles = () => {
    const nextRefId = getNextRefId(this.props.refFiles);

    if (
      this.props?.authInfo?.isAuthenticated &&
      this.props.authInfo.user &&
      this.props.screen === HOME
    ) {
      //check screen so sync doesn't happen if currently working on a ref, else it'll get deleted while working on elog
      const userRole =
        this.props.authInfo.user["https://hasura.io/jwt/claims/roles"][0];
      this.props.onFetchRefs({
        token: this.props.authToken,
        role: userRole,
        newId: nextRefId,
      });
    }
  };

  markElogAsComplete = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmation
            title="Are you sure?"
            caption={
              <>Are you sure this elog is correct and ready to be submitted?</>
            }
            affirmativeText="Yes"
            declineText="No"
            onClose={onClose}
            onClick={() => {
              const elogId = this.props.selectedElog;
              if (!elogId) {
                return;
              }
              this.props.onShowRecipientPrompt();
              onClose();
            }}
          />
        );
      },
    });
  };

  // uploadElog = (anew, justCompleted) => {
  //   //anew specifies whether it exists on server

  //   const elogs = this.props.elogs;
  //   const elogId = this.props.selectedElog;

  //   if (!elogId) {
  //     return;
  //   }
  //   const elog = elogs[elogId];

  //   const auth = Object.assign({}, this.props.auth0);

  //   if (auth.isAuthenticated) {
  //     auth.getAccessTokenSilently().then((token) => {

  //       if (elog.needsUpdateServer || anew || justCompleted) {
  //         if (auth.user) {
  //           const userRole = auth.user["https://hasura.io/jwt/claims/roles"][0];
  //           //create elog and get id
  //           this.props.onUploadElog({ elog, anew });
  //         }
  //       }
  //     });
  //   }
  // };

  newElogClick = (refFile) => {
    this.props.onChangeScreen(NEW_ELOG);
    this.props.onNewElog(refFile);
    this.props.onChangePage(0);
  };

  viewElog = () => {
    const selectedRef = this.props.selectedRef;
    const refFiles = this.props.refFiles;
    const selectedElog = this.props.selectedElog;
    const elogs = this.props.elogs;

    const openElog = () => {
      document.title = elogs[selectedElog].header.PlodNo;
      this.props.onChangeScreen(VIEW_ELOG);//hmm
      //this.props.onChangePage(null)
    };

    try {
      openElog();
    } catch (e) {
      logErrorToServer(-1, e, {}, "viewElog catch", {
        currentElog: elogs[selectedElog],
        currentRef: refFiles[selectedRef],
      });
    }
  };

  updateHeader = (entry) => {
    let elogs = this.state.eLogs;
    let elogId = this.state.selectedElog;

    elogs[elogId].header = entry;

    this.setState({ eLogs: elogs });
  };

  pushAllRefFilesToDB = () => {
    const refFiles = Object.values(this.props.refFiles);

    refFiles.map((refFile) => {
      this.props.onSaveRefFile(refFile);
    });
  };

  submitHeader = () => {
    this.props.onChangeScreen(NEW_LINE_ITEM);
  };

  newLineItem = () => {
    const screen = this.props.screen;

    const elogs = Object.assign({}, this.props.elogs);
    const currentElogId = this.props.selectedElog.valueOf();

    const newLineItemId = getNextLineitemId(elogs[currentElogId].lineItems); //elogs[currentElogId].lineItems.length

    if (
      !this.props.selectedProduct &&
      (screen == NEW_LINE_ITEM || screen == EDIT_LINE_ITEM)
    ) {
      return;
    }

    this.props.onChangeScreen(NEW_LINE_ITEM);
    this.props.onChangePage(null);
    this.props.onChangeCurrLineItem(newLineItemId);
    this.props.onChangeProduct(null);
  };

  cancelLineItem = () => {
    const elogs = this.props.elogs;
    const currentElogId = this.props.selectedElog;
    const newLineItemId = getNextLineitemId(elogs[currentElogId].lineItems);

    //delete elogs[currentElogId].lineItems[currentLineItemId]
    this.props.onDeleteLineItem();

    this.props.onChangeScreen(NEW_LINE_ITEM);
    this.props.onChangeCurrLineItem(newLineItemId);
    this.props.onChangePage(null);
    this.props.onChangeProduct(null);
  };

  getLineItem(id) {
    const elogs = this.props.elogs;
    const currentElogId = this.props.selectedElog;
    //const index = this.getIndexById(elogs[currentElogId].lineItems, id)
    //return elogs[currentElogId].lineItems[index]
    //return elogs[currentElogId].lineItems.filter(li => li.id == id)[0]
    return [elogs[currentElogId][id]];
  }

  editLineItem = (id) => {
    if (this.props.screen !== VIEW_ELOG) {
      return;
    }
    this.props.onChangeCurrLineItem(id);

    this.props.onChangeScreen(EDIT_LINE_ITEM);
    const lineItem = this.getLineItem(id);
    this.props.onChangePage(lineItem.page);
    this.props.onChangeProduct(lineItem.product);
  };

  editHeader = () => {
    if (this.props.screen !== VIEW_ELOG) {
      return;
    }
    this.props.onChangeScreen(EDIT_HEADER);
    this.props.onChangePage(0);
  };

  getStateFromIdb() {
    this.props.onFetchPriorEntries();
  }

  navigateHome = () => {
    document.title = "PlodCo UDE";

    this.props.onChangeScreen(HOME);
    this.props.onChangePage(null);
  };

  handleHeaderButtonEvent = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
      this.submitHeader();
    }
  };

  deleteLineItem = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmation
            title="Are you sure?"
            caption={
              <>
                Are you sure you want to <b>delete</b> this line item?
              </>
            }
            affirmativeText="Yes, delete it!"
            declineText="No"
            onClose={onClose}
            onClick={() => {
              this.props.onDeleteLineItem();
              this.viewElog();
              onClose();
            }}
          />
        );
      },
    });
  };

  getElogExportDLLink = () => {
    //export elog
    const currentElog = this.props.elogs[this.props.selectedElog];

    const elogDownloadData = generateElogForExport(currentElog, "local");

    let elogDownloadUrl = "";
    if (elogDownloadData) {
      elogDownloadUrl = window.URL.createObjectURL(elogDownloadData);
    }
    return elogDownloadUrl;
  };

  //Export ref file
  getRefExportDLLink = () => {
    const currentRef = this.props.refFiles[this.props.selectedRef].refFile;

    const refDownloadData = generateRefFileForExport(currentRef);

    let refDownloadUrl = "";
    if (refDownloadData) {
      refDownloadUrl = window.URL.createObjectURL(refDownloadData);
    }
    return refDownloadUrl;
  };

  clearData = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Confirmation
            title="Are you sure?"
            caption={
              <>
                Are you sure you want to <b>delete</b> all UDE data?
              </>
            }
            affirmativeText="Yes, delete it!"
            declineText="No"
            onClose={onClose}
            onClick={() => {
              Promise.all([
                PlodDb.deleteAll("elogs"),
                PlodDb.deleteAll("errors"),
                PlodDb.deleteAll("ref_files"),
                PlodDb.deleteAll("prior_entires"),
              ]).then(window.location.reload());
              onClose();
            }}
          />
        );
      },
    });
  };

  onRefFileChange = (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    const lastModified = file.lastModified;

    reader.onload = (event) => {
      try {
        const refString = event.target.result;
        const refObject = JSON.parse(refString);
        const newId = getNextRefId(this.props.refFiles);

        const payload = {
          refFile: refObject,
          downloaded: lastModified,
          id: newId,
          name: file.name,
          localUpload: true,
        };

        this.props.onLoadRef(payload);
        this.props.onSaveRefFile(payload);
      } catch (e) {
        console.log(e);
      }
    };

    reader.readAsText(file);
  };

  importElog = (elogObject) => {
    const newId = getNextElogId(this.props.elogs);

    const refFiles = Object.assign({}, this.props.refFiles);
    const selectedRef = this.props.selectedRef;

    if (
      Object.keys(refFiles).length < 1 ||
      !selectedRef ||
      Object.keys(refFiles[selectedRef]).length < 1
    ) {
      alert("You must have the correct ref file selected to import elog.");
      return;
    }

    const elogPayload = generateElogForImport(
      elogObject,
      refFiles[selectedRef].refFile,
      newId,
      refFiles[selectedRef].name
    );

    if (elogPayload) {
      this.props.onSaveElog({ id: newId, elog: elogPayload });
      this.props.onLoadElog(elogPayload);
      this.props.onChangeScreen(VIEW_ELOG);
    } else {
      alert("Error importing elog");
    }
  };

  onElogFileChange = (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();

    reader.onload = (event) => {
      const elogString = event.target.result;
      const elogObject = JSON.parse(elogString);

      this.importElog(elogObject);
    };

    reader.readAsText(file);
  };

  checkDesktop = () => {
    let isMobile =
      window.matchMedia("screen and (max-width: 1200px)").matches ||
      window.screen.width < 1200;
    let isPhone =
      window.matchMedia("screen and (max-width: 600px)").matches ||
      window.screen.width < 600;

    this.setState({ isPhone });
    this.setState({ isDesktop: !isMobile });
  };

  onLogin(loginWithRedirect) {
    this.props.onAuthRedirect();
    loginWithRedirect();
  }

  onLogout(logout, args) {
    this.props.onAuthRedirect();
    logout(args);
  }

  changeElogFontSize(direction) {
    const selectedElog = this.props.selectedElog;
    const elogs = this.props.elogs;
    const currentElog = elogs[selectedElog];
    const currentFontSize = currentElog.fontSize;
    let fontSize = currentFontSize;

    if (direction < 0) {
      fontSize = fontSize - 1;
    } else {
      fontSize = fontSize + 1;
    }

    if (fontSize < 1) {
      fontSize = 1;
    }

    if (currentElog)
      this.props.onChangeFontSize({ id: currentElog.id, fontSize: fontSize });
  }

  expandHomeMenu = () => {
    this.setState({ homeMenuExpanded: true });
  };

  expandRefMenu = () => {
    this.setState({ refMenuExpanded: true });
  };

  expandElogMenu = () => {
    this.setState({ elogMenuExpanded: true });
  };

  closeElogMenu = () => {
    this.setState({ elogMenuExpanded: false });
  };

  closeRefMenu = () => {
    this.setState({ refMenuExpanded: false });
  };

  closeHomeMenu = () => {
    this.setState({ homeMenuExpanded: false });
  };

  closeAllMenus = () => {
    this.closeElogMenu();
    this.closeRefMenu();
    this.closeHomeMenu();
  };

  render() {
    const screen = this.props.screen;
    const selectedRef = this.props.selectedRef;
    const refFiles = this.props.refFiles;
    const selectedElog = this.props.selectedElog;
    const currentPage = this.props.currentPage;
    const currentLineItemId = this.props.selectedLineItemId;
    const { isDesktop, isPhone, serviceWorkerRegistration } = this.state;
    const eLogs = Object.assign({}, this.props.elogs);

    try {
      let currentElog = eLogs[selectedElog];
      let currentRef = refFiles[selectedRef];

      let selectedLineItem = null;

      if (currentElog) {
        //const index = this.getIndexById(currentElog.lineItems, currentLineItemId)
        selectedLineItem = currentElog.lineItems[currentLineItemId];
      }

      let editing = null;
      let isEditing = false;

      if (screen == EDIT_HEADER) {
        editing = currentElog.header;
      }

      if (screen == EDIT_LINE_ITEM) {
        editing = selectedLineItem;
        isEditing = true;
      }

      //pref for whether to display activity Analysis or not
      //const activityAnalysis = getActivityAnalysisFlag(pref)

      let newHeaderScreen = <></>;
      let newLineItemScreen = <></>;

      // isLoading(pin):true => false
      // isAuthenticated(pin):true

      if (Object.keys(refFiles).length > 0 && selectedRef) {
        newHeaderScreen = (
          <HeaderPage
            currentPage={currentPage}
            //fieldMap={headerPage}
            onSubmission={() => {
              this.newLineItem();
            }}
            onFormUpdate={(header) => {
              this.updateHeader(header);
            }}
            existingValues={editing}
          />
        );

        newLineItemScreen = (
          <ProductEntry
            currentRef={refFiles[selectedRef].refFile}
            pageChange={(page, product) => {
              this.changeProductPage(page, product);
            }}
            newLineItemEvent={this.newLineItem}
            screen={screen}
            isEditing={isEditing}
            currentLineItemId={currentLineItemId}
          />
        );

        if (isDesktop) {
          newHeaderScreen = (
            <div className="split-screen">
              <HeaderPage
                //key={eLogs}
                currentPage={currentPage}
                //fieldMap={headerPage}
                onSubmission={() => {
                  this.newLineItem();
                }}
                onFormUpdate={(header) => {
                  this.updateHeader(header);
                }}
                //listX={listX}
                //priorEntries={priorEntries}
                existingValues={editing}
              />
              <div className="preview-window">
                <ShiftSummary
                  //currentElog={eLogs[selectedElog]}
                  //fieldMap={fMap}
                  //prodMap={prodMap}
                  editLineItem={(id) => {
                    this.editLineItem(id);
                  }}
                  editHeader={this.editHeader}
                  hideAnalysis={true}
                //onLogin={() => this.onLogin(loginWithRedirect)}
                //activityAnalysis={activityAnalysis}
                />
              </div>
            </div>
          );

          newLineItemScreen = (
            <div className="split-screen">
              <ProductEntry
                currentRef={refFiles[selectedRef].refFile}
                pageChange={(page, product) => {
                  this.changeProductPage(page, product);
                }}
                newLineItemEvent={this.newLineItem}
                screen={screen}
                isEditing={isEditing}
                currentLineItemId={currentLineItemId}
              />
              <ShiftSummary
                //currentElog={eLogs[selectedElog]}
                editLineItem={(id) => {
                  this.editLineItem(id);
                }}
                editHeader={this.editHeader}
                hideAnalysis={true}
              //onLogin={() => this.onLogin(loginWithRedirect)}
              />
            </div>
          );
        }
      }

      const adminMenuVisible =
        (this.props?.authInfo?.isAuthenticated &&
          this.props.authInfo.user &&
          this.props.authToken &&
          this.props.authInfo.user["https://hasura.io/jwt/claims/roles"][0] ===
          "manager") ||
        isDev();

      const loginWithRedirect = this.props.auth0.loginWithRedirect;
      const logout = this.props.auth0.logout;

      const isAuthenticated = this.props.auth0.isAuthenticated;
      const isLoading = this.props.auth0.isLoading;

      let loginButton = null;

      let indicator = <p style={{ color: "#ff4646" }}>OFFLINE</p>;

      if (isAuthenticated) {
        const jwt = decodeJwt(this.props.authToken);
        const companyName = getCompanyNameFromJwt(jwt);

        indicator = (
          <p style={{ color: "#21ff21", lineHeight: 1 }}>
            {this.props.authInfo?.user?.email}
            {companyName ? (
              <>
                <br /> ({companyName})
              </>
            ) : (
              <></>
            )}
          </p>
        );
      }

      if (this.props.isLoading) {
        indicator = <FontAwesomeIcon icon={faSpinner} spin />;
      }

      if (isLoading) {
        loginButton = <Button variant="info">Logging in...</Button>;
      } else if (isAuthenticated) {
        loginButton = (
          <Button
            variant="info"
            onClick={() => {
              this.onLogout(logout, { returnTo: window.location.origin });
            }}
            disabled={isLoading}
          >
            Logout
          </Button>
        );
      } else {
        loginButton = (
          <Button
            variant="info"
            onClick={() => {
              this.onLogin(loginWithRedirect);
            }}
            disabled={isLoading}
          >
            Login
          </Button>
        );
      }

      if (
        this.props.redirecting ||
        // this.props.fetchingRefFiles ||
        this.props.isLoading
      ) {
        let action = <h2>Attempting login</h2>;
        // if (this.props.fetchingRefFiles) {
        //   action = <h2>Fetching ref files</h2>;
        // }
        return (
          <div className="redirect-spinner">
            <div className="loader center">
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
            {action}
          </div>
        );
      }

      return (
        <ErrorBoundary>
          <div className="App">
            <Container fluid>
              <Row>
                <div
                  className="page"
                  style={
                    isPhone ? { marginTop: "70px" } : { marginTop: "170px" }
                  }
                >
                  {!isLoading &&
                    !isAuthenticated && ( // && is online
                      <>
                        <Modal
                          animation={false}
                          show={this.props.showLoginPrompt}
                          onHide={this.props.onCloseLoginPrompt}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>You&apos;re not logged in</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            To upload elogs and get download ref files you must
                            be logged in.
                          </Modal.Body>
                          <Modal.Footer>
                            {loginButton}
                            <Button
                              variant="info"
                              onClick={this.props.onCloseLoginPrompt}
                            >
                              Cancel
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </>
                    )}

                  {this.props.showShiftPrompt &&
                    <ShiftPrompt submitShiftDetails={(refDetails) => {
                      if (refDetails) {
                        this.changeCurrentRef(refDetails.id);
                        const ref = this.props.refFiles[refDetails.id];
                        this.newElogClick(ref);
                      } else {
                        alert("Error selecting ref file.")
                      }
                    }} />
                  }

                  <RecipientPrompt />

                  {serviceWorkerRegistration?.waiting && (
                    <Alert
                      dismissable
                      key="update-available"
                      variant="warning"
                      id="update-banner"
                    >
                      New update available.{" "}
                      <Alert.Link
                        href="#"
                        onClick={() => this.updateServiceWorker()}
                      >
                        Press to update.
                      </Alert.Link>
                    </Alert>
                  )}

                  {((this.props?.authInfo?.isAuthenticated &&
                    this.props.authInfo.user &&
                    this.props.authToken &&
                    this.props.authInfo.user[
                    "https://hasura.io/jwt/claims/roles"
                    ][0] === "manager") ||
                    isDev()) &&
                    screen === ADMIN_PANEL && (
                      <AdminPanel
                        setLastRefresh={(datetime) => {
                          this.setLastAdminRefresh(datetime);
                        }}
                        lastRefresh={this.state.lastAdminPanelRefresh}
                        setAdminElogs={(elogs) => {
                          this.setAdminElogs(elogs);
                        }}
                        adminElogs={this.state.adminElogs}
                      />
                    )}

                  {screen === HOME && (
                    <React.Fragment>
                      {isPhone && (
                        <MobileMenu
                          newElog={() => this.props.onShowShiftInfoPrompt()}
                          importElog={() => {
                            document.getElementById("upload-elog").click();
                          }}
                          exportElog={() => {
                            document.getElementById("download-elog").click();
                          }}
                          //login={}
                          debug={this.viewDebug}
                          admin={() => {
                            this.props.onChangeScreen(ADMIN_PANEL);
                          }}
                          importRef={() => {
                            document.getElementById("upload-ref").click();
                          }}
                          exportRef={() => {
                            document.getElementById("download-ref").click();
                          }}
                          syncRefs={() => this.downloadRefFiles()}
                          selectedRef={selectedRef}
                          selectedElog={selectedElog}
                          elogCompleted={currentElog?.completed}
                          markElogAsComplete={() => this.markElogAsComplete()}
                          deleteSelectedElog={() => this.deleteSelectedElog()}
                          resend={() => this.props.onShowRecipientPrompt()}
                          adminMenuVisible={adminMenuVisible}
                          isAuthenticated={
                            this.props?.authInfo?.isAuthenticated
                          }
                          viewElog={() => {
                            this.viewElog();
                          }}
                          deleteRefFile={() => this.deleteRefFile()}
                          loginButton={loginButton}
                          statusIndicator={indicator}
                          homeMenuExpanded={this.state.homeMenuExpanded}
                          elogMenuExpanded={this.state.elogMenuExpanded}
                          refMenuExpanded={this.state.refMenuExpanded}
                          closeAllMenus={() => {
                            this.closeAllMenus();
                          }}
                        />
                      )}

                      {/* <pre>{JSON.stringify(eLogs[selectedElog])}</pre> */}

                      <div className="elog-repo">
                        <ElogRepo
                          isMobile={isPhone}
                          toggleElogMenu={() => {
                            this.closeAllMenus();
                            this.expandElogMenu();
                          }}
                        />
                      </div>

                      <div className="ref-repo">
                        <RefFileRepo
                          isMobile={isPhone}
                          toggleRefMenu={() => {
                            this.closeAllMenus();
                            this.expandRefMenu();
                          }}
                          //key={refCount}//(refFiles || selectedRef)}
                          //refFiles={refFiles}
                          onRefSelect={this.changeCurrentRef}
                        //currentRef={selectedRef}
                        //prefMap={pref}
                        />
                      </div>

                      {!isPhone && (
                        <div className="home-buttons">
                          <ButtonGroup aria-label="Elogs">
                            <Button
                              variant="info"
                              onClick={() => this.props.onShowShiftInfoPrompt()}
                              disabled={Object.keys(refFiles).length <= 0 || this.props.fetchingRefFiles || isLoading}
                            >
                              New eLog
                            </Button>
                            <Button
                              variant="info"
                              onClick={this.viewElog}
                              disabled={
                                !selectedElog
                              } /*disabled={!(selectedElog && selectedRef) || incompatibleElogRef}*/
                            >
                              View/Edit
                            </Button>
                            {!currentElog?.completed && (
                              <Button
                                key="complete-elog"
                                variant="info"
                                disabled={!selectedElog}
                                onClick={() => this.markElogAsComplete()}
                              >
                                Complete
                              </Button>
                            )}

                            {currentElog?.completed && (
                              <Button
                                key="resend-elog"
                                variant="info"
                                disabled={!selectedElog}
                                onClick={() => {
                                  if (this.props.selectedElog) {
                                    this.props.onShowRecipientPrompt();
                                  }
                                }}
                              >
                                Resend
                              </Button>
                            )}

                            <Button
                              variant="info"
                              id="upload-elog-anew"
                              onClick={() => {
                                if (this.props.selectedElog) {
                                  const elogPayload =
                                    this.props.elogs[this.props.selectedElog];
                                  if (elogPayload) {
                                    this.props.onSetElogAnew(elogPayload);
                                  } else {
                                    alert("Error uploading elog anew");
                                  }
                                }
                              }}
                              disabled={
                                !(
                                  this.props?.authInfo?.isAuthenticated &&
                                  this.props.authInfo.user &&
                                  this.props.authToken
                                ) || !selectedElog
                              }
                            >
                              Send Anew
                            </Button>

                            <Button
                              variant="info"
                              onClick={this.deleteSelectedElog}
                              disabled={!selectedElog}
                            >
                              Delete eLog
                            </Button>
                          </ButtonGroup>

                          <ButtonGroup aria-label="Elogs-input-output">
                            <Button
                              variant="info"
                              id="upload-ref-button"
                              onClick={() => {
                                document.getElementById("upload-elog").click();
                              }}
                            >
                              Import eLog
                            </Button>
                            <Button
                              variant="info"
                              id="download-elog-button"
                              onClick={() => {
                                document
                                  .getElementById("download-elog")
                                  .click();
                              }}
                              disabled={!selectedElog}
                            >
                              Export eLog
                            </Button>
                          </ButtonGroup>

                          <ButtonGroup aria-label="Login and Info">
                            {loginButton}
                            <Button variant="info" onClick={this.viewDebug}>
                              Debug
                            </Button>
                            {((this.props?.authInfo?.isAuthenticated &&
                              this.props.authInfo.user &&
                              this.props.authToken &&
                              this.props.authInfo.user[
                              "https://hasura.io/jwt/claims/roles"
                              ][0] === "manager") ||
                              isDev()) && (
                                <Button
                                  variant="info"
                                  onClick={() =>
                                    this.props.onChangeScreen(ADMIN_PANEL)
                                  }
                                >
                                  Admin
                                </Button>
                              )}
                          </ButtonGroup>

                          {isDev() && (
                            <>
                              <Button variant="danger" onClick={this.fakeLogin}>
                                FAKE LOGIN
                              </Button>
                            </>
                          )}

                          <ButtonGroup aria-label="Ref Files">
                            <Button
                              variant="info"
                              onClick={/*this.props.onShowShiftInfoPrompt*/() => { this.downloadRefFiles() }}
                              disabled={!this.props?.authInfo?.isAuthenticated}
                            >
                              Sync Refs
                            </Button>
                            <Button
                              variant="info"
                              id="upload-ref-button"
                              onClick={() => {
                                document.getElementById("upload-ref").click();
                              }}
                            >
                              Import Ref
                            </Button>
                            <Button
                              variant="info"
                              onClick={this.deleteRefFile}
                              disabled={!selectedRef}
                            >
                              Delete Ref
                            </Button>
                            <Button
                              variant="info"
                              id="download-ref-button"
                              onClick={() => {
                                document.getElementById("download-ref").click();
                              }}
                              disabled={!selectedRef}
                            >
                              Export RefFile
                            </Button>
                            {/* <Button variant='info' onClick={this.props.onShowShiftInfoPrompt}>Custom Sync</Button> */}
                          </ButtonGroup>

                          {/* <Button variant='info' onClick={this.uploadElog} disabled={!this.props?.authInfo?.isAuthenticated}>Upload Elog</Button> */}
                          {/* <Button variant='info' onClick={() => { generateElogHtml() }} disabled={!selectedElog}>Send eLog</Button> */}
                        </div>
                      )}

                      <div className="ref-upload">
                        <input
                          type="file"
                          accept=".REF"
                          id="upload-ref"
                          name="upload-ref"
                          onChange={this.onRefFileChange}
                        />
                      </div>

                      <div className="elog-upload">
                        <input
                          type="file"
                          accept=".ELOG"
                          id="upload-elog"
                          name="upload-elog"
                          onChange={this.onElogFileChange}
                        />
                      </div>

                      {currentElog && (
                        <div className="elog-download">
                          <a
                            id="download-elog"
                            name="download-elog"
                            href={this.getElogExportDLLink()}
                            download={currentElog.header.PlodNo + ".ELOG"}
                          />
                        </div>
                      )}

                      {currentRef && (
                        <div className="ref-download">
                          <a
                            id="download-ref"
                            name="download-ref"
                            href={this.getRefExportDLLink()}
                            download={currentRef.name}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  )}

                  {(screen === NEW_ELOG || screen === EDIT_HEADER) && (
                    <>{newHeaderScreen}</>
                  )}

                  {screen === VIEW_ELOG && (
                    <React.Fragment>
                      <ShiftSummary
                        editLineItem={(id) => {
                          this.editLineItem(id);
                        }}
                        editHeader={this.editHeader}
                        onLogin={() => this.onLogin(loginWithRedirect)}
                      //activityAnalysis={activityAnalysis}
                      />
                    </React.Fragment>
                  )}

                  {(screen === NEW_LINE_ITEM || screen === EDIT_LINE_ITEM) && (
                    <>{newLineItemScreen}</>
                  )}

                  {screen === DEBUG && <RefFileViewer />}
                </div>
              </Row>
            </Container>

            <Container fluid className="fixed-header">
              {!isPhone && (
                <Row className="header-banner-container">
                  <HeaderBanner
                    currentElog={currentElog}
                    indicator={indicator}
                  />
                </Row>
              )}

              <Row className="title-bar-container">
                <TitleBar
                  //key={screen || currentPage}
                  isDesktop={!isPhone}
                  screen={screen}
                  productPage={currentPage}
                  homeButtonPress={this.navigateHome}
                  newLineItemPress={this.newLineItem}
                  keyPressOnHeaderSubmit={this.handleHeaderButtonEvent}
                  finishedEditing={this.viewElog}
                  deleteLineItem={this.deleteLineItem}
                  cancelLineItem={this.cancelLineItem}
                  changeFontSize={(direction) => {
                    this.changeElogFontSize(direction);
                  }}
                  setElogAsComplete={() => this.markElogAsComplete()}
                  resendElog={() => {
                    if (this.props.selectedElog) {
                      this.props.onShowRecipientPrompt();
                    }
                  }}
                  clearData={this.clearData}
                  homeMenuExpanded={this.state.homeMenuExpanded}
                  toggleMobileMenu={() => {
                    if (this.state.homeMenuExpanded) {
                      this.closeAllMenus();
                    } else {
                      this.closeAllMenus();
                      this.expandHomeMenu();
                    }
                  }}
                />
              </Row>
            </Container>
          </div>
        </ErrorBoundary>
      );
    } catch (e) {
      logErrorToServer(-1, e, {}, "App.js: render()", {});
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    //onFetchRefFile: payload => dispatch(doFetchRefFile(payload)),
    onFetchAllRefFiles: () => dispatch(doGetRefFilesIdb()),
    onDeleteRefFile: (id) => dispatch(doDeleteRef(id)),
    onNewElog: (refFile) => dispatch(doAddNewElog(refFile)),
    onFetchAllElogs: () => dispatch(doGetElogsIdb()),
    onDeleteElog: (id) => dispatch(doDeleteElog(id)),
    onChangeCurrLineItem: (id) => dispatch(doChangeCurrentLineItem(id)),
    onSelectElog: (id) => dispatch(doSelectElog(id)),
    onNewLineItem: (lineItem) => dispatch(doAddNewLineItem(lineItem)),
    onChangeScreen: (screen) => dispatch(doChangeScreen(screen)),
    onSelectRef: (id) => dispatch(doSelectRef(id)),
    onDeleteLineItem: () => dispatch(doDeleteLineItem()),
    onChangePage: (id) => dispatch(doChangePage(id)),
    onChangeProduct: (id) => dispatch(doChangeProduct(id)),
    onUpdatePriorEntries: (fieldValues) =>
      dispatch(doBatchUpdatePriorEntry(fieldValues)),
    onSavePriorEntries: (priorEntries) =>
      dispatch(doSavePriorEntriesIdb(priorEntries)),
    onFetchPriorEntries: () => dispatch(doGetPriorEntriesIdb()),
    onSaveElog: (payload) => dispatch(doSaveElogIdb(payload)),
    onLoadRef: (payload) => dispatch(doLoadRefFile(payload)),
    onLoadElog: (payload) => dispatch(doLoadElog(payload)),
    onSaveRefFile: (payload) => dispatch(doSaveRefFile(payload)),
    //onCompleteElog: (payload) => dispatch(doCompleteElog(payload)),

    onFetchRefs: (payload) => dispatch(doFetchRefsGraphql(payload)),
    onUploadElog: (payload) => dispatch(doUploadElog(payload)),

    onUpdateAuth: (payload) => dispatch(doUpdateAuth(payload)),
    onUpdateAuthToken: (token) => dispatch(doUpdateToken(token)),
    onAuthRedirect: () => dispatch(doAuthRedirect()),

    onChangeFontSize: (payload) => dispatch(doChangeFontSize(payload)),

    onCloseLoginPrompt: () => dispatch(doCloseLoginPrompt()),
    onShowShiftInfoPrompt: () => dispatch(doShowShiftInfoPrompt()),

    onFetchAdminElogFilters: () => dispatch(doLoadFiltersFromIdb()),

    onShowRecipientPrompt: () => dispatch(doShowRecipientPrompt()),

    onSetElogAnew: (payload) => dispatch(doSetElogAnew(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    elogs: state.elogState.elogs,
    refFiles: state.refFileState.refFiles,
    selectedRef: state.refFileState.currentRefId,
    selectedElog: state.elogState.currentElogId,
    selectedLineItemId: state.elogState.currentLineItemId,
    screen: state.globalState.screen,
    currentPage: state.refFileState.currentPage,
    selectedProduct: state.refFileState.currentProduct,
    priorEntries: state.priorEntryState.priorEntries,
    fetchingRefFiles: state.refFileState.fetchingRefFiles,

    authInfo: state.authState.auth,
    authToken: state.authState.token,

    redirecting: state.globalState.redirecting,
    showLoginPrompt: state.globalState.showLoginPrompt,
    showShiftPrompt: state.globalState.showShiftInfoPrompt,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(App));
