import {
  CHANGE_SCREEN,
  UPLOAD_ERROR,
  AUTH_REDIRECT,
  SHOW_LOGIN_PROMPT,
  CLOSE_LOGIN_PROMPT,
  SHOW_SHIFT_INFO_PROMPT,
  CLOSE_SHIFT_INFO_PROMPT,
  CLOSE_RECIPIENT_PROMPT,
  SHOW_RECIPIENT_PROMPT,
} from "../constants/actionTypes";

const doChangeScreen = (id) => ({
  type: CHANGE_SCREEN,
  id,
});

const doUploadError = (payload) => ({
  type: UPLOAD_ERROR,
  payload,
});

const doAuthRedirect = () => ({
  type: AUTH_REDIRECT,
});

const doShowLoginPrompt = () => ({
  type: SHOW_LOGIN_PROMPT,
});

const doCloseLoginPrompt = () => ({
  type: CLOSE_LOGIN_PROMPT,
});

const doCloseShiftInfoPrompt = () => ({
  type: CLOSE_SHIFT_INFO_PROMPT,
});

const doShowShiftInfoPrompt = () => ({
  type: SHOW_SHIFT_INFO_PROMPT,
});

const doShowRecipientPrompt = (payload) => ({
  type: SHOW_RECIPIENT_PROMPT,
  payload,
});

const doCloseRecipientPrompt = () => ({
  type: CLOSE_RECIPIENT_PROMPT,
});

export {
  doChangeScreen,
  doUploadError,
  doAuthRedirect,
  doShowLoginPrompt,
  doCloseLoginPrompt,
  doShowShiftInfoPrompt,
  doCloseShiftInfoPrompt,
  doShowRecipientPrompt,
  doCloseRecipientPrompt,
};
