import React from "react";
import { connect } from "react-redux";

import { isDev } from "../services/Utilities";

import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faPlus,
  faPrint,
  faCheck,
  faTrashAlt,
  faTimes,
  faFileExport,
  faFileImport,
  faTextHeight,
  faBars
} from "@fortawesome/free-solid-svg-icons";
import {
  EDIT_HEADER,
  EDIT_LINE_ITEM,
  HOME,
  NEW_LINE_ITEM,
  VIEW_ELOG,
  NEW_ELOG,
  DEBUG,
  ADMIN_PANEL,
} from "../constants/screenTypes";
import { doChangeScreen } from "../actions/global";

class TitleBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      screenName: "",
      buttons: [],
    };
  }

  onButtonKeyDown = (e) => {
    if (e.keyCode === 9) {
      e.preventDefault();
      if (this.props.screen === EDIT_LINE_ITEM) {
        this.props.finishedEditing();
      } else if (this.props.screen === EDIT_HEADER) {
        this.props.finishedEditing();
      } else {
        this.props.newLineItemPress();
      }
    }
  };

  getExportDLLink = () => {
    //export elog
    const currentState = this.props.appState;

    const downloadData = new Blob(
      [
        JSON.stringify(currentState, function (k, v) {
          return v === undefined ? "" : v;
        }),
      ],
      { type: "text/json" }
    );

    let downloadUrl = "";
    if (downloadData) {
      downloadUrl = window.URL.createObjectURL(downloadData);
    }
    return downloadUrl;
  };

  componentDidMount() { }

  toggleMobileMenu = () => {
    this.props.toggleMobileMenu();
  };

  render() {
    const homeMenuExpanded = this.props.homeMenuExpanded;

    const screenId = this.props.screen;
    const isDesktop = this.props.isDesktop;
    //const productPage = this.props.productPage;

    const homeButton = (
      <Button key="home" variant="info" onClick={this.props.homeButtonPress}>
        <span className="d-none d-lg-inline-block button-text">Home</span>
        <FontAwesomeIcon icon={faHome} />
      </Button>
    );
    //const screenName = screenId

    let titleBarButtons = [];
    const elogs = this.props.elogs;
    const elogId = this.props.elogId;
    let elog = null;

    if (elogs && elogId) {
      elog = elogs[elogId];
    }

    let elogFontSize = elog?.fontSize;

    if (screenId === VIEW_ELOG) {
      let fontSize = "";

      if (elogFontSize) {
        fontSize = elogFontSize;
      }

      titleBarButtons.push(
        <div className="font-size-widget btn">
          <Button
            key="font-size-down"
            variant="info"
            onClick={() => {
              this.props.changeFontSize(-1);
            }}
          >
            -
          </Button>
          <span>
            <FontAwesomeIcon icon={faTextHeight} />
            {Math.round(fontSize)}
          </span>
          <Button
            key="font-size-up"
            variant="info"
            onClick={() => {
              this.props.changeFontSize(1);
            }}
          >
            +
          </Button>
        </div>
      );

      titleBarButtons.push(
        <Button
          key="view-elog-new-line-item"
          variant="info"
          /*disabled={elogCompleted}*/ onClick={this.props.newLineItemPress}
        >
          <span className="d-none d-md-inline-block button-text">
            New Line-Item
          </span>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      );

      titleBarButtons.push(
        <Button key="print" variant="info" onClick={() => window.print()}>
          <span className="d-none d-md-inline-block button-text">Print</span>
          <FontAwesomeIcon icon={faPrint} />
        </Button>
      );

      if (elog?.completed) {
        titleBarButtons.push(
          <Button
            key="complete"
            variant="info"
            /*disabled={elogCompleted}*/ onClick={() => this.props.resendElog()}
          >
            <span className="d-none d-md-inline-block button-text">Resend</span>
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        );
      } else {
        titleBarButtons.push(
          <Button
            key="complete"
            variant="info"
            /*disabled={elogCompleted}*/ onClick={() =>
              this.props.setElogAsComplete()
            }
          >
            <span className="d-none d-md-inline-block button-text">
              Complete
            </span>
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        );
      }

      {
        ((this.props?.authInfo?.isAuthenticated &&
          this.props.authInfo.user &&
          this.props.authToken &&
          this.props.authInfo.user["https://hasura.io/jwt/claims/roles"][0] ===
          "manager") ||
          isDev()) &&
          titleBarButtons.push(
            <Button
              variant="info"
              onClick={() => this.props.onChangeScreen(ADMIN_PANEL)}
            >
              Admin
            </Button>
          );
      }
    }

    if (screenId === NEW_ELOG) {
      titleBarButtons.push(
        <Button
          key="header-new-line-item"
          variant="info"
          onClick={this.props.newLineItemPress}
          onKeyDown={this.onButtonKeyDown}
        >
          <span className="d-none d-md-inline-block button-text">
            New Line-Item
          </span>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      );
    }

    if (screenId === EDIT_HEADER) {
      titleBarButtons.push(
        <Button
          key="edit-line-item"
          variant="info"
          onClick={this.props.finishedEditing}
          onKeyDown={this.onButtonKeyDown}
        >
          <span className="d-none d-md-inline-block button-text">Done</span>
          <FontAwesomeIcon icon={faCheck} />
        </Button>
      );
    }

    if (screenId === NEW_LINE_ITEM) {
      titleBarButtons.push(
        <Button
          key="btn-new-line-item"
          variant="info"
          onClick={this.props.newLineItemPress}
          onKeyDown={this.onButtonKeyDown}
        >
          <span className="d-none d-md-inline-block button-text">
            New Line-Item
          </span>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      );

      titleBarButtons.push(
        <Button
          key="btn-cancel-line-item"
          variant="info"
          onClick={this.props.cancelLineItem}
        >
          <span className="d-none d-md-inline-block button-text">Cancel</span>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      );

      titleBarButtons.push(
        <Button
          key="view-edit"
          variant="info"
          onClick={this.props.finishedEditing}
        >
          <span className="d-none d-md-inline-block button-text">
            View/Edit
          </span>
          <FontAwesomeIcon icon={faCheck} />
        </Button>
      );
    }

    if (screenId === EDIT_LINE_ITEM) {
      titleBarButtons.push(
        <Button
          key="edit-line-item"
          variant="info"
          onClick={this.props.finishedEditing}
          onKeyDown={this.onButtonKeyDown}
        >
          <span className="d-none d-md-inline-block button-text">Done</span>
          <FontAwesomeIcon icon={faCheck} />
        </Button>
      );

      titleBarButtons.push(
        <Button
          key="delete-line-item"
          variant="info"
          onClick={this.props.deleteLineItem}
        >
          <span className="d-none d-md-inline-block button-text">Delete</span>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Button>
      );
    }

    if (screenId === DEBUG) {
      titleBarButtons.push(
        <Button
          key="export-app-state"
          href={this.getExportDLLink()}
          download={"ude_data.dat"}
          variant="info"
        >
          <span className="d-none d-md-inline-block button-text">
            Export Data
          </span>
          <FontAwesomeIcon icon={faFileExport} />
        </Button>
      );

      titleBarButtons.push(
        <Button key="import-app-state" id="upload-state-button" variant="info">
          <span
            className="d-none d-md-inline-block button-text"
            onClick={() => {
              document.getElementById("upload-state").click();
            }}
          >
            Import Data
          </span>
          <FontAwesomeIcon icon={faFileImport} />
        </Button>
      );

      titleBarButtons.push(
        <Button
          key="clean-app-state"
          id="clean-state-button"
          variant="danger"
          onClick={() => {
            this.props.clearData();
          }}
        >
          <span className="d-none d-md-inline-block button-text">Clear</span>
          <FontAwesomeIcon icon={faTimes} />
        </Button>
      );
    }

    if (screenId === ADMIN_PANEL) {
      titleBarButtons.push(
        <Button key="print" variant="info" onClick={() => window.print()}>
          <span className="d-none d-md-inline-block button-text">Print</span>
          <FontAwesomeIcon icon={faPrint} />
        </Button>
      );
    }

    if (screenId !== HOME) {
      titleBarButtons.push(homeButton);
    }

    if (!screenId) {
      return <></>;
    }

    return (
      <>
        <div className={`title-bar${!isDesktop ? " mobile" : ""}`}>
          {isDesktop && (
            <>
              <h4>{screenId}</h4>
              <div className="title-bar-buttons">
                {titleBarButtons.map((button) => {
                  return button;
                })}
              </div>
            </>
          )}

          {!isDesktop && (
            <>
              {screenId !== HOME &&
                titleBarButtons.map((button) => {
                  return button;
                })}

              {screenId === HOME && (
                <>
                  <div
                    className="mobile-hamburger"
                    onClick={this.toggleMobileMenu}
                  >
                    {!homeMenuExpanded && <FontAwesomeIcon icon={faBars} />}

                    {homeMenuExpanded && <FontAwesomeIcon icon={faTimes} />}
                  </div>
                  <h4>UDE</h4>
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onChangeScreen: (screen) => dispatch(doChangeScreen(screen)),
  };
};

const mapStateToProps = (state) => {
  return {
    appState: state,
    elogs: state.elogState.elogs,
    elogId: state.elogState.currentElogId,
    authInfo: state.authState.auth,
    authToken: state.authState.token,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TitleBar);
