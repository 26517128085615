import {
  CHANGE_ADMIN_ELOG_FILTER,
  DELETE_ADMIN_ELOG_FILTER,
  LOAD_ELOG_FILTERS_IDB_SUCCESS,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  activeFilters: {},
};

const applyChangeFilter = (state, action) => {
  let clone = Object.assign({}, state.activeFilters);
  const key = action.payload.key;
  const value = action.payload.val;
  clone[key] = value;

  return {
    ...state,
    activeFilters: clone,
  };
};

const applyDeleteFilter = (state, action) => {
  let clone = Object.assign({}, state.activeFilters);
  delete clone[action.filterKey];

  return {
    ...state,
    activeFilters: clone,
  };
};

const applyLoadFiltersIdbSuccess = (state, action) => ({
  ...state,
  activeFilters: action.activeFilters,
});

function adminPanelReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHANGE_ADMIN_ELOG_FILTER: {
      return applyChangeFilter(state, action);
    }

    case DELETE_ADMIN_ELOG_FILTER: {
      return applyDeleteFilter(state, action);
    }

    case LOAD_ELOG_FILTERS_IDB_SUCCESS: {
      return applyLoadFiltersIdbSuccess(state, action);
    }

    default:
      return state;
  }
}

export default adminPanelReducer;
