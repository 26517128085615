import React from "react";
import PropTypes from "prop-types";

class Autocomplete extends React.Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array),
  };

  //firstInput = {}

  constructor(props) {
    super(props);

    this.firstInput = React.createRef();

    let filteredSuggestions = [];

    if (this.props.showSuggestions === true) {
      filteredSuggestions = this.props.suggestions;
    }

    let defaultValue = "";

    if (this.props.default) {
      defaultValue = this.props.default;
    }

    let activeSuggestion = filteredSuggestions.indexOf(defaultValue);

    //not found
    if (activeSuggestion < 0) {
      activeSuggestion = 0;
    }

    this.state = {
      // The active selection's index
      activeSuggestion: activeSuggestion,
      // The suggestions that match the user's input
      filteredSuggestions: filteredSuggestions,
      // Whether or not the suggestion list is shown
      showSuggestions: this.props.showSuggestions && !this.props.value,
      // What the user has entered
      userInput: defaultValue,
      isActive: false,
    };
  }

  componentDidMount() {
    if (this.props.label == "Product") {
      this.firstInput.current.focus();
      this.firstInput.current.scrollIntoView();
    }

    //alert(this.props.value)

    let activeSuggestion = this.props.suggestions.indexOf(this.props.value);

    if (activeSuggestion < 0) {
      activeSuggestion = 0;
    }

    let filteredSuggestions = [];

    if (this.props.showSuggestions == true) {
      filteredSuggestions = this.props.suggestions;
    }

    this.setState({
      // The active selection's index
      activeSuggestion: activeSuggestion,
      // The suggestions that match the user's input
      filteredSuggestions: filteredSuggestions,
      // Whether or not the suggestion list is shown
      showSuggestions: this.props.showSuggestions && !this.props.value,
      // What the user has entered
      //userInput: defaultValue,
      fieldLabel: this.props.label,
      isActive: false,
    });
  }

  // Event fired when the input value is changed
  onChange = (e) => {
    const { suggestions, label } = this.props;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter((suggestion) =>
      String(suggestion.toLowerCase()).startsWith(userInput.toLowerCase())
    );

    let visibleInput = userInput;

    if (this.props.allCaps) {
      visibleInput = visibleInput.toUpperCase();
    }

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: visibleInput,
      fieldLabel: label,
    });
  };

  // Event fired when the user clicks on a suggestion
  onClick = (e) => {
    //this.props.incrementFocus()
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
      itemClicked: true,
    });

    //console.log(e.currentTarget.innerText)
    //  var finalSelection = e.currentTarget.innerText;

    // if (this.props.onSelection) {
    //   this.props.onSelection(finalSelection);
    // }
  };

  onBlur = () => {
    const {
      activeSuggestion,
      filteredSuggestions,
      userInput,
      itemClicked,
    } = this.state;

    // if (showSuggestions) {
    if (this.props.onSelection) {
      if (itemClicked) {
        this.props.onSelection(userInput);
      } else {
        if (filteredSuggestions[activeSuggestion]) {
          this.props.onSelection(filteredSuggestions[activeSuggestion]);
        }
      }
      //this.props.onSelection(userInput);
    }
    // }

    this.setState({
      isActive: false,
      userInput: filteredSuggestions[activeSuggestion],
      showSuggestions: false,
      itemClicked: false,
    });
  };

  onFocus = () => {
    this.setState({ isActive: true });

    this.setState({ showSuggestions: true });
  };

  // Event fired when the user presses a key down
  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions, showSuggestions } =
      this.state;

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13 || e.keyCode === 9) {
      //if (e.keyCode !== 9) {

      //if (this.props.label == 'Product' || this.props.label == 'Employee:' || this.props.label == 'UoM:' || this.props.label == 'Role:') {
      //if (this.props.label != 'Product') {
      e.preventDefault();
      //}
      // }
      // else {
      //   //e.preventDefault()
      // }
      //}
      if (showSuggestions) {
        if (this.props.onSelection) {
          const filtered = filteredSuggestions[activeSuggestion];
          const input = this.state.userInput;

          if (filtered) {
            this.props.onSelection(filtered);
          } else {
            if (this.props.suggestions.includes(input)) {
              this.props.onSelection(input);
            } else {
              this.props.onSelection(filteredSuggestions[activeSuggestion]);
            }
          }
        }
      }

      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
      });
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      e.preventDefault();
      if (activeSuggestion == 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      e.preventDefault();
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      activeSuggestion,
      filteredSuggestions,
      showSuggestions,
      userInput
    } = this.state;

    let suggestionsListComponent;

    //document.activeElement.name == this.props.fieldName &&
    if ((showSuggestions && userInput) || (this.props.showSuggestions && this.state.isActive == true)) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index == activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li
                  className={className}
                  key={suggestion}
                  onMouseDown={this.onClick}
                >
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        if (!this.props.suggestions.includes(userInput)) {
          suggestionsListComponent = (
            <div className="no-suggestions">
              <em>{"No matching " + this.props.label.toLowerCase() + "."}</em>
            </div>
          );
        }
      }
    }

    return (
      //Product field needs to grab size from reffile
      <div className="field-object form-group">
        <label htmlFor={this.props.fieldName}>{this.props.label}</label>
        <input
          className="form-control input-lg"
          type="text"
          onChange={this.onChange}
          onKeyDown={(e) => {
            this.onKeyDown(e);
          }}
          onBlur={(e) => {
            this.onBlur(e);
          }}
          onFocus={(e) => {
            this.onFocus(e);
          }}
          value={userInput} //|| this.props.value || ''}
          id={this.props.fieldName}
          name={this.props.fieldName}
          maxLength={this.props.maxLength}
          size={Math.round(this.props.maxLength * 0.5)}
          ref={this.firstInput}
          tabIndex="0"
          autoComplete="none"
        />
        {suggestionsListComponent}
      </div>
    );
  }
}

export default Autocomplete;
