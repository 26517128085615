import { renderToStaticMarkup } from "react-dom/server";
import React from "react";
import ElogPdfHtml from "../components/static/ElogPdfHtml";
import { PlodDb } from "./DBService";
import store from "../store";

export function convertDateTimeToUnix(dateString) {
  const datetime = dateString.split(", ");
  const date = datetime[0];
  const time = datetime[1];

  const daymonthyear = date.split("/");
  const day = parseInt(daymonthyear[0]);
  const month = parseInt(daymonthyear[1]) - 1; //for month index
  const year = parseInt(daymonthyear[2]);

  const hoursminutesseconds = time.split(":");
  const hours = parseInt(hoursminutesseconds[0]);
  const minutes = parseInt(hoursminutesseconds[1]);
  const seconds = parseInt(hoursminutesseconds[2]);

  const constructedDate = new Date(year, month, day, hours, minutes, seconds);
  const timestamp = Date.parse(constructedDate);
  return timestamp;
}

export function getPageCount(fieldMap) {
  const pages = fieldMap.filter((field) => field.FieldName === "PAGE");
  return pages.length;
}

export function getPageNameFromPageId(pageId, fieldMap) {
  if (!pageId) {
    return "Unknown";
  }

  const field = fieldMap.find((field) => {
    if (field.PageNo == pageId && Object.prototype.hasOwnProperty.call(field, "Caption")) {
      return true;
    }
    return false;
  });

  if (field) {
    return field.Caption;
  }

  return "Unknown";
}

export function getScreenName(screenId) {
  switch (screenId) {
    case "home":
      return "Home";
    case "header":
      return "New eLog";
    case "view-elog":
      return "View/Edit eLog";
    case "new-line-item":
      return "New Line Item";
    case "debug":
      return "Debug";
    case "edit-header":
      return "Edit Header";
    case "edit-line-item":
      return "Edit Line Item";
    default:
      return "UNKNOWN PAGE";
  }
}

export function filterFieldMap(pageId, fieldMap) {
  if (!fieldMap) {
    return;
  }
  var filtered = fieldMap.filter(
    (field) => field.PageNo === pageId && field.FieldName !== "PAGE"
  );
  return filtered;
}

export function getPriorEntryFields(pageId, fieldMap) {
  var filtered = fieldMap.filter(
    (field) =>
      field.PageNo == pageId &&
      field.FieldName !== "PAGE" &&
      field.DefaultType &&
      (field.DefaultType.toUpperCase() === "P" ||
        field.DefaultType.toUpperCase() === "PRIORENTRY")
  );
  filtered = filtered.map((field) => field.FieldName);
  return filtered;
}

export function getSprFields(pageId, fieldMap) {
  var filtered = fieldMap.filter(
    (field) =>
      field.PageNo == pageId && field.FieldName !== "PAGE" && field.SpecProc
  );
  return filtered;
}

export function getFieldCaption(pageId, fieldName, fieldMap) {
  var filtered = fieldMap.filter(
    (field) =>
      field.PageNo == pageId &&
      field.FieldName !== "PAGE" &&
      field.FieldName == fieldName
  );
  return filtered[0].Caption;
}

export function getUom(prodMap, currentProduct) {
  var filtered = prodMap.filter((prod) => prod.ProdCode === currentProduct);
  return filtered[0].UoM;
}

export function getNextElogId(elogs) {
  const elogKeys = Object.keys(elogs); //eLogs.map(elog => elog.id)//
  if (elogKeys.length < 1) {
    return "1";
  }

  const largestId = Math.max(...elogKeys);
  const newKey = largestId + 1;

  return newKey.toString();
}

export function getPageFromProduct(productMap, productName) {
  const filtered = productMap.find((field) => {
    return field.ProdCode == productName;
  });

  return filtered?.PageType;
}

export function getRateFromProductCode(productMap, productCode) {
  const product = productMap.find((field) => field.ProdCode === productCode);
  if (product && Object.prototype.hasOwnProperty.call(product, "Rate")) {
    return product.Rate;
  }
  return;
}

export function getProductDescription(productMap, productName) {
  var filtered = productMap.find((field) => field.ProdCode == productName);
  if (!filtered) {
    return "unknown";
  }
  return filtered.ProdDesc;
}

export function getLargestElogId(elogs) {
  const elogKeys = Object.keys(elogs); //eLogs.map(elog => elog.id)//
  if (elogKeys.length < 1) {
    return;
  }

  const largestId = Math.max(...elogKeys);
  return largestId;
}

export function getNextLineitemId(lineitems) {
  const itemKeys = Object.keys(lineitems); //eLogs.map(elog => elog.id)//
  if (itemKeys.length < 1) {
    return "1";
  }

  const largestId = Math.max(...itemKeys);
  const newKey = largestId + 1;

  return newKey.toString();
}

export function generateElogForImport(elog, refFileObj, newId, refName) {
  //spread operator doesn't clone nested object. Hence the below mess
  const elogObject = JSON.parse(JSON.stringify(elog));

  let lineItems = {};
  let header = elogObject.shift();
  const createDate = header.created;
  const created = convertDateTimeToUnix(createDate);
  delete header.created;
  header.ShiftDate = Date.parse(header.ShiftDate);

  let errorExists = false;
  let erroredLineItems = [];

  elogObject.forEach((lineItem, index) => {
    const indexAsString = (index + 1).toString();
    let pageId = null;

    if (!lineItem.page) {
      try {
        const prodObj = refFileObj?.PRODfile?.find(
          (prod) => prod.ProdCode === lineItem.Product
        );
        pageId = prodObj?.PageType;
      } catch (e) {
        console.log(e);
        errorExists = true;
      }
    } else {
      pageId = lineItem.page.toString();
    }

    if (pageId) {
      let lineItemObj = {
        id: indexAsString,
        page: pageId,
        product: lineItem.Product,
        description: lineItem.ProdDesc,
        fieldValues: lineItem,
      };
      delete lineItemObj.fieldValues.page;
      lineItems[indexAsString - erroredLineItems.length] = lineItemObj;
    } else {
      erroredLineItems.push(lineItem.Product);
    }
  });

  if (errorExists) {
    return null;
  }

  if (erroredLineItems.length > 0) {
    alert(`The following line items weren't found in the current ref file (${erroredLineItems.join(",")}), so editing isn't possible. This is a temporary measure to prevent deletions. If the elog requires editing, please contact administrator.`);
    return;
    // if (
    //   window.confirm(
    //     `The following line items weren't found in current ref file, so will be excluded from the import: ${erroredLineItems.join(
    //       ","
    //     )}. Do you want to continue?`
    //   )
    // ) {
    //   console.log("Proceeding...")
    // } else {
    //   return;
    // }
  }

  return {
    header: header,
    created: created,
    lineItems: lineItems,
    id: newId,
    refFile: refName,
    existsOnServer: true,
  };
}

export function generateElogForExport(selectedElog, endpoint) {
  if (!selectedElog) {
    return;
  }

  let elogData = [];
  let elogHeader = {};
  let lineItems = {};

  Object.assign(elogHeader, selectedElog.header);
  Object.assign(lineItems, selectedElog.lineItems);

  var shiftDate = new Date(elogHeader.ShiftDate);
  var shiftDateString = shiftDate.toLocaleString("en-AU", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  var elogDatetime = new Date(selectedElog.created);
  var elogDatetimeString = elogDatetime.toLocaleString();

  elogHeader.created = elogDatetimeString;
  elogHeader.ShiftDate = shiftDateString;

  elogData.push(elogHeader);

  Object.values(lineItems).forEach((lineItem) => {
    //lineItem.fieldValues.page = lineItem.page
    elogData.push(lineItem.fieldValues);
  });

  const elogString = JSON.stringify(elogData, function (k, v) {
    return v === undefined ? "" : v;
  });

  if (endpoint === "local") {
    return new Blob([elogString], { type: "text/json" });
  } else {
    return JSON.parse(elogString);
    //return JSON.stringify(elogData, function(k, v) { return v === undefined ? "" : v; });
  }
}

//From SO. Adds escape character to string.
export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

/* Usage
  Will be useful for searching custom ref files where reffilename job/rig/shift isn't applicable.
  let object = { 'a': [{ 'b': { 'c': 3 } }] };
  getValueAtObjectPath(object, 'a[0].b["c"]');
*/
export function getValueAtObjectPath(object, path) {
  const regex = /(?<=\[)(?!")[^\]]+|(?<=\[")[^"]+|[^."[\]]+/g;
  return path.match(regex).reduce((res, prop) => res[prop], object);
}

export function getUniqueFieldNamesFromFieldMap(fieldMap) {
  if (!fieldMap) {
    return;
  }

  let headings = ["PAGE", "Product", "ProdDesc"];

  fieldMap.forEach((field) => {
    const fieldName = field.FieldName;
    if (fieldName && headings.indexOf(fieldName) === -1) {
      headings.push(fieldName);
    }
  });

  headings = [...headings];

  return headings;
}

export function generateCsvDataString(headingArr, lineItemsArr) {
  let csvDataString = headingArr.join(",") + "\n";

  lineItemsArr.forEach((lineItem) => {
    headingArr.forEach((heading) => {
      csvDataString += `"`;

      let cellVal = "";

      if (Object.prototype.hasOwnProperty.call(lineItem, heading)) {
        cellVal = lineItem[heading];
      }

      csvDataString += cellVal.toString().replace('"', "");
      csvDataString += `"`;
      csvDataString += ",";
    });

    csvDataString += "\n";
  });

  return csvDataString;
}

export function findRefFileByRig(refFiles, rig) {
  if (!rig) {
    return;
  }

  const rigMatch = refFiles.find((refFile) => {
    const refFileFieldmap = refFile.ref_file_data.FMAPfile;
    const rigField = refFileFieldmap.find(
      (field) => field.FieldName.toUpperCase() === "RIG"
    );
    const refRig = rigField?.Supplied;

    if (rig.toLowerCase() === refRig.toLowerCase()) {
      return true;
    }

    return false;
  });

  return rigMatch;
}

export function findRefFileByName(refFiles, refFileName) {
  if (!refFileName) {
    return;
  }

  const nameMatch = refFiles.find((refFile) => {
    if (refFile.ref_file_name.toLowerCase() === refFileName.toLowerCase()) {
      return true;
    }

    return false;
  });

  return nameMatch;
}

// find the best ref file ID for a given elog. 
export function getRefFileIdForElog(elogData, refFileName, localRefFiles) {
  const localRefs = Object.values(Object.assign({}, localRefFiles))

  if (!elogData || elogData.length <= 0) {
    alert('Elog data corrupted.')
    return;
  }

  if (localRefs.length <= 0) {
    alert('Cannot import elog: no ref files on device. Sync ref files and try again.')
    return;
  }

  let refResultId;

  //first try to find a ref file with the same name as the elog was created with.
  const nameMatch = localRefs.find((ref) => ref.name === refFileName);


  if (nameMatch) {
    refResultId = nameMatch.id
  } else {
    //JRS = Job Rig Shift
    const refFilesByJRS = getShiftDetailsFromRefs(localRefs);

    // console.log('local')
    // console.log(localRefs)

    // console.log('JRS')
    // console.log(refFilesByJRS)

    // console.log('Elog Data')
    // console.log(elogData)

    const elogHeader = elogData[0];
    const elogJob = elogHeader?.Job;
    const elogRig = elogHeader?.Rig;
    const elogShift = elogHeader?.Shift;


    //apply following logic to onelogselect
    let matches = [];

    const jobMatches = refFilesByJRS.combinations.filter((combo) => combo.job === elogJob);

    if (jobMatches.length > 0) {
      const rigMatches = jobMatches.filter((combo) => combo.rig === elogRig);

      if (rigMatches.length > 0) {
        const shiftMatches = rigMatches.filter((combo) => combo.shift === elogShift);

        if (shiftMatches.length > 0) {
          matches = shiftMatches;
        } else {
          matches = rigMatches;
        }

      } else {
        matches = jobMatches;
      }
    } else {
      alert('No ref files for job ' + elogJob)
      return;
    }

    if (matches.length <= 0) {
      alert('Reffile not located.');
      return;
    }

    if (matches.length === 1) {
      refResultId = matches[0].id;
    } else {
      //if there's multiple matches, do a product by product check 

      //get array of product codes in elog. splice to remove header li. Set to remove duplicates.
      const elogProducts = [...new Set(
        elogData.splice(1).map((li) => {
          return li.Product;
        })
      )];

      for (let i = 0; i < matches.length; i++) {
        const ref = localRefs.find((ref) => ref.id === matches[i].id)
        const prodMap = ref.refFile.PRODfile;
        const refProductCodes = prodMap.map((product) => product.ProdCode)

        const doProductsMatch = elogProducts.every(prod => refProductCodes.includes(prod));

        if (doProductsMatch) {
          refResultId = matches[i].id;
          break;
        }
      };
    }
  }

  if (refResultId == null) {
    alert('No ref file matches elog data. Contact administrator.');
    return;
  }

  return refResultId;
}

export function getShiftDetailsFromRef(refFile) {

  if (!refFile) {
    return;
  }

  const refFileFieldmap = refFile.refFile.FMAPfile;

  const jobField = refFileFieldmap.find(
    (field) => field.FieldName.toUpperCase() === "JOB"
  )?.Supplied;

  const rigField = refFileFieldmap.find(
    (field) => field.FieldName.toUpperCase() === "RIG"
  )?.Supplied;

  const shiftField = refFileFieldmap.find(
    (field) => field.FieldName.toUpperCase() === "SHIFT"
  )?.Supplied;

  return ({ job: jobField, rig: rigField, shift: shiftField });
}

export function getShiftDetailsFromRefs(refFiles) {

  if (!refFiles) {
    return;
  }

  let jobArr = [];
  let rigArr = [];
  let shiftArr = [];
  let combinations = [];

  refFiles.forEach((refFile) => {
    const refFileFieldmap = refFile.refFile.FMAPfile;

    const jobField = refFileFieldmap.find(
      (field) => field.FieldName.toUpperCase() === "JOB"
    )?.Supplied;

    const rigField = refFileFieldmap.find(
      (field) => field.FieldName.toUpperCase() === "RIG"
    )?.Supplied;

    const shiftField = refFileFieldmap.find(
      (field) => field.FieldName.toUpperCase() === "SHIFT"
    )?.Supplied;

    if (jobField && !jobArr.includes(jobField)) {
      jobArr.push(jobField)
    }

    if (rigField && !rigArr.includes(rigField)) {
      rigArr.push(rigField)
    }

    if (shiftField && !shiftArr.includes(shiftField)) {
      shiftArr.push(shiftField)
    }

    combinations.push({ job: jobField, rig: rigField, shift: shiftField, id: refFile.id })

  });

  // function compareListItems(a, b) {

  //   if (a === "Another") {
  //     return 1;
  //   }

  //   if (b === "Another") {
  //     return -1;
  //   }

  //   if (a < b) {
  //     return -1;
  //   }

  //   if (a === b) {
  //     return 0;
  //   }

  //   if (a > b) {
  //     return 1;
  //   }
  // }

  return ({
    rigs: rigArr.sort(/*compareListItems*/),
    jobs: jobArr.sort(/*compareListItems*/),
    shifts: shiftArr.sort(/*compareListItems*/),
    combinations
  });
}

export function findRefFileByJob(refFiles, job) {
  if (!job) {
    return;
  }

  const jobMatch = refFiles.find((refFile) => {
    const refFileFieldmap = refFile.ref_file_data.FMAPfile;
    const jobField = refFileFieldmap.find(
      (field) => field.FieldName.toUpperCase() === "JOB"
    );
    const refJob = jobField?.Supplied;

    if (job.toLowerCase() === refJob.toLowerCase()) {
      return true;
    }

    return false;
  });

  return jobMatch;
}

//make this a generic finder for job, rig, refname, etc
export function findRemoteRefFileByElog(elogRefFileName, elogData, refFiles) {
  if (refFiles.length <= 0) {
    alert("Cannot find any ref files to generate the report.");
    return;
  }

  if (!elogData || elogData.length <= 0) {
    alert("Elog has no data.");
    return;
  }

  const elogHeader = elogData[0];

  // Parameters for report generation
  const job = elogHeader?.Job;
  const rig = elogHeader?.Rig;

  if (refFiles.length <= 0) {
    return;
  }

  if (elogRefFileName) {
    const nameMatch = findRefFileByName(refFiles, elogRefFileName);
    if (nameMatch) {
      return nameMatch;
    }
  }

  const rigMatch = findRefFileByRig(refFiles, rig);

  if (rigMatch) {
    return rigMatch;
  }

  const jobMatch = findRefFileByJob(refFiles, job);

  if (jobMatch) {
    return jobMatch;
  }

  return refFiles[0];
}

export function generateRefFileForExport(selectedRef) {
  if (!selectedRef) {
    return;
  }

  let refData = {};
  Object.assign(refData, selectedRef);

  const refString = JSON.stringify(refData, function (k, v) {
    return v === undefined ? "" : v;
  });

  return new Blob([refString], { type: "text/json" });
}

export function generateElogHtml(elog, refFile) {
  const elogCopy = Object.assign({}, elog);

  const html = renderToStaticMarkup(
    <ElogPdfHtml
      elog={elogCopy}
      fieldMap={refFile.refFile.FMAPfile}
      prodMap={refFile.refFile.PRODfile}
      prefMap={refFile.refFile.PREFfile}
    />
  );

  return html;
}

export function getNextRefId(refFiles) {
  if (!refFiles) {
    return "1";
  }

  const refKeys = Object.keys(refFiles);

  if (refKeys.length < 1) {
    return "1";
  }

  const largestId = Math.max(...refKeys);
  const newKey = largestId + 1;

  return newKey.toString();
}

export function getApiHeaders(token) {
  //const hostEnv = process.env.REACT_APP_HOST_ENV;

  // if (hostEnv === "dev") {
  //   return {
  //     "Content-Type": "application/json",
  //     "x-hasura-admin-secret": "myadminsecretkey",
  //   };
  // }

  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
}

export function isDev() {
  const hostEnv = process.env.REACT_APP_HOST_ENV;

  if (hostEnv === "dev") {
    return true;
  }
  return false;
}

export function getApiUrl() {
  const hostEnv = process.env.REACT_APP_HOST_ENV;

  if (hostEnv === "staging") {
    return "https://ude-staging.hasura.app/v1/graphql";
  } else if (hostEnv === "prod") {
    return "https://ude.hasura.app/v1/graphql";
  } else if (hostEnv === "dev") {
    return "https://ude-staging.hasura.app/v1/graphql";
    //return "http://localhost:8080/v1/graphql";
  } else {
    return "https://ude-staging.hasura.app/v1/graphql";
  }
}

export function getDbEnv() {
  const hostEnv = process.env.REACT_APP_HOST_ENV;

  if (hostEnv === "staging") {
    return "UDE_STAGING_DB";
  } else if (hostEnv === "prod") {
    return "UDE_PROD_DB";
  } else if (hostEnv === "dev") {
    return "UDE_DEV_DB";
  } else {
    return "UDE_STAGING_DB";
  }
}

export function getAuthDetails() {
  const hostEnv = process.env.REACT_APP_HOST_ENV;

  if (hostEnv === "staging") {
    return {
      domain: "ude-staging.au.auth0.com",
      clientId: "2juLhrinyQjICdwDL6KUa1YyaCF6rKSn",
      audience: "hasura-staging",
    };
  } else if (hostEnv === "prod") {
    return {
      domain: "dev-lzn3u6gh.au.auth0.com",
      clientId: "4BD3saXPPoHHr619Mh3rsnlRXDjsrmXg",
      audience: "https://www.oughtomate.com.au/ude",
    };
  } else {
    return {
      domain: "ude-staging.au.auth0.com",
      clientId: "2juLhrinyQjICdwDL6KUa1YyaCF6rKSn",
      audience: "hasura-staging",
    };
  }
}

export function getRefFileName(prefFile) {
  let name = "Unknown";
  prefFile.forEach((pref) => {
    if (pref.PREF.includes(".REF")) {
      name = pref.PREF;
    }
  });
  return name;
}

export function decodeJwt(token) {
  if (!token) {
    return;
  }

  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return;
  }
}

export function getCompanyIdFromJwt(jwt) {
  if (!jwt) {
    return;
  }

  return jwt?.["https://hasura.io/jwt/claims"]?.["x-hasura-user-company"];
}

export function getCompanyNameFromJwt(jwt) {
  if (!jwt) {
    return;
  }

  return jwt?.["https://hasura.io/jwt/claims"]?.["x-hasura-user-company-name"];
}

export function getActivityAnalysisFlag(prefFile) {
  let hasFlag = false;
  prefFile.forEach((pref) => {
    const prefString = pref.PREF.toUpperCase();
    if (prefString.includes("ACTIVITYANALYSIS")) {
      if (prefString.includes("YES")) {
        hasFlag = true;
      }
    }
  });
  return hasFlag;
}

export function getElogPdfTitle(prefFile) {
  let title = "";

  prefFile.forEach((pref) => {
    const prefString = pref.PREF.toUpperCase();
    if (prefString.includes("PDFTITLE")) {
      title = prefString.replace("PDFTITLE=", "");
    }
  });

  return title;
}

export function getLogoPath(prefFile) {
  let path = "drc-drilling-logo.png";
  prefFile.forEach((pref) => {
    const prefString = pref.PREF.toUpperCase();
    if (prefString.includes("PDFLOGO")) {
      path = prefString.replace("PDFLOGO=", "");
    }
  });

  if (path === "DRC LOGO.JPG") {
    //To not break existing refs in the field
    return "drc-drilling-logo.png";
  }

  return path.toLowerCase();
}

export function getFontSize(prefFile) {
  let fontSize = 15;
  prefFile.forEach((pref) => {
    const prefString = pref.PREF.toUpperCase();
    if (prefString.includes("PDFFONT")) {
      const sizeString = prefString.replace("PDFFONT=", "");
      fontSize = parseFloat(sizeString);
    }
  });
  return fontSize * 1.2; //1.2 is Geoffs request.
}

export function getRoundTime(prefFile) {
  let roundTime = null;

  prefFile.forEach((pref) => {
    const prefString = pref.PREF.toUpperCase();
    if (prefString.includes("ROUNDTIME")) {
      const timeString = prefString.replace("ROUNDTIME=", "");
      roundTime = parseFloat(timeString);
    }
  });

  return roundTime;
}

export function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function getHeaderColour(prefFile) {
  let colour = "#c4dbf1";
  prefFile.forEach((pref) => {
    const prefString = pref.PREF.toUpperCase();
    if (prefString.includes("ELOGPDFBACKGROUNDCOLOUR=")) {
      const hexString = prefString.replace("ELOGPDFBACKGROUNDCOLOUR=", "");
      colour = hexString;
    }
  });
  return colour;
}

export function calculateDuration(timeStart, timeFinish) {
  const MINS_PER_HR = 60;
  const MINS_PER_DAY = 1440;

  let startHour = parseInt(timeStart[0] + timeStart[1]);
  let startMinute = parseInt(timeStart[2] + timeStart[3]);

  let endHour = parseInt(timeFinish[0] + timeFinish[1]);
  let endMinute = parseInt(timeFinish[2] + timeFinish[3]);

  let startx = startHour * MINS_PER_HR + startMinute;
  let endx = endHour * MINS_PER_HR + endMinute;

  let duration = endx - startx;
  if (duration < 0) {
    duration = duration + MINS_PER_DAY;
  }

  let durationInHours = duration / 60;
  return durationInHours.toFixed(3);
}

export function logErrorToServer(
  idbId,
  error,
  info,
  moreInfo = "",
  reduxState = {}
) {
  const reduxAuth = store.getState().authState;
  const token = reduxAuth.token;

  //if is online
  if (token && reduxAuth.auth.isAuthenticated) {
    const apiUrl = getApiUrl();

    let query = `mutation LogError($error: jsonb!, $info: jsonb!, $moreInfo: String, $reduxState: jsonb ) {
        insert_errors(objects: {error: $error, error_info: $info, more_info: $moreInfo, redux_state: $reduxState}) {
          affected_rows
        }
      }`;

    let variables = {
      error,
      info,
      moreInfo,
      reduxState,
    };

    try {
      fetch(apiUrl, {
        method: "POST",
        headers: getApiHeaders(token),
        body: JSON.stringify({ query, variables }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res?.data?.insert_errors?.affected_rows) {
            if (idbId >= 0) {
              PlodDb.delete("errors", idbId);
            }
          } else {
            if (idbId === -1) {
              const errorObj = { error, info, moreInfo, reduxState };
              const errorObjString = JSON.stringify(errorObj);
              PlodDb.put("errors", { errorObj: errorObjString });
            }
          }
        });
    } catch (e) {
      console.log(e)
    }
  } else {
    //not online
    if (idbId === -1) {
      const errorObj = { error, info, moreInfo, reduxState };
      const errorObjString = JSON.stringify(errorObj);
      PlodDb.put("errors", { errorObj: errorObjString });
    }
  }
}

export function generateListX(prefFile) {
  let listX = {};
  prefFile.forEach((x) => {
    const prefObj = x.PREF;

    if (prefObj.includes("ListX")) {
      let trimmed = prefObj.replace("ListX-", "");
      let splitStr = trimmed.split("=");
      let key = splitStr[0];
      let values = splitStr[1].split(";");

      listX[key] = values;
    }
  });

  return listX;
}

export function getRefRecipients(prefFile) {
  let recipients = [];
  prefFile.forEach((pref) => {
    const prefObj = pref?.PREF?.toLowerCase();

    if (prefObj.includes("interestedparties")) {
      const splitStr = prefObj.split("=");
      const emails = splitStr[1];
      recipients = emails.split(";");
    }
  });

  return recipients;
}

export function getRefReplyTo(prefFile) {
  let replyTo = "";
  prefFile.forEach((pref) => {
    const prefObj = pref?.PREF?.toLowerCase();

    if (prefObj.includes("replyto")) {
      const splitStr = prefObj.split("=");
      replyTo = splitStr[1];
    }
  });

  return replyTo;
}

export function getRefEmailBody(prefFile) {
  let emailBody = "Please see attached.";
  prefFile.forEach((pref) => {
    const prefObj = pref?.PREF?.toLowerCase();

    if (prefObj.includes("emailbody")) {
      const splitStr = prefObj.split("=");
      emailBody = splitStr[1];
    }
  });

  return `<p>${emailBody}</p>`;
}

export function generateEmployeeArray(empMap) {
  var empStrings = [];

  empMap.forEach((emp) => {
    empStrings.push(emp.EmpName);
  });

  return empStrings.sort();
}
