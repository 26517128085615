export const DEBUG = "Debug";
export const NEW_ELOG = "New Elog";
export const VIEW_ELOG = "View Elog";
export const EDIT_HEADER = "Edit Header";
export const EDIT_LINE_ITEM = "Edit Line Item";
export const HOME = "UDE Home";
export const NEW_LINE_ITEM = "New Line Item";
export const ADMIN_PANEL = "Admin Panel";

export const SAVE_SCREENS = [
  NEW_ELOG,
  EDIT_HEADER,
  EDIT_LINE_ITEM,
  NEW_LINE_ITEM,
  VIEW_ELOG,
];
