import React from 'react';

import Table from 'react-bootstrap/Table';
//import { getPageNameFromPageId } from '../../services/Utilities'

class ElogPdfTable extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      lineItems: [],
      page: null
    };
  }

  componentDidMount() {
    const lineItems = this.props.lineItems;
    const currentPage = this.props.page;

    this.setState({
      lineItems: lineItems,
      page: currentPage,
    });

  }


  render() {


    const lineItems = this.props.lineItems;
    const extendedColumns = this.props.specialColumns;
    const emptyColumns = this.props.emptyColumns;
    const fieldMap = this.props.fieldMap;

    const rowClicked = this.props.rowClicked
    const fontSizes = this.props.fontSizes
    const headerColour = this.props.headerColour


    if (lineItems.length < 1 || fieldMap.length < 1) {
      return (<div></div>);
    }

    let headings = []

    const tableHead = (

      <>
        <style>
          {`
        .shift-summary .table-panels thead th {
          background-color: ${headerColour} !important;
        }    
        
        .shift-summary .table-panels tr.total-row td {
          background-color: ${headerColour} !important;
        }`}
        </style>

        <thead style={{ backgroundColor: headerColour }}>
          <tr>
            {
              fieldMap.map((field, index) => {
                if (!extendedColumns.includes(index) && !emptyColumns.includes(index)) {
                  headings.push(field.FieldName)
                  return (
                    <th key={index} style={fontSizes.sm}>{field.Caption}</th>
                  );
                }
              })
            }
          </tr>
        </thead>
      </>
    );


    let tableTotalRow = (<></>)

    //Array of -1s equal to length of sumcolumns array. 
    const emptyTotalArray = new Array(this.props.sumColumns.length).fill(-1)

    //if sumcolumns if the same as an empty sumcolumns array
    if (JSON.stringify(this.props.sumColumns) != JSON.stringify(emptyTotalArray)) {
      tableTotalRow = (
        <tr style={{ backgroundColor: headerColour }} className='total-row'>
          {
            this.props.sumColumns.map((sum, index) => {
              if (!extendedColumns.includes(index) && !emptyColumns.includes(index)) {
                if (index == 0) {
                  return (<td key={`total-${index}`} className='sum-heading' style={fontSizes.sm}>Total:</td>)
                }

                if (sum != -1) {
                  return (<td key={`total-${index}`} className='sum-total' style={fontSizes.md}>{sum}</td>)
                }

                return (<td key={`total-${index}`}></td>)

              }
            })
          }
        </tr>
      )
    }

    return (
      <div className={'table-category table-' + (this.props?.tableName?.toLowerCase() || "")}>
        <h4 className='heading' style={fontSizes.lg}>{this.props?.tableName}</h4>
        <Table hover bordered size='sm'>
          {tableHead}
          <tbody>

            {

              lineItems.map((lineItem, liIndex) => {
                let rowSpan = 1;
                let extendedRow = <></>;

                extendedColumns.map(columnIndex => {
                  if (lineItem.fieldValues[fieldMap[columnIndex].FieldName]) {
                    rowSpan = 2;
                    return (<p className='extended-field' style={fontSizes.md}>{fieldMap[columnIndex].Caption} : {lineItem.fieldValues[fieldMap[columnIndex].FieldName]}</p>)
                  }
                })

                if (rowSpan > 1) {
                  extendedRow = (
                    <>
                      <tr className='extended-row' onClick={() => { rowClicked(lineItem.id) }} itemID={lineItem.id}>
                        <td colSpan='100%'>
                          {
                            extendedColumns.map((columnIndex, index) => {
                              if (lineItem.fieldValues[fieldMap[columnIndex].FieldName]) {
                                rowSpan = 2;
                                return (<p key={`extrow-${index}`} className={'extended-field ' + fieldMap[columnIndex].FieldName.toLowerCase()}><span className='caption' style={fontSizes.sm}>{fieldMap[columnIndex].Caption}:</span> <span className='data' style={fontSizes.md}>{lineItem.fieldValues[fieldMap[columnIndex].FieldName].toString()};</span></p>)
                              }
                            })
                          }
                        </td>
                      </tr>
                    </>
                  )
                }

                return (
                  <React.Fragment key={`row-${lineItem.id}-${liIndex}`} >
                    <tr onClick={() => { rowClicked(lineItem.id) }} itemID={lineItem.id}>
                      {
                        headings.map((fieldName) => {
                          return (<td key={"activity-" + fieldName} className={fieldName + ' rowspan-' + rowSpan} rowSpan='1' style={fontSizes.md}>{lineItem.fieldValues[fieldName]}</td>)
                        })
                      }

                    </tr>
                    {extendedRow}
                  </React.Fragment>




                )

              })
            }

            {tableTotalRow}
          </tbody>
        </Table>
      </div>

    )





  }


}


export default ElogPdfTable;