import {
  AUTH_REDIRECT,
  CHANGE_SCREEN,
  CLOSE_LOGIN_PROMPT,
  CLOSE_SHIFT_INFO_PROMPT,
  SHOW_LOGIN_PROMPT,
  SHOW_SHIFT_INFO_PROMPT,
  SHOW_RECIPIENT_PROMPT,
  CLOSE_RECIPIENT_PROMPT,
} from "../constants/actionTypes";

import { HOME } from "../constants/screenTypes";

const INITIAL_STATE = {
  screen: HOME,
  prevScreen: null,
  redirecting: false,
  showLoginPrompt: false,
  showShiftInfoPrompt: false,
  showRecipientPrompt: false,
  elogToSend: {},
};

const applyChangeScreen = (state, action) => ({
  ...state,
  screen: action.id,
  prevScreen: state.screen,
});

const applyAuthRedirect = (state) => ({
  ...state,
  redirecting: true,
});

const applyShowLoginPrompt = (state) => ({
  ...state,
  showLoginPrompt: true,
});

const applyCloseLoginPrompt = (state) => ({
  ...state,
  showLoginPrompt: false,
});

const applyShowShiftInfoPrompt = (state) => ({
  ...state,
  showShiftInfoPrompt: true,
});

const applyCloseShiftInfoPrompt = (state) => ({
  ...state,
  showShiftInfoPrompt: false,
});

const applyShowRecipientPrompt = (state, action) => ({
  ...state,
  showRecipientPrompt: true,
  elogToSend: action?.payload?.elog,
});

const applyCloseRecipientPrompt = (state) => ({
  ...state,
  showRecipientPrompt: false,
  elogToSend: {},
});

function globalReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHANGE_SCREEN: {
      return applyChangeScreen(state, action);
    }

    case AUTH_REDIRECT: {
      return applyAuthRedirect(state, action);
    }

    case SHOW_LOGIN_PROMPT: {
      return applyShowLoginPrompt(state, action);
    }

    case CLOSE_LOGIN_PROMPT: {
      return applyCloseLoginPrompt(state, action);
    }

    case SHOW_SHIFT_INFO_PROMPT: {
      return applyShowShiftInfoPrompt(state, action);
    }

    case CLOSE_SHIFT_INFO_PROMPT: {
      return applyCloseShiftInfoPrompt(state, action);
    }

    case SHOW_RECIPIENT_PROMPT: {
      return applyShowRecipientPrompt(state, action);
    }

    case CLOSE_RECIPIENT_PROMPT: {
      return applyCloseRecipientPrompt(state, action);
    }

    default:
      return state;
  }
}

export default globalReducer;
