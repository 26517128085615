import {
  UPDATE_AUTH,
  UPDATE_TOKEN,
  UPDATE_USER_EMAIL_PERMISSIONS,
  UPDATE_USER_ID,
} from "../constants/actionTypes";

const doUpdateAuth = (auth) => ({
  type: UPDATE_AUTH,
  auth,
});

const doUpdateToken = (token) => ({
  type: UPDATE_TOKEN,
  token,
});

const doUpdateUserId = (id) => ({
  type: UPDATE_USER_ID,
  id,
});

const doUpdateUserEmailPermissions = (allowed) => ({
  type: UPDATE_USER_EMAIL_PERMISSIONS,
  allowed,
});

export {
  doUpdateAuth,
  doUpdateToken,
  doUpdateUserId,
  doUpdateUserEmailPermissions,
};
