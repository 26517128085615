import { call, put, select } from "redux-saga/effects";
import {
  doSaveFiltersToIdbSuccess,
  doSaveFiltersToIdbError,
  doLoadFiltersFromIdbSuccess,
  doLoadFiltersFromIdbError,
} from "../actions/adminPanel";
import {
  fetchAdminFiltersFromIdb,
  saveAdminFiltersToIdb,
} from "../api/adminPanel";

const getActiveFilters = (state) => state.adminPanelState.activeFilters;

/*eslint-disable */
function* handleFetchAdminFilters(action) {
  /*eslint-enable */
  try {
    const result = yield call(fetchAdminFiltersFromIdb);
    if (result && Object.keys(result).length > 0) {
      yield put(doLoadFiltersFromIdbSuccess(result));
    } else {
      yield put(doLoadFiltersFromIdbError());
    }
  } catch (error) {
    yield put(doLoadFiltersFromIdbError());
  }
}

/*eslint-disable */
function* handleSaveAdminFilters(action) {
  /*eslint-enable */
  try {
    const activeFilters = yield select(getActiveFilters);
    yield call(saveAdminFiltersToIdb, activeFilters);
    yield put(doSaveFiltersToIdbSuccess());
  } catch (error) {
    console.log(error);
    yield put(doSaveFiltersToIdbError());
  }
}

export { handleFetchAdminFilters, handleSaveAdminFilters };
