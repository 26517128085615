import React from 'react';

import ElogPdfTable from './ElogPdfTable';

import { getActivityAnalysisFlag, getElogPdfTitle, getFontSize, getHeaderColour, getLogoPath, getPageCount, logErrorToServer } from '../../services/Utilities'
import ElogPdfAnalysis from './ElogPdfAnalysis';

//pagecount is hardcoded. Should be from ref file

class ElogPdfHtml extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      tables: [],
    };
  }

  intersect(arrays) {
    if (0 === arrays.length) {
      return [];
    }
    return arrays.reduce((intersection, array) => {
      return intersection.filter(intersectedItem => array.some(item => intersectedItem === item));
    }, arrays[0]);
  }

  getAllIndexes(arr, val) {
    var indexes = [], i;
    for (i = 0; i < arr.length; i++)
      if (arr[i] === val)
        indexes.push(i);
    return indexes;
  }

  findEmptyColumns(lineItems, fieldMap) {

    let arrayOfArrays = []

    lineItems.map(lineItem => {
      const blankIndexes = []

      fieldMap.map((field, index) => {
        if (!lineItem.fieldValues[field.FieldName]) {
          blankIndexes.push(index)
        }
      })
      arrayOfArrays.push(blankIndexes)
    })

    const emptyColumnIndexes = this.intersect(arrayOfArrays)
    return emptyColumnIndexes
  }

  getSumTotalColumns(fieldMap, lineItems) {

    const sumField = function (fieldName, fieldIndex, lineItems) {
      let total = 0;
      lineItems.map(lineItem => {
        const value = parseFloat(lineItem.fieldValues[fieldName])
        if (value) {
          total += value
        }

      })

      const decimalPlaces = parseInt(fieldMap[fieldIndex].Decimals);

      return total.toFixed(decimalPlaces)
    }

    let sumColumnFields = new Array(fieldMap.length).fill(-1)

    fieldMap.map((field, index) => {
      if (!field.PrintCol) {
        console.log()
      }
      else if (field.PrintCol.toUpperCase() == 'TOTAL') {
        sumColumnFields[index] = sumField(field.FieldName, index, lineItems)
      }
    })

    return sumColumnFields

  }

  componentDidMount() {

    try {
      const pageCount = getPageCount(fieldMap);
      let tables = [];

      const elog = this.props.currentElog;
      const lineItems = Object.values(elog.lineItems);
      const fieldMap = this.props.fieldMap;
      let activityAnalysis = null
      let fontSize = this.props.elog?.fontSize || 12;
      let headerColour = null;
      let logoPath = null;

      //With properties to determine placement in product table
      //const adjustedLineItems = addColumnPlacement(lineItems, fieldMap);

      for (let currentPage = 1; currentPage <= pageCount; currentPage++) {
        let lineItemsByPage = lineItems.filter(lineItem => lineItem.page == currentPage);
        let fieldMapByPage = fieldMap.filter(field => field.PageNo == currentPage && field.FieldName !== "PAGE");
        let specialColumnIndexes = this.generateDisplayProperties(lineItemsByPage, fieldMapByPage)
        const emptyColumnIndexes = this.findEmptyColumns(lineItemsByPage, fieldMapByPage)
        const tableName = fieldMap.find(field => field.PageNo == currentPage && field.FieldName === "PAGE")?.Caption;

        const sumColumns = this.getSumTotalColumns(fieldMapByPage, lineItemsByPage)

        tables.push(
          <ElogPdfTable
            key={currentPage}
            page={currentPage}
            fieldMap={fieldMapByPage}
            lineItems={lineItemsByPage}
            specialColumns={specialColumnIndexes}
            emptyColumns={emptyColumnIndexes}
            rowClicked={(id) => { this.props.editLineItem(id) }}
            sumColumns={sumColumns}
            tableName={tableName}
          />)
      }

      if (!fontSize) {
        fontSize = getFontSize(this.props.refFiles[this.props.selectedRef].refFile.PREFfile)
      }

      activityAnalysis = getActivityAnalysisFlag(this.props.refFiles[this.props.selectedRef].refFile.PREFfile)
      headerColour = getHeaderColour(this.props.refFiles[this.props.selectedRef].refFile.PREFfile)
      activityAnalysis = getActivityAnalysisFlag(this.props.prefMap)
      logoPath = getLogoPath(this.props.refFiles[this.props.selectedRef].refFile.PREFfile)

      this.setState({
        tables: tables,
        activityAnalysis: activityAnalysis,
        fontSize: fontSize,
        headerColour: headerColour,
        logoPath: logoPath,
      });

    } catch (e) {
      logErrorToServer(-1, e, {}, 'ElogPdfHtml: componentDidMount', {})
    }

  }

  getTables(fontSizes, headerColour) {
    let tables = [];

    const elog = Object.assign({}, this.props.elog);
    const lineItems = Object.values(elog.lineItems);
    const fieldMap = this.props.fieldMap;

    const pageCount = getPageCount(fieldMap)

    for (let currentPage = 1; currentPage <= pageCount; currentPage++) {
      let lineItemsByPage = lineItems.filter(lineItem => lineItem.page == currentPage);

      if (lineItemsByPage.length > 0) {

        let fieldMapByPage = fieldMap.filter(field => field.PageNo == currentPage && field.FieldName !== "PAGE");
        let specialColumnIndexes = this.generateDisplayProperties(lineItemsByPage, fieldMapByPage)
        const emptyColumnIndexes = this.findEmptyColumns(lineItemsByPage, fieldMapByPage)
        const tableName = fieldMap.find(field => field.PageNo == currentPage && field.FieldName === "PAGE")?.Caption;

        const sumColumns = this.getSumTotalColumns(fieldMapByPage, lineItemsByPage)

        tables.push(
          <ElogPdfTable
            key={currentPage}
            page={currentPage}
            fieldMap={fieldMapByPage}
            lineItems={lineItemsByPage}
            specialColumns={specialColumnIndexes}
            emptyColumns={emptyColumnIndexes}
            sumColumns={sumColumns}
            tableName={tableName}
            fontSizes={fontSizes}
            headerColour={headerColour} />)
      }
    }
    return tables;
  }

  generateDisplayProperties(lineItems, fieldMap) {

    var dedicatedRowIndexes = [];

    fieldMap.forEach((field, index) => {
      if (!field.PrintCol) {
        console.log()
      }
      else if (field.PrintCol.toUpperCase() !== "COLUMN" && field.PrintCol.toUpperCase() !== "TOTAL") {
        dedicatedRowIndexes.push(index);
      }
    })

    return dedicatedRowIndexes;
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
  //     //alert('changed')
  //   }
  // }


  render() {

    try {
      const elog = this.props.elog;
      const header = elog.header;
      const lineItems = Object.values(elog.lineItems);
      const fieldMap = this.props.fieldMap
      const activityAnalysis = getActivityAnalysisFlag(this.props.prefMap)
      let fontSize = elog?.fontSize || 12;
      const headerColour = getHeaderColour(this.props.prefMap);
      const logoPath = getLogoPath(this.props.prefMap);
      const pdfTitle = getElogPdfTitle(this.props.prefMap);

      if (!fontSize) {
        fontSize = getFontSize(this.props.prefMap)
      }

      let fontSizes = {}

      if (fontSize) {

        fontSizes = {
          sm: { fontSize: (fontSize * 0.8).toString() + 'pt' },
          md: { fontSize: fontSize.toString() + 'pt' },
          lg: { fontSize: (fontSize * 1.2).toString() + 'pt' }
        }
      }

      let tables = this.getTables(fontSizes, headerColour)


      const headerFieldMap = fieldMap.filter(field => field.PageNo == 0 && field.FieldName !== 'PAGE');

      //Move this head panel code to component did mount
      let headerPanelMap = headerFieldMap.filter(field => field.IsPrint && field.FieldName !== 'Comments')
      headerPanelMap = headerPanelMap.sort((a, b) => parseInt(a.PrintCol) - parseInt(b.PrintCol) || parseInt(a.PrintOrder) - parseInt(b.printOrder));

      const headerComment = header['Comments'];
      const plodNo = header['PlodNo'];

      let headerRow = -1

      const cssOutOfHours = `
    table.chart-table tbody tr td:nth-last-child(-n+6):not(.label), 
    table.chart-table tbody tr td:nth-child(-n+5):not(.label),
      table.chart-table {
        background-color: ${headerColour};
      }
    `;

      return (
        <>
          <style>
            {cssOutOfHours}
          </style>
          <div className='shift-summary'>
            <div style={{ backgroundColor: headerColour }} onClick={this.props.editHeader} className='header-panel'>
              <img align='left' src={process.env.PUBLIC_URL + '/' + logoPath} />

              <div className='panel-heading'>
                {/*<h5 style={fontSizes.sm}>DAILY SURFACE DRILLING REPORT</h5>*/}
                <h5 style={fontSizes.md}>No: {plodNo}</h5>
              </div>

              <div className='header-data'>
                {
                  headerPanelMap.map((field, index) => {
                    let newLines = []
                    let value = header[field.FieldName]

                    if (value) {
                      if (field.FieldName == 'ShiftDate') {
                        value = new Date(value).toLocaleString('en-AU', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
                      }
                      if (field.FieldName == 'PlodNo') {
                        return <React.Fragment key={index}></React.Fragment>
                      }
                      let headerField = <div className='key-val-pair'><span className='key' style={fontSizes.sm}>{field.Caption}:</span> <span className='value' style={fontSizes.md}>{value}</span></div>

                      if (field.IsPrint === 'b' || field.IsPrint === 'B') {
                        headerField = <div className='key-val-pair'><span className='key' style={fontSizes.sm}>{field.Caption}:</span> <b><span className='value' style={Object.assign({}, fontSizes.md, { color: 'red' })}>{value}</span></b></div>
                      }

                      if (field.PrintCol > headerRow && headerRow != -1) {
                        const breakCount = field.PrintCol - headerRow

                        for (let i = 0; i < breakCount; i++) {
                          newLines.push(<br key={index + '-' + i} />)
                        }

                      }
                      headerRow = field.PrintCol
                      return (
                        <React.Fragment key={index}>
                          {
                            newLines.map(br => { return (br) })
                          }
                          {headerField}
                        </React.Fragment>

                      )
                    }
                  })
                }
              </div>

            </div>

            {headerComment && <p style={fontSizes.md} className='header-note'><span className='label'>Note:</span> {headerComment}</p>}



            <div className='table-panels'>
              {
                tables.map(table => {
                  return (table);
                })

              }
            </div>


            {activityAnalysis &&
              <div className='activity-analysis'>
                <h4 className='heading' style={fontSizes.lg}>Activity Analysis</h4>
                <div className='activity-analysis-chart'>
                  <ElogPdfAnalysis prodMap={this.props.prodMap} header={header} lineItems={lineItems} />
                </div>
              </div>
            }


            <div className='shift-summary-footer'>
              <hr className='footer-divider' />
              <img src={process.env.PUBLIC_URL + '/newdriller1.jpg'} />
              <div className='footer-content'>

                <div className='first-row'>
                  <p className='red left'>UDE eLogPDF</p>
                  <p className='bold red right'>{pdfTitle || 'PLOD SHEET'} No: {header['PlodNo']}</p>
                  <p className='bold right'>{new Date(header['ShiftDate']).toLocaleString('en-AU', { year: '2-digit', month: '2-digit', day: '2-digit' })}</p>
                  <p className='bold right'>{header['Shift']} Shift</p>

                </div>

                <div className='second-row'>
                  <p className='left'>© Copyright 2021 PlodCo Pty Limited <a className='footer-url' href='http://plod.com.au' target='_blank' rel="noreferrer">
                    <span className='actual-url'> (www.plod.com.au)</span>
                  </a></p>
                  <p className='right'>As at: {new Date(Date.now()).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })} {new Date(Date.now()).toLocaleString('en-AU', { year: 'numeric', month: '2-digit', day: '2-digit' })}</p>
                </div>
              </div>
            </div>

          </div>

        </>
      );
    } catch (e) {
      logErrorToServer(-1, e, {}, 'ElogPdfHtml: render', {})
    }
  }

}

export default ElogPdfHtml;