import { call, put, select } from 'redux-saga/effects';
import { doSaveElogIdb, doUpdateElogHtml, doUploadElog } from '../actions/elog';
import { doBatchUpdatePriorEntry } from '../actions/priorEntry';
import { savePriorEntriesToIdb } from '../api/priorEntry';
import { EDIT_HEADER, EDIT_LINE_ITEM, NEW_ELOG, NEW_LINE_ITEM, SAVE_SCREENS } from '../constants/screenTypes';
import { generateElogHtml, getPriorEntryFields, logErrorToServer } from '../services/Utilities';


const getPrevScreen = (state) => state.globalState.prevScreen;
const getElogId = (state) => state.elogState.currentElogId;
const getElogs = (state) => state.elogState.elogs;
const getLineItemId = (state) => state.elogState.currentLineItemId;

const getIsAuthenticated = (state) => state.authState.auth?.isAuthenticated;
//const getAuthToken = (state) => state.authState.token;
const getAuthUser = (state) => state.authState.auth?.user;

const getRefFiles = (state) => state.refFileState.refFiles;
const getPageId = (state) => state.refFileState.currentPage;
const getRefId = (state) => state.refFileState.currentRefId;

/* eslint-disable */
function* handleScreenChangeSave(action) {
  /* eslint-enable */
  try {
    const screen = yield select(getPrevScreen)

    if (SAVE_SCREENS.includes(screen)) {

      const elogs = yield select(getElogs)
      const elogId = yield select(getElogId)

      if (!elogId || Object.keys(elogs).length < 1 || elogs[elogId] === undefined) {
        alert('Error: handleScreenChange() - elog undefined')
        return;
      }

      const currentElog = elogs[elogId];
      const refFiles = yield select(getRefFiles)
      const refFileId = yield select(getRefId)
      const currentPage = yield select(getPageId)

      const isAuthenticated = yield select(getIsAuthenticated)
      //const authToken = yield select(getAuthToken)
      const user = yield select(getAuthUser)
      let userRole = null
      let email = null

      if (user && Object.keys(user).length > 0) {
        userRole = user['https://hasura.io/jwt/claims/roles'][0]
        email = user.email;
      }

      if (currentElog.needsUpdateIdb) {
        if (Object.keys(refFiles).length > 0 && refFileId /*&& currentPage !== null going to move further down to where relevant*/) {
          const currentRefFile = refFiles[refFileId]
          const html = generateElogHtml(currentElog, currentRefFile)

          if (html) {
            yield put(doUpdateElogHtml({ id: currentElog.id, html }));
          } else {
            logErrorToServer(-1, { html: html }, {}, 'eloghtml is empty', currentElog)
          }

          let fieldValues = null;
          if (screen === NEW_ELOG || screen === EDIT_HEADER) {
            fieldValues = Object.assign({}, currentElog.header)
          } else if (screen === NEW_LINE_ITEM || screen === EDIT_LINE_ITEM) {
            let lineItemId = yield select(getLineItemId)
            if (currentElog.lineItems[lineItemId]) { //if line item was deleted
              fieldValues = Object.assign({}, currentElog.lineItems[lineItemId].fieldValues)
            }
          }

          if (fieldValues && Object.keys(fieldValues).length > 0 && currentPage !== null) {

            //Clean object of empty values
            Object.keys(fieldValues).forEach((key) => (fieldValues[key] == null) && delete fieldValues[key])

            //return fmap values that have priorentry flag
            const priorEntryFields = getPriorEntryFields(currentPage, currentRefFile.refFile.FMAPfile)

            if (priorEntryFields.length > 0) {
              //Excludes all fields not contained in priorEntryFields
              const filtered = priorEntryFields.reduce((obj, key) => ({ ...obj, [key]: fieldValues[key] }), {});

              if (Object.keys(filtered).length > 0) {
                yield put(doBatchUpdatePriorEntry(filtered));
                yield call(savePriorEntriesToIdb, filtered);
                //alert('prior entries saved')
              }
            }
          }
        }
      }


      const updatedElogs = yield select(getElogs)
      const updatedElog = updatedElogs[elogId]

      if (currentElog.needsUpdateServer && isAuthenticated && userRole && email && !currentElog.isUploading) {
        yield put(doUploadElog({ elog: updatedElog }));
      } else if (currentElog.needsUpdateIdb) {
        yield put(doSaveElogIdb({ id: elogId, elog: updatedElog }));
      }

    }
  } catch (error) {
    console.log("ERROR")
    console.log(error)
    alert('Error in global reducer')
    //yield put(doSavePriorEntriesError(error));
  }
}

export {
  handleScreenChangeSave,
};