import React from "react";
import { connect } from "react-redux";

import {
  Button,
  Modal,
} from "react-bootstrap";
import { getShiftDetailsFromRefs } from "../services/Utilities";
import { HOME } from "../constants/screenTypes";
import { doCloseShiftInfoPrompt } from "../actions/global";

class ShiftPrompt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job: '',
      rig: '',
      shift: '',
      refDetails: {},
      fetching: true,
      jobOptions: [],
      rigOptions: [],
      shiftOptions: [],
    };
  }

  changeJob(e) {
    this.setState({ job: e.target.value, rig: '', shift: '' }, () => {
      this.updateValidOptions();
    });
  }

  updateValidOptions() {

    const validCombos = Object.values(this.state.refDetails.combinations).filter((combo) => {
      if (combo.job === this.state.job) {
        if (this.state.rig && this.state.rig !== combo.rig) {
          return false;
        }
        return true;
      }
    })

    const validRigs = validCombos.map((combo) => { if (combo.rig) { return combo.rig } })


    const validShifts = validCombos.filter((combo) => {
      if (combo.shift) {
        return combo.shift
      }
    })

    this.setState({ shiftOptions: Array.from(new Set(validShifts)), rigOptions: Array.from(new Set(validRigs)) })
  }

  changeRig(e) {
    this.setState({ rig: e.target.value, shift: '' }, () => {
      this.updateValidOptions();
    });
  }

  changeShift(e) {
    this.setState({ shift: e.target.value });
  }

  updateStateFromRefs() {
    const refFiles = this.props.refFiles;
    const shiftDetails = getShiftDetailsFromRefs(Object.values(refFiles));
    this.setState({ refDetails: shiftDetails, fetchingRefs: this.props.fetchingRefFiles, jobOptions: shiftDetails.jobs }, () => {
      this.updateValidOptions();
    })
  }

  componentDidMount() {
    this.updateStateFromRefs();
  }

  componentDidUpdate(prevProps) {
    if ((JSON.stringify(prevProps.refFiles) !== JSON.stringify(this.props.refFiles))
      || (prevProps.fetchingRefFiles !== this.props.fetchingRefFiles)) {
      this.updateStateFromRefs();
    }
  }

  submitShift() {
    const job = this.state.job;
    const rig = this.state.rig;
    const shift = this.state.shift
    let shiftRequired = false;

    const shiftOptions = this.state.shiftOptions;

    if (shiftOptions.filter((shift) => shift).length > 0) {
      shiftRequired = true;
    }

    if (!job || !rig || (!shift && shiftRequired)) {
      alert("Please specify job, rig");
      return;
    }

    //const shiftDetails = { job, rig, shift };

    this.props.onCloseShiftInfoPrompt();
    const selectedRef = this.state.refDetails?.combinations?.find((ref) =>
      ref.job == job && ref.rig == rig && (shiftRequired ? ref.shift == shift : true) // only search for shift if there is a shift field
    )

    const selectedRefFile = selectedRef;

    this.props.submitShiftDetails(selectedRefFile);
  }

  render() {

    const { fetchingRefs, refDetails, jobOptions, rigOptions, shiftOptions, job, rig, shift } = this.state;
    const refCount = refDetails?.combinations?.length || 0;

    return (
      <>
        {
          this.props.screen === HOME && (
            // && is online
            <>
              <Modal
                className="shift-details-prompt"
                animation={false}
                show={this.props.showShiftInfoPrompt}
                onHide={this.props.onCloseShiftInfoPrompt}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Shift Details</Modal.Title>
                </Modal.Header>

                {!fetchingRefs && refCount > 0 &&
                  <Modal.Body>
                    <p>Please specify shift details.</p>
                    <select value={job} onChange={(job) => this.changeJob(job)}>
                      <option key="job-placeholder" value={''}>
                        Select job
                      </option>
                      {jobOptions.map((job, index) => (
                        <option key={"job-" + index} value={job}>
                          {job}
                        </option>
                      ))}
                    </select>
                    {job &&
                      <select value={rig} onChange={(rig) => this.changeRig(rig)}>
                        <option key="rig-placeholder" value={''}>
                          Select rig
                        </option>
                        {rigOptions.map((rig, index) => (
                          <option key={"rig-" + index} value={rig}>
                            {rig}
                          </option>
                        ))}
                      </select>
                    }
                    {job && rig && shiftOptions.length > 0 &&
                      <select value={shift} onChange={(shift) => this.changeShift(shift)}>
                        <option key='shift-placeholder' value={''}>Select shift</option>
                        {
                          shiftOptions.map((shift, index) => {
                            return (<option key={'shift-' + index} value={shift?.shift}>{shift?.shift}</option>)
                          })
                        }
                      </select>
                    }
                  </Modal.Body>
                }

                {(fetchingRefs || !refCount) &&
                  <Modal.Body>
                    <p>Please wait... ref files are loading.</p>
                  </Modal.Body>
                }
                <Modal.Footer>

                  {!fetchingRefs &&
                    <Button variant="info" onClick={() => this.submitShift()}>
                      New eLog
                    </Button>
                  }
                  <Button
                    variant="info"
                    onClick={() => {
                      this.setState({
                        job: '',
                        rig: '',
                        shift: '',
                        refDetails: {},
                        fetching: true,
                        jobOptions: [],
                        rigOptions: [],
                        shiftOptions: [],
                      })
                      this.props.onCloseShiftInfoPrompt();
                    }}
                  >
                    Cancel
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseShiftInfoPrompt: () => dispatch(doCloseShiftInfoPrompt()),
  };
};

const mapStateToProps = (state) => {
  return {
    showShiftInfoPrompt: state.globalState.showShiftInfoPrompt,

    shiftCombinations: state.refFileState.refFileCombinations,
    fetchingRefFiles: state.refFileState.fetchingRefFiles,

    screen: state.globalState.screen,
    authInfo: state.authState.auth,
    authToken: state.authState.token,

    refFiles: state.refFileState.refFiles,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftPrompt);
