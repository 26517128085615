import { PlodDb } from "../services/DBService";

const fetchAdminFiltersFromIdb = () =>
  PlodDb.get("preferences", "active_admin_filters").then(
    (activeFilters) => activeFilters
  );

const saveAdminFiltersToIdb = (activeFilters) => {
  PlodDb.put("preferences", activeFilters, "active_admin_filters");
};

export { saveAdminFiltersToIdb, fetchAdminFiltersFromIdb };
