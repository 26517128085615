import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";

class ProdXField extends React.Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array),
  };

  firstInput = {};
  dropDown = {};

  constructor(props) {
    super(props);

    this.dropDown = React.createRef();

    this.firstInput = React.createRef();

    let filteredSuggestions = [];
    if (this.props.showSuggestions === true) {
      filteredSuggestions = this.props.suggestions;
    }

    let defaultValue = "";

    if (this.props.default) {
      defaultValue = this.props.default;
    }

    let activeSuggestion = filteredSuggestions.indexOf(defaultValue);

    //not found
    if (activeSuggestion < 0) {
      activeSuggestion = 0;
    }

    this.state = {
      // The active selection's index
      activeSuggestion: activeSuggestion,
      // The suggestions that match the user's input
      filteredSuggestions: filteredSuggestions,
      // Whether or not the suggestion list is shown
      showSuggestions: this.props.showSuggestions && !this.props.value,
      // What the user has entered
      userInput: defaultValue,
      isActive: false,
      productCategories: this.props.productCategories,
      categoryMode: false,
      menuPage: 0,
      menuSelections: this.getProductCategories(),
    };
  }

  componentDidMount() {
    this.firstInput.current.focus();
    this.firstInput.current.scrollIntoView();

    let filteredSuggestions = [];

    if (this.props.showSuggestions === true) {
      filteredSuggestions = this.props.suggestions;
    }

    this.setState({
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: filteredSuggestions,
      // Whether or not the suggestion list is shown
      showSuggestions: this.props.showSuggestions && !this.props.value,
      // What the user has entered
      //userInput: defaultValue,
      isActive: false,
      productCategories: this.props.productCategories,
      categoryMode: false,
      menuPage: 0,
      menuSelections: this.getProductCategories(),
    });
  }

  // Event fired when the input value is changed
  onChange = (e) => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value.toLowerCase();

    // Filter our suggestions that don't contain the user's input
    let filteredSuggestions = suggestions.filter((suggestion) => {
      const lowerSuggestion = suggestion.toLowerCase();
      if (userInput.length <= 1) {
        return lowerSuggestion.startsWith(userInput);
      }
      return lowerSuggestion.includes(userInput);
    });

    filteredSuggestions.sort((a, b) => {
      const aProd = a.split(" - ")[0]?.toLowerCase();
      const bProd = b.split(" - ")[0]?.toLowerCase();

      if (aProd === userInput) {
        return -1;
      }

      if (bProd === userInput) {
        return 1;
      }

      if (aProd.includes(userInput)) {
        if (bProd.includes(userInput)) {
          return 0;
        }
        return -1;
      }
      return 1;
    });

    let visibleInput = userInput;

    if (this.props.allCaps) {
      visibleInput = visibleInput.toUpperCase();
    }

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: visibleInput,
      //fieldLabel: label,
    });
  };

  // Event fired when the user clicks on a suggestion
  onClick = (e) => {
    if (this.state.categoryMode) {
      if (this.state.menuPage == 0) {
        this.setState({
          menuPage: 1,
          menuSelections: this.getProductsFromCategory(
            e.currentTarget.getAttribute("index")
          ),
        });
      } else if (this.state.menuPage == 1) {
        //let productCode = e.currentTarget.innerText.split(' - ')[0]
        this.props.onSelection(e.currentTarget.innerText);
      }
    } else {
      //this.props.incrementFocus()
      // Update the user input and reset the rest of the state
      this.setState({
        activeSuggestion: 0,
        filteredSuggestions: [],
        showSuggestions: false,
        userInput: e.currentTarget.innerText,
        itemClicked: true,
      });
    }

    //console.log(e.currentTarget.innerText)
    //  var finalSelection = e.currentTarget.innerText;

    // if (this.props.onSelection) {
    //   this.props.onSelection(finalSelection);
    // }
  };

  onBlur = () => {
    const {
      activeSuggestion,
      filteredSuggestions,
      userInput,
      itemClicked,
      categoryMode,
      menuPage,
    } = this.state;

    // if (showSuggestions) {
    if (this.props.onSelection) {
      if (categoryMode) {
        if (menuPage == 1 && itemClicked) {
          this.props.onSelection(userInput);
        }
      } else {
        if (itemClicked) {
          this.props.onSelection(userInput);
        } else {
          if (filteredSuggestions[activeSuggestion]) {
            this.props.onSelection(filteredSuggestions[activeSuggestion]);
          }
        }
      }
      //this.props.onSelection(userInput);
    }
    // }

    this.setState({
      isActive: false,
      userInput: filteredSuggestions[activeSuggestion],
      showSuggestions: false,
      itemClicked: false,
    });
  };

  onFocus = () => {
    this.setState({ isActive: true, showSuggestions: true });
  };

  // Event fired when the user presses a key down
  onKeyDown = (e) => {
    const { activeSuggestion, filteredSuggestions, showSuggestions } =
      this.state;

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13 || e.keyCode === 9) {
      //if (e.keyCode !== 9) {

      //if (this.props.label == 'Product' || this.props.label == 'Employee:' || this.props.label == 'UoM:' || this.props.label == 'Role:') {
      //if (this.props.label != 'Product') {
      e.preventDefault();
      //}
      // }
      // else {
      //   //e.preventDefault()
      // }
      //}

      if (showSuggestions) {
        if (this.props.onSelection) {
          const filtered = filteredSuggestions[activeSuggestion];
          const input = this.state.userInput;

          if (filtered) {
            this.props.onSelection(filtered);
          } else {
            if (this.props.suggestions.includes(input)) {
              this.props.onSelection(input);
            } else {
              this.props.onSelection(filteredSuggestions[activeSuggestion]);
            }
          }
        }
      }

      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
      });
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      e.preventDefault();
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      e.preventDefault();
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  getProductCategories() {
    let filteredSuggestions = this.props.productCategories.map(
      (prod, index) => {
        if (this.getProductsFromCategory(index).length > 0) {
          return prod.Caption;
        }
      }
    );
    return filteredSuggestions;
  }

  getProductsFromCategory(categoryIndex) {
    let categoryId = parseInt(categoryIndex) + 1;
    let filteredProducts = this.props.productMap.filter(
      (prod) => prod.PageType == categoryId
    );
    let prodCodes = filteredProducts.map((prod) => prod.ProdCode);
    let prodDescriptions = filteredProducts.map((prod) => prod.ProdDesc);
    let prodStrings = [];

    prodCodes.forEach((element, index) => {
      let item = prodCodes[index] + " - " + prodDescriptions[index];
      prodStrings.push(item);
    });
    return prodStrings;
  }

  handleDoubleClick = () => {
    if (this.props.currentPage) {
      return;
    }
    this.firstInput.current.blur();
    this.setState({
      categoryMode: true,
      showSuggestions: true,
      activeSuggestion: null,
    });
  };

  navigateBack = () => {
    if (this.state.menuPage == 0) {
      this.firstInput.current.focus();
      this.firstInput.current.scrollIntoView();
      this.setState({
        isActive: false,
        showSuggestions: false,
        itemClicked: false,
        categoryMode: false,
      });
    } else if (this.state.menuPage == 1) {
      this.setState({
        menuPage: 0,
        menuSelections: this.getProductCategories(),
      });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput,
        categoryMode,
        menuSelections,
      },
    } = this;

    let suggestionsListComponent;

    let readOnly = false;

    if (this.props.currentPage) {
      readOnly = true;
    }

    if (categoryMode) {
      suggestionsListComponent = (
        <ul className="suggestions category-mode" ref={this.dropDown}>
          <li key="back" onClick={this.navigateBack}>
            <i>&lt; Back</i>
          </li>
          {menuSelections.map((suggestion, index) => {
            let className;

            // Flag the active suggestion with a class
            if (index === activeSuggestion) {
              //className = "suggestion-active";
            }

            return (
              <li
                className={className}
                key={suggestion}
                //onMouseDown={onClick}
                onClick={onClick}
                index={index}
              >
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    }

    //document.activeElement.name == this.props.fieldName &&
    if (
      (showSuggestions && userInput) ||
      (this.props.showSuggestions && this.state.isActive == true)
    ) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions category-mode">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li
                  className={className}
                  key={suggestion}
                  onMouseDown={onClick}
                >
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        if (!this.props.suggestions.includes(userInput)) {
          suggestionsListComponent = (
            <div className="no-suggestions">
              <em>No matching results.</em>
            </div>
          );
        }
      }
    }

    return (
      //Product field needs to grab size from reffile
      <div className="field-object form-group">
        <label htmlFor={this.props.fieldName}>Product</label>
        <input
          className="form-control input-lg"
          type="text"
          onChange={onChange}
          onKeyDown={(e) => {
            this.onKeyDown(e);
          }}
          onBlur={(e) => {
            this.onBlur(e);
          }}
          onFocus={(e) => {
            this.onFocus(e);
          }}
          value={userInput || this.props.currentProduct || ""}
          id={this.props.fieldName}
          name={this.props.fieldName}
          maxLength={this.props.maxLength}
          size={Math.round(this.props.maxLength * 0.5)}
          ref={this.firstInput}
          tabIndex="0"
          onDoubleClick={this.handleDoubleClick}
          autoComplete="none"
          readOnly={readOnly}
        />
        {suggestionsListComponent}
        <input
          type="text"
          autoComplete="on"
          value=""
          style={{
            display: "none",
            opacity: 0,
            position: "absolute",
            left: "-100000px",
          }}
          readOnly={true}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentProduct: state.refFileState.currentProduct,
  };
};

export default connect(mapStateToProps, null)(ProdXField);
