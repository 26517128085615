import React from "react";

import { connect } from "react-redux";
import { doAddNewLineItem } from "../actions/elog";
import ProdXField from "./ProdXField";
import ProductFields from "./ProductFields";

import {
  filterFieldMap,
  generateListX,
  generateEmployeeArray,
  getPageFromProduct,
  getProductDescription,
  getRoundTime,
} from "../services/Utilities";

import { EDIT_LINE_ITEM, NEW_LINE_ITEM } from "../constants/screenTypes";

class ProductEntry extends React.Component {
  constructor(props) {
    super(props);

    //state of autocomplete + productfields

    this.state = {
      //currentRefFile: this.props.currentRef,
      currentPage: null,
      //fieldMap: this.props.fieldMap,
      fieldValues: {},
      currentFieldMap: [],
      productStrings: [],
      selectedProduct: null,
      empMap: [],
      listX: {},
      prodMap: [],
      //screen: this.props.screen,
      pageTypes: [],
      productCategory: null,
      filteredProducts: [],
    };

    this.fieldRef = React.createRef();
    this.hiddenRef = React.createRef();
  }

  componentDidMount() {
    this.resetForm();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.currentLineItemId !== this.props.currentLineItemId) {
      this.resetForm();
    }
  }

  generateProductArray(productMap) {
    var productStrings = [];

    productMap.map((product) => {
      const productString = product.ProdCode + " - " + product.ProdDesc;
      productStrings.push(productString);
    });

    return productStrings.sort();
  }

  getCurrentRefFile = () => {
    const refFiles = this.props.refFiles;
    const refFileId = this.props.currentRefFileId;
    const refFile = Object.assign({}, refFiles[refFileId]);
    return refFile;
  };

  getCurrentFieldMap = () => {
    const refFile = this.getCurrentRefFile();
    return refFile.refFile.FMAPfile;
  };

  getCurrentProdMap = () => {
    const refFile = this.getCurrentRefFile();
    return refFile.refFile.PRODfile;
  };

  getCurrentPrefMap = () => {
    const refFile = this.getCurrentRefFile();
    return refFile.refFile.PREFfile;
  };

  getCurrentEmpMap = () => {
    const refFile = this.getCurrentRefFile();
    return refFile.refFile.EMPfile;
  };

  resetForm = () => {
    let pageTypes = this.getCurrentFieldMap().filter(
      (field) => field.FieldName.toUpperCase() == "PAGE" && field.PageNo != 0
    );
    const prodStrings = this.generateProductArray(this.getCurrentProdMap());
    const prefMap = this.getCurrentPrefMap();

    const listX = generateListX(prefMap);
    const roundTime = getRoundTime(prefMap);

    const empStrings = generateEmployeeArray(this.getCurrentEmpMap());

    const fieldMapClone = this.getCurrentFieldMap();

    this.setState({
      currentRefFile: this.props.currentRef,
      fieldMap: fieldMapClone,
      productStrings: prodStrings,
      currentProductDescription: null,
      empStrings: empStrings,
      listX: listX,
      roundTime: roundTime,
      screen: this.props.screen,
      fieldValues: {},
      prodMap: this.getCurrentProdMap(),
      pageTypes: pageTypes,
      currentPage: this.props.currentPage,
    });

    if (this.props.isEditing) {
      const currLineItem = this.getCurrentLineItem();
      this.changeProduct(currLineItem.product);
    }
  };

  getCurrentLineItem = () => {
    const elogs = this.props.elogs;
    const elogId = this.props.elogId;
    const lineItemId = this.props.lineItemId;
    const lineItem = Object.assign({}, elogs[elogId].lineItems[lineItemId]);
    return lineItem;
  };

  changeProduct = (selectedString) => {
    if (!selectedString) {
      return;
    }

    const splitString = selectedString.split(" - ");

    let obj = selectedString;

    if (splitString.length > 1) {
      obj = selectedString.split(" - ")[0];
    }

    const currentRefFile = Object.assign(
      {},
      this.props.refFiles[this.props.currentRefFileId]
    );
    if (currentRefFile && obj) {
      const pageNo = getPageFromProduct(this.getCurrentProdMap(), obj);
      const prodDesc = getProductDescription(this.getCurrentProdMap(), obj);
      const filteredFieldMap = filterFieldMap(
        pageNo,
        this.getCurrentFieldMap()
      );
      const currentProductFields = [...filteredFieldMap];

      let fieldArray = {};

      currentProductFields.map((field) => {
        if (field.FieldName == "Product") {
          fieldArray[field.FieldName] = obj;
        } else if (field.FieldName == "ProdDesc") {
          fieldArray[field.FieldName] = prodDesc;
        } else {
          fieldArray[field.FieldName] = field.Supplied;
        }
      });

      this.props.pageChange(pageNo, obj);

      const lineItem = {
        page: pageNo,
        fieldValues: fieldArray,
        product: obj,
        description: prodDesc,
      };

      //dispatchAction
      if (!this.props.isEditing) {
        this.props.onNewLineItem(lineItem);
      }

      this.setState({
        selectedProduct: obj,
        currentPage: pageNo,
        currentFieldMap: currentProductFields,
        fieldValues: fieldArray,
        currentProductDescription: prodDesc,
      });
    }
  };

  updateFieldValues = (fieldValues) => {
    this.setState({
      fieldValues: fieldValues,
    });
  };

  handleSubmit = () => {
    let obj = {
      currentPage: this.state.currentPage,
      fieldValues: this.state.fieldValues,
      selectedProduct: this.props.selectedProduct,
      productDescription: this.state.currentProductDescription,
    };
    this.props.onLineItemSubmission(obj);
  };

  // onFocus = () => {
  //   console.log('ProductEntry.js: onFocus()')
  //   //this.fieldRef.current.prodFieldRefs[1].current.focus();
  // }

  render() {
    //console.log('ProductEntry.js: render()')

    const selectedProduct = this.props.selectedProduct;

    const {
      screen,
      currentPage,
      productStrings,
      currentProductDescription,
      empStrings,
      listX,
      roundTime,
      prodMap,
      pageTypes,
    } = this.state;

    const currentFieldMap = [...this.state.currentFieldMap];

    const { refFiles, currentRefFileId } = this.props;

    const currentRefFile = Object.assign({}, refFiles[currentRefFileId]);

    let editing = null;

    if (screen === EDIT_LINE_ITEM) {
      const currLineItem = this.getCurrentLineItem();

      const _page = currLineItem.page;
      const _fieldMap = filterFieldMap(_page, currentRefFile.refFile.FMAPfile);

      const _selectedProduct = currLineItem.product;
      editing = currLineItem;

      return (
        <div className="product-entry-screen">
          <form id="product-entry-form">
            <div className="form-group">
              <label htmlFor="Product">Product:</label>
              <input
                readOnly
                defaultValue={_selectedProduct}
                className="form-control input-lg"
              />
            </div>

            <input
              type="text"
              autoComplete="on"
              value=""
              style={{
                display: "none",
                opacity: 0,
                position: "absolute",
                left: "-100000px",
              }}
              readOnly={true}
            />

            <ProductFields
              currentPage={_page}
              currentProd={currLineItem.product}
              fieldMap={_fieldMap}
              productDescription={currLineItem.description}
              updateFieldValues={this.updateFieldValues}
              employeeStrings={empStrings}
              listX={listX}
              roundTime={roundTime}
              prodMap={prodMap}
              //ref={this.fieldRef}
              onSubmit={this.props.newLineItemEvent}
              priorEntries={this.props.priorEntries}
              editing={editing}
            />
          </form>
        </div>
      );
    }

    return (
      <div className="product-entry-screen">
        <form id="product-entry-form">
          {screen === NEW_LINE_ITEM && (
            <>
              <ProdXField
                key={this.props.selectedProduct}
                value={this.props.selectedProduct}
                default={this.props.selectedProduct}
                suggestions={productStrings}
                onSelection={(obj) => {
                  this.changeProduct(obj);
                }}
                maxLength="10"
                //label='Product'
                allCaps="Y"
                showSuggestions={false}
                productCategories={pageTypes}
                productMap={prodMap}
                currentPage={currentPage}
              />

              <div className="hidden-ref-container">
                <input
                  tabIndex="0"
                  onFocus={() => this.onFocus()}
                  ref={this.hiddenRef}
                  className="hidden-ref-field"
                />
              </div>
            </>
          )}

          {screen !== EDIT_LINE_ITEM && currentPage && (
            <ProductFields
              currentPage={this.props.currentPage}
              currentProd={selectedProduct}
              fieldMap={currentFieldMap}
              productDescription={currentProductDescription}
              updateFieldValues={this.updateFieldValues}
              employeeStrings={empStrings}
              listX={listX}
              roundTime={roundTime}
              prodMap={prodMap}
              //ref={this.fieldRef}
              onSubmit={this.props.newLineItemEvent}
              priorEntries={this.props.priorEntries}
              editing={editing}
            />
          )}

          {/*          <div className='submit-button-container'>
            <Button variant='info' tabIndex='0' type="submit" value="Submit" onKeyDown={e => {this.handleTabSubmit(e)}}>New Line-Item</Button>
          </div>*/}
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onNewLineItem: (lineItem) => dispatch(doAddNewLineItem(lineItem)),
  };
};

const mapStateToProps = (state) => {
  return {
    elogs: state.elogState.elogs,
    //elog: state.elogState.elogs[state.elogState.currentElogId],
    refFiles: state.refFileState.refFiles,
    //refFile: state.refFileState.refFiles[state.refFileState.currentRefId],
    currentRefFileId: state.refFileState.currentRefId,
    //lineItem: state.elogState.elogs[state.elogState.currentElogId].lineItems[state.elogState.currentLineItemId],
    currentPage: state.refFileState.currentPage,
    selectedProduct: state.refFileState.currentProduct,
    //fieldMap: state.refFileState.refFiles[state.refFileState.currentRefId].refFile.FMAPfile,
    //prodMap: state.refFileState.refFiles[state.refFileState.currentRefId].refFile.PRODfile,
    //prefMap: state.refFileState.refFiles[state.refFileState.currentRefId].refFile.PREFfile,
    //empMap: state.refFileState.refFiles[state.refFileState.currentRefId].refFile.EMPfile,
    lineItemId: state.elogState.currentLineItemId,
    elogId: state.elogState.currentElogId,
    //this.setState({ selectedProduct: obj, currentPage: pageNo, currentFieldMap: currentProductFields, fieldValues: fieldArray, currentProductDescription: prodDesc });
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductEntry);

//export default ProductEntry;
