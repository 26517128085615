import { PlodDb } from "../services/DBService";
import { getApiHeaders, getApiUrl } from "../services/Utilities";

const API_URL = getApiUrl();

const uploadElogsGraphql = (token, query, vars) =>
  fetch(API_URL, {
    method: "POST",
    headers: getApiHeaders(token),
    body: JSON.stringify({ query, variables: vars }),
  })
    .then((res) => res.json())
    .then((res) => res);

const getPlodNoFromServer = (token) =>
  fetch(getApiUrl(), {
    method: "POST",
    headers: getApiHeaders(token),
    body: JSON.stringify({
      query: "query { get_next_company_plodno { result } }",
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      const plodNoArray = res?.data?.get_next_company_plodno;

      if (plodNoArray && plodNoArray.length > 0) {
        const plodNo = plodNoArray[0]?.result;
        if (plodNo) {
          return plodNo;
        }
      }
      return "";
    });

const fetchElogsFromIdb = () =>
  PlodDb.getAll("elogs").then((allElogs) => allElogs);

const saveElogToIdb = (payload) =>
  PlodDb.put("elogs", payload.elog, parseInt(payload.id));

const deleteElogIdb = (id) => PlodDb.delete("elogs", parseInt(id));

const emailElog = (query, variables, token) =>
  fetch(API_URL, {
    method: "POST",
    headers: getApiHeaders(token),
    body: JSON.stringify({ query, variables }),
  })
    .then((res) => res.json())
    .then((res) => res);

export {
  fetchElogsFromIdb,
  saveElogToIdb,
  deleteElogIdb,
  uploadElogsGraphql,
  getPlodNoFromServer,
  emailElog,
};
