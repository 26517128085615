//fetch from API
export const REFFILE_FETCH = "REFFILE_FETCH";
export const REFFILE_FETCH_ERROR = "REFFILE_FETCH_ERROR";
export const REFFILE_FETCH_SUCCESS = "REFFILE_FETCH_SUCCESS";

export const REFFILES_FROM_GRAPHQL = "REFFILES_FROM_GRAPHQL";

//load from local storage
export const REFFILE_LOAD = "REFFILE_LOAD";
export const REFFILE_LOAD_ERROR = "REFFILE_LOAD_ERROR";
export const REFFILE_LOAD_SUCCESS = "REFFILE_LOAD_SUCCESS";

export const REFFILE_SELECT = "REFFILE_SELECT";
export const REFFILE_DELETE = "REFFILE_DELETE";
export const REFFILE_DELETE_ALL = "REFFILE_DELETE_ALL";

export const REFFILE_BULK_ADD = "REFFILE_BULK_ADD";

export const REFFILE_DELETE_ALL_IDB_ERROR = "REFFILE_DELETE_ALL_IDB_ERROR";
export const REFFILE_DELETE_ALL_IDB_SUCCESS = "REFFILE_DELETE_ALL_IDB_SUCCESS";

export const REFFILE_DELETE_IDB_SUCCESS = "REFFILE_DELETE_IDB_SUCCESS";
export const REFFILE_DELETE_IDB_ERROR = "REFFILE_DELETE_IDB_ERROR";

export const REFFILES_FROM_IDB = "REFFILES_FROM_IDB";
export const REFFILES_FROM_IDB_SUCCESS = "REFFILES_FROM_IDB_SUCCESS";
export const REFFILES_FROM_IDB_ERROR = "REFFILES_FROM_IDB_ERROR";

export const REFFILE_TO_IDB = "REFFILE_TO_IDB";
export const REFFILE_TO_IDB_SUCCESS = "REFFILE_TO_IDB_SUCCESS";
export const REFFILE_TO_IDB_ERROR = "REFFILE_TO_IDB_ERROR";

export const ELOG_DELETE_IDB_SUCCESS = "ELOG_DELETE_IDB_SUCCESS";
export const ELOG_DELETE_IDB_ERROR = "ELOG_DELETE_IDB_ERROR";

export const CHANGE_PAGE = "CHANGE_PAGE";
export const CHANGE_PRODUCT = "CHANGE_PRODUCT";

export const ELOG_DELETE = "ELOG_DELETE";
export const ELOG_NEW = "ELOG_NEW";
export const LI_MODIFIED = "LI_MODIFIED";
export const ELOG_SELECT = "ELOG_SELECT";

export const CHANGE_CURRENT_LI = "CHANGE_CURRENT_LI";
export const NEW_LI = "CREATE_LI";
export const DELETE_LI = "DELETE_LI";

export const HEADER_MODIFIED = "HEADER_MODIFIED";

export const CHANGE_FONT_SIZE = "CHANGE_FONT_SIZE";

export const ELOGS_TO_IDB = "ELOGS_TO_IDB";
export const ELOGS_TO_IDB_SUCCESS = "ELOGS_TO_IDB_SUCCESS";
export const ELOGS_TO_IDB_ERROR = "ELOGS_TO_IDB_ERROR";

export const ELOG_TO_IDB = "ELOG_TO_IDB";
export const ELOG_TO_IDB_ERROR = "ELOG_TO_IDB_ERROR";
export const ELOG_TO_IDB_SUCCESS = "ELOG_TO_IDB_SUCCESS";

export const ELOGS_FROM_IDB = "ELOGS_FROM_IDB";
export const ELOGS_FROM_IDB_SUCCESS = "ELOGS_FROM_IDB_SUCCESS";
export const ELOGS_FROM_IDB_ERROR = "ELOGS_FROM_IDB_ERROR";

export const ELOGS_TO_GRAPHQL = "ELOGS_TO_GRAPHQL";
export const ELOGS_TO_GRAPHQL_ERROR = "ELOGS_TO_GRAPHQL_ERROR";
export const ELOGS_TO_GRAPHQL_SUCCESS = "ELOGS_TO_GRAPHQL_SUCCESS";

export const ELOG_TO_GRAPHQL_CANCEL = "ELOG_TO_GRAPHQL_CANCEL";

export const ELOG_TO_GRAPHQL = "ELOG_TO_GRAPHQL";
export const ELOG_TO_GRAPHQL_ERROR = "ELOG_TO_GRAPHQL_ERROR";
export const ELOG_TO_GRAPHQL_SUCCESS = "ELOG_TO_GRAPHQL_SUCCESS";

export const ELOG_LOAD = "ELOG_LOAD";
export const ELOG_COMPLETE = "ELOG_COMPLETE";

export const SET_ELOG_ANEW = "SET_ELOG_ANEW";

export const ELOG_TO_RECIPIENTS = "ELOG_TO_RECIPIENTS";

export const UPDATE_ELOG_RECIPIENTS = "UPDATE_ELOG_RECIPIENTS";
export const SEND_ELOG_PDF = "SEND_ELOG_PDF";

export const UPDATE_ELOG_HTML = "UPDATE_ELOG_HTML";

export const PRIORENTRIES_FROM_IDB = "PRIORENTRIES_FROM_IDB";
export const PRIORENTRIES_FROM_IDB_SUCCESS = "PRIORENTRIES_FROM_IDB_SUCCESS";
export const PRIORENTRIES_FETCH_ERROR = "PRIORENTRIES_FETCH_ERROR";

export const PRIORENTRIES_TO_IDB = "PRIORENTRIES_TO_IDB";
export const PRIORENTRIES_TO_IDB_SUCCESS = "PRIORENTRIES_TO_IDB_SUCCESS";
export const PRIORENTRIES_SAVE_ERROR = "PRIORENTRIES_SAVE_ERROR";

export const PRIORENTRY_UPDATE = "PRIORENTRY_UPDATE";
export const PRIORENTRY_BATCH_UPDATE = "PRIORENTRY_BATCH_UPDATE";

export const DELETE_ALL_NONLOCAL_REFS = "DELETE_ALL_NONLOCAL_REFS";

//save application state to indexed database
export const SAVE_TO_IDB = "SAVE_TO_IDB";

export const CHANGE_SCREEN = "CHANGE_SCREEN";

export const SHOW_LOGIN_PROMPT = "SHOW_LOGIN_PROMPT";
export const CLOSE_LOGIN_PROMPT = "CLOSE_LOGIN_PROMPT";

export const SHOW_SHIFT_INFO_PROMPT = "SHOW_SHIFT_INFO_PROMPT";
export const CLOSE_SHIFT_INFO_PROMPT = "CLOSE_SHIFT_INFO_PROMPT";

export const SHOW_RECIPIENT_PROMPT = "SHOW_RECIPIENT_PROMPT";
export const CLOSE_RECIPIENT_PROMPT = "CLOSE_RECIPIENT_PROMPT";

//AUTH
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const UPDATE_AUTH = "UPDATE_AUTH";
export const UPDATE_TOKEN = "UPDATE_TOKEN";
export const UPDATE_USER_ID = "UPDATE_USER_ID";
export const UPDATE_USER_EMAIL_PERMISSIONS = "UPDATE_USER_EMAIL_PERMISSIONS";

export const AUTH_REDIRECT = "AUTH_REDIRECT";

export const IMPORT_REF_STATE = "IMPORT_REF_STATE";
export const IMPORT_ELOG_STATE = "IMPORT_ELOG_STATE";
export const IMPORT_PRIOR_ENTRY_STATE = "IMPORT_PRIOR_ENTRY_STATE";

export const UPLOAD_ERROR = "UPLOAD_ERROR";

export const REFFILE_COMBINATIONS_FROM_GRAPHQL =
  "REFFILE_COMBINATIONS_FROM_GRAPHQL";
export const REFFILE_COMBINATIONS_FROM_GRAPHQL_SUCCESS =
  "REFFILE_COMBINATIONS_FROM_GRAPHQL_SUCCESS";
export const REFFILE_COMBINATIONS_FROM_GRAPHQL_ERROR =
  "REFFILE_COMBINATIONS_FROM_GRAPHQL_ERROR";

//ADMIN PANEL
export const CHANGE_ADMIN_ELOG_FILTER = "CHANGE_ELOG_FILTER";
export const DELETE_ADMIN_ELOG_FILTER = "DELETE_ELOG_FILTER";
export const SAVE_ELOG_FILTERS_IDB = "SAVE_ELOG_FILTERS_IDB";
export const SAVE_ELOG_FILTERS_IDB_SUCCESS = "SAVE_ELOG_FILTERS_IDB_SUCCESS";
export const SAVE_ELOG_FILTERS_IDB_ERROR = "SAVE_ELOG_FILTERS_IDB_ERROR";
export const LOAD_ELOG_FILTERS_IDB = "LOAD_ELOG_FILTERS_IDB";
export const LOAD_ELOG_FILTERS_IDB_SUCCESS = "LOAD_ELOG_FILTERS_IDB_SUCCESS";
export const LOAD_ELOG_FILTERS_IDB_ERROR = "LOAD_ELOG_FILTERS_IDB_ERROR";
