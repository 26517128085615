import React from "react";
import { connect } from "react-redux";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { doImportElogState } from "../actions/elog";
import { doImportRefState } from "../actions/refFile";
import { doImportPriorEntryState } from "../actions/priorEntry";

class RefFileViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ref: {},
    };
  }

  componentDidMount() { }

  componentDidUpdate() { }

  onStateImport = (event) => {
    let file = event.target.files[0];
    let reader = new FileReader();

    reader.onload = (event) => {
      const stateString = event.target.result;
      const stateObject = JSON.parse(stateString);

      // elogState: elogReducer,
      // refFileState: refFileReducer,
      // priorEntryState: priorEntryReducer,

      this.props.onUploadElogState(stateObject.elogState);
      this.props.onUploadRefState(stateObject.refFileState);
      this.props.onUploadPriorEntryState(stateObject.priorEntryState);
    };

    reader.readAsText(file);
  };

  render() {
    const { fieldMap, prodMap, empMap, prefMap } = this.props;

    return (
      <>
        <div className="debug">
          <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
            <Tab eventKey="fieldMap" title="FieldMap">
              <pre>{JSON.stringify(fieldMap, null, 4)}</pre>
            </Tab>
            <Tab eventKey="prodMap" title="ProdMap">
              <pre>{JSON.stringify(prodMap, null, 4)}</pre>
            </Tab>
            <Tab eventKey="empMap" title="EmpMap">
              <pre>{JSON.stringify(empMap, null, 4)}</pre>
            </Tab>
            <Tab eventKey="prefMap" title="PrefMap">
              <pre>{JSON.stringify(prefMap, null, 4)}</pre>
            </Tab>
          </Tabs>
        </div>

        <div className="state-upload">
          <input
            type="file"
            accept=".dat"
            id="upload-state"
            name="upload-state"
            onChange={this.onStateImport}
          />
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onUploadElogState: (payload) => dispatch(doImportElogState(payload)),
    onUploadRefState: (payload) => dispatch(doImportRefState(payload)),
    onUploadPriorEntryState: (payload) =>
      dispatch(doImportPriorEntryState(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    fieldMap:
      state.refFileState?.refFiles[state.refFileState?.currentRefId]?.refFile
        ?.FMAPfile,
    prodMap:
      state.refFileState?.refFiles[state.refFileState?.currentRefId]?.refFile
        ?.PRODfile,
    prefMap:
      state.refFileState?.refFiles[state.refFileState?.currentRefId]?.refFile
        ?.PREFfile,
    empMap:
      state.refFileState?.refFiles[state.refFileState?.currentRefId]?.refFile
        ?.EMPfile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RefFileViewer);
