import React from "react";
import { connect } from "react-redux";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "./Autocomplete";

import {
  calculateDuration,
  getSprFields,
  generateListX,
  getRoundTime,
  isNumeric,
  logErrorToServer,
} from "../services/Utilities";
import { doModifyHeader } from "../actions/elog";

class HeaderPage extends React.Component {
  fieldRefs = [];

  constructor(props) {
    super(props);

    const refFiles = Object.assign({}, this.props.refFiles);
    const currRefId = this.props.refFileId;
    const currentRefFile = refFiles[currRefId];

    const fieldMap = currentRefFile.refFile.FMAPfile;
    const prefMap = currentRefFile.refFile.PREFfile;

    const headerFieldMap = fieldMap.filter(
      (field) => field.PageNo == 0 && field.FieldName !== "PAGE"
    );

    const listX = generateListX(prefMap);
    const roundTime = getRoundTime(prefMap);

    //const elogs = this.props.elogs
    //const selectedElog = this.props.selectedElog

    let alteredFieldMap = [];

    if (headerFieldMap.length > 0) {
      alteredFieldMap = headerFieldMap.map((field, index) => {
        this.fieldRefs[index] = React.createRef();

        if (field.FieldType.toUpperCase() === "PLODX") {
          //if (!elogs[selectedElog].header.PlodNo) {
          return {
            ...field,
            Supplied: this.getDefaultPlodNo(),
          };
          //}
        }
        return field;
      });
    }

    this.state = {
      fieldValues: {},
      fieldMap: alteredFieldMap,
      listX: listX,
      roundTime: roundTime,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      JSON.stringify(prevState.fieldValues) !==
      JSON.stringify(this.state.fieldValues)
    ) {
      this.props.onModifyHeader({
        data: this.state.fieldValues,
        id: this.props.selectedElog,
      });
    }
  }

  componentDidMount() {
    let fieldArray = {};
    const fieldMap = [...this.state.fieldMap];
    const priorEntries = Object.assign({}, this.props.priorEntries); //this.props.priorEntries
    const valuesForEditing = Object.assign({}, this.props.existingValues);

    //if editing header
    if (Object.keys(valuesForEditing).length > 0) {
      fieldMap.map((field) => {
        const fieldName = field.FieldName;
        const entryVal = valuesForEditing[fieldName];
        let supplied = field.Supplied;

        if (entryVal) {
          supplied = entryVal;
        }

        fieldArray[fieldName] = supplied;
      });

      this.setState({
        fieldValues: fieldArray,
      });
    }

    //if creating new elog
    else {
      fieldMap.map((field) => {
        const fieldType = field.FieldType;
        const fieldName = field.FieldName;
        const defaultType = field.DefaultType;
        let supplied = field.Supplied;

        if (fieldType === "DATEX") {
          supplied = Date.now();
        }

        if (fieldType === "PLODX") {
          field.Required = "Y";
        }

        if (Object.keys(priorEntries).length > 0 && defaultType) {
          const defaultTypeCaps = defaultType.toUpperCase();

          if (defaultTypeCaps === "P" || defaultTypeCaps === "PRIORENTRY") {
            const priorEntry = priorEntries[fieldName];

            if (priorEntry) {
              supplied = priorEntry;
            }
          }
        }
        fieldArray[fieldName] = supplied;
      });

      this.setState({ fieldValues: fieldArray }, function () {
        this.applyAllSprs(0, fieldMap); //hardcoded 0 for header page
      });
    }

    //Focuses on first field
    if (this.fieldRefs[0].current) {
      this.fieldRefs[0].current.focus();
      this.fieldRefs[0].current.scrollIntoView();
      this.fieldRefs[0].current.select();
    }
  }

  getDefaultPlodNo = () => {
    const today = new Date();

    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    const yyyy = today.getFullYear();

    const hour = today.getHours();
    const min = today.getMinutes();
    const sec = today.getSeconds();

    return `${hour}:${min}:${sec}-${dd}-${mm}-${yyyy}`;
  };

  applyAllSprs(page, fieldMap) {
    const sprFields = getSprFields(page, fieldMap);

    let lastSprRun;

    if (sprFields > 0) {
      sprFields.map(async (field) => {
        const fieldsAfterSprs = this.applySprs(field, lastSprRun);

        if (fieldsAfterSprs) {
          lastSprRun = fieldsAfterSprs;
        }
      });

      this.setState({ fieldValues: lastSprRun });
    }
  }

  applySprs = (field, currentFields) => {
    let currentFieldState = Object.assign({}, this.state.fieldValues);

    if (currentFields) {
      currentFieldState = Object.assign({}, currentFields);
    }

    const fieldMap = this.state.fieldMap;
    let updatedFields = Object.assign({}, currentFieldState);

    if (!field) {
      return;
    }

    let equations = [];

    try {
      if (Object.prototype.hasOwnProperty.call(field, "SpecProc")) {
        let specProc = field.SpecProc;

        if (specProc) {
          //remove whitespace
          specProc = specProc.replace(/\s+/g, "");
          if (!specProc.includes("=")) {
            return;
          }

          equations = specProc.split(";");

          equations.map((equation) => {
            if (equation) {
              const components = equation.split("=");
              const left = components[0];
              const right = components[1];

              const rightComponents = right.split(/[/*+-]/);

              let rightSubstituted = right;
              let isTime = false;

              if (rightComponents.length === 1 && rightComponents[0] === "") {
                currentFieldState[left] = "";
                updatedFields = Object.assign({}, currentFieldState);
              } else {
                let equationHasEmptyVal = false;

                if (!equationHasEmptyVal) {
                  rightComponents.map((fieldName) => {
                    let val;
                    if (fieldName.includes("@")) {
                      const priorKey = fieldName.replace("@", "");
                      val = this.props.priorEntries[priorKey];
                    } else {
                      const numeric = isNumeric(fieldName);

                      if (numeric) {
                        val = fieldName;
                      } else {
                        val = currentFieldState[fieldName];
                      }
                    }

                    if ((!val && val !== 0) || val === "NaN") {
                      equationHasEmptyVal = true;
                    } else {
                      if (
                        fieldMap
                          .find((field) => field.FieldName === fieldName)
                          ?.FieldType?.toUpperCase() === "TIMEX"
                      ) {
                        isTime = true;
                      }
                      rightSubstituted = rightSubstituted.replace(
                        fieldName,
                        val.toString()
                      );
                    }
                  });

                  if (!equationHasEmptyVal) {
                    let result = null;

                    if (isTime) {
                      const timeVals = rightSubstituted.split("-");
                      result = calculateDuration(timeVals[1], timeVals[0]);
                    } else {
                      if (rightSubstituted.includes("--")) {
                        result = eval(rightSubstituted.replace("--", "+"));
                      } else {
                        result = eval(rightSubstituted);
                      }
                    }

                    const leftField = fieldMap.find(
                      (field) => field.FieldName === left
                    );

                    if (leftField && leftField.Decimals) {
                      result = parseFloat(result).toFixed(leftField.Decimals);
                    }

                    currentFieldState[left] = result;
                    updatedFields = Object.assign({}, currentFieldState);
                  }
                }
              }
            }
          });
          return updatedFields;
        }
      }

      return updatedFields;
    } catch (e) {
      logErrorToServer(-1, e, {}, "HeaderPage.js: applySprs", {
        fieldMap,
        updatedFields,
      });
    }
  };

  onTextFieldKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.props.onSubmission();
    }
  };

  roundTimeTo = (time, roundTime) => {
    const hours = time.substring(0, 2);
    const minutes = time.substring(2, 4);
    const roundedTo = 60 * roundTime;

    let timeToReturn = new Date(1776, 6, 4, hours, minutes, 0, 0);

    timeToReturn.setMilliseconds(
      Math.round(timeToReturn.getMilliseconds() / 1000) * 1000
    );
    timeToReturn.setSeconds(
      Math.round(timeToReturn.getSeconds() / 60) * 60
    );
    timeToReturn.setMinutes(
      Math.round(timeToReturn.getMinutes() / roundedTo) * roundedTo
    );

    const timeString =
      String(timeToReturn.getHours()).padStart(2, "0") +
      String(timeToReturn.getMinutes()).padStart(2, "0");
    return timeString;
  }

  incrementFocus = (currentFieldName = null) => {
    const fieldRefCount = this.fieldRefs.length - 1;
    const fieldMap = this.state.fieldMap;

    let currentFocus = fieldMap.findIndex(
      (field) => field.FieldName == document.activeElement.name
    );

    if (currentFieldName) {
      currentFocus = fieldMap.findIndex(
        (field) => field.FieldName == currentFieldName
      );
    }

    for (let i = currentFocus + 1; i <= fieldRefCount; i++) {
      if (
        (fieldMap[i].TabStop == "Y" || fieldMap[i].TabStop == "y") &&
        this.fieldRefs[i].current
      ) {
        this.fieldRefs[i].current.focus();
        this.fieldRefs[i].current.scrollIntoView();
        return;
      }
    }
  };

  handleInputChange = (event, allCaps) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;

    if (allCaps) {
      event.target.value = value.toUpperCase();
      value = value.toUpperCase();
    }

    var fieldValues = Object.assign({}, this.state.fieldValues);
    fieldValues[name] = value;

    this.setState({
      fieldValues: fieldValues,
    });
  };

  handleListXChange = (fieldName, value) => {
    this.incrementFocus(fieldName);

    let fieldValues = Object.assign({}, this.state.fieldValues);
    fieldValues[fieldName] = value;

    this.setState({
      fieldValues: fieldValues,
    });
  };

  handleDateChange = (date, fieldName) => {
    let fieldValues = Object.assign({}, this.state.fieldValues);
    fieldValues[fieldName] = date;

    this.setState({
      fieldValues: fieldValues,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmission();
  };

  handleBlur = (type, event, field) => {
    let fieldValues;

    if (field.Required) {
      if (event.target.value) {
        if (event.target.classList.contains("is-invalid")) {
          event.target.classList.remove("is-invalid");
        }
      } else {
        //alert(`You must enter a value for ${field.Caption}`)
        //this.fieldRefs[0].current.focus();
        if (!event.target.classList.contains("is-invalid")) {
          event.target.classList.add("is-invalid");
        }
      }
    }

    switch (type) {
      case "TIME":
        fieldValues = this.formatTime(event, field);
        break;

      case "DECIMAL":
        fieldValues = this.formatDecimal(event, field);
        break;

      default:
        fieldValues = Object.assign({}, this.state.fieldValues);
        break;
    }

    const fieldsAfterSprs = this.applySprs(field, fieldValues);
    this.setState({ fieldValues: fieldsAfterSprs });
  };

  formatDecimal = (event, field) => {
    const fieldName = field.FieldName;
    const decimalPlaces = field.Decimals;

    let currentFieldState = Object.assign({}, this.state.fieldValues);
    let fieldVal = event.target.value;

    if (fieldVal) {
      currentFieldState[fieldName] = parseInt(fieldVal).toFixed(decimalPlaces);
    }

    return currentFieldState;
  };

  formatTime = (event, field) => {
    const fieldName = field.FieldName;
    let currentFieldState = Object.assign({}, this.state.fieldValues);
    let fieldVal = event.target.value;

    if (fieldVal.length == 1) {
      currentFieldState[fieldName] = "0" + fieldVal + "00";
    } else if (fieldVal.length == 2) {
      currentFieldState[fieldName] = fieldVal + "00";
    } else if (fieldVal.length == 3) {
      currentFieldState[fieldName] = "0" + fieldVal;
    } else {
      currentFieldState[fieldName] = fieldVal;
    }

    if (currentFieldState[fieldName] == "2400") {
      currentFieldState[fieldName] = "0000";
    }

    if (this.state.roundTime) {
      const roundTime = this.state.roundTime;

      currentFieldState[fieldName] = this.roundTimeTo(
        currentFieldState[fieldName],
        roundTime
      );
    }

    if (
      Object.prototype.hasOwnProperty.call(currentFieldState, "Hours") &&
      currentFieldState["TimeStart"] &&
      currentFieldState["TimeFinish"]
    ) {
      currentFieldState["Hours"] = calculateDuration(
        currentFieldState["TimeStart"],
        currentFieldState["TimeFinish"]
      );
    }

    return currentFieldState;
  };

  render() {
    const fieldMap = this.state.fieldMap;
    const listX = this.state.listX;

    if (this.props.currentPage !== 0) {
      return <></>;
    }

    if (fieldMap.length < 1) {
      return <p>no fieldMap</p>;
    }

    if (!listX) {
      return <p>No listx</p>;
    }

    return (
      <div className="header-entry-form" key={fieldMap}>
        <form id="header-page-form" onSubmit={this.handleSubmit}>
          <input
            type="text"
            autoComplete="on"
            value=""
            style={{
              display: "none",
              opacity: 0,
              position: "absolute",
              left: "-100000px",
            }}
            readOnly={true}
          />
          {fieldMap.map((field, index) => {
            let tabIndex = -1;
            let allCaps = false;
            let newLine = (
              <React.Fragment key={"newline-" + index}></React.Fragment>
            );

            if (field.TabStop == "y" || field.TabStop == "Y") {
              tabIndex = 0;
            }

            if (field.AllCaps) {
              allCaps = true;
            }

            if (field.NewLine) {
              newLine = (
                <React.Fragment key={"hr-" + index}>
                  <hr />
                </React.Fragment>
              );
            }

            if (field.FieldType == "NoteX") {
              return (
                <div
                  key={"field-" + index}
                  className="form-group paragraph-entry"
                >
                  <label htmlFor={field.FieldName}>{field.Caption}:</label>
                  <textarea
                    key={index}
                    className="form-control input-lg"
                    type="text"
                    id={field.FieldName}
                    name={field.FieldName}
                    onChange={(evt) => {
                      this.handleInputChange(evt, allCaps);
                    }}
                    tabIndex={tabIndex}
                    onKeyDown={this.onTextFieldKeyDown}
                    ref={this.fieldRefs[index]}
                    value={this.state.fieldValues[field.FieldName]}
                    onFocus={(e) => e.target.select()}
                    onBlur={(e) => {
                      this.handleBlur("OTHER", e, field);
                    }}
                  />
                </div>
              );
            } else if (field.FieldType == "LISTX") {
              let values = listX[field.FieldName];

              return (
                <React.Fragment key={"field-" + index}>
                  {newLine}
                  <Autocomplete
                    key={this.state.fieldValues[field.FieldName]}
                    suggestions={values}
                    showSuggestions={true}
                    onSelection={(selection) => {
                      this.handleListXChange(field.FieldName, selection);
                    }}
                    maxLength={field.MaxLen}
                    size={Math.round(field.MaxLen * 0.5)}
                    label={field.Caption}
                    fieldName={field.FieldName}
                    tabIndex={tabIndex}
                    value={this.state.fieldValues[field.FieldName]}
                    default={this.state.fieldValues[field.FieldName]}
                    onFocus={(e) => e.target.select()}
                    onBlur={(e) => {
                      this.handleBlur("OTHER", e, field);
                    }}
                  />
                </React.Fragment>
              );
            } else if (field.FieldType.toUpperCase() == "DATEX") {
              if (this.state.fieldValues[field.FieldName]) {
                return (
                  <React.Fragment key={"field-" + index}>
                    {newLine}
                    <div className="form-group date-select">
                      <label htmlFor={field.FieldName}>{field.Caption}:</label>
                      <DatePicker
                        key={index}
                        className="form-control input-lg"
                        id={field.FieldName}
                        name={field.FieldName}
                        dateFormat="dd/MM/yyyy"
                        selected={this.state.fieldValues[field.FieldName]}
                        onChange={(date) =>
                          this.handleDateChange(date, field.FieldName)
                        }
                        tabIndex={-1}
                        ref={this.fieldRefs[index]}
                      />
                    </div>
                  </React.Fragment>
                );
              }
            } else if (field.FieldType == "PLODX") {
              return (
                <React.Fragment key={"field-" + index}>
                  {newLine}
                  <div className="form-group">
                    <label htmlFor={field.FieldName}>{field.Caption}:</label>
                    <input
                      key={index}
                      className="form-control input-lg"
                      type="text"
                      id={field.FieldName}
                      name={field.FieldName}
                      defaultValue={this.state.fieldValues[field.FieldName]}
                      onChange={(evt) => {
                        this.handleInputChange(evt, allCaps);
                      }}
                      maxLength={20} //field.MaxLen}
                      size={field.MaxLen}
                      ref={this.fieldRefs[index]}
                      tabIndex={tabIndex}
                      onFocus={(e) => e.target.select()}
                      onBlur={(e) => {
                        this.handleBlur("OTHER", e, field);
                      }}
                      autoComplete="none"
                    />
                  </div>
                </React.Fragment>
              );
            } else if (field.FieldType == "TIMEX") {
              return (
                <React.Fragment key={"field-" + index}>
                  {newLine}
                  <div className="form-group">
                    <label htmlFor={field.FieldName}>{field.Caption}:</label>
                    <input
                      key={index}
                      className="form-control input-lg number-input"
                      type="number"
                      id={field.FieldName}
                      name={field.FieldName}
                      onChange={this.handleInputChange}
                      maxLength={field.MaxLen}
                      min="0"
                      max="2400"
                      onBlur={(e) => {
                        this.handleBlur("TIME", e, field);
                      }}
                      tabIndex={tabIndex}
                      ref={this.fieldRefs[index]}
                      value={this.state.fieldValues[field.FieldName]}
                      onFocus={(e) => e.target.select()}
                      autoComplete="none"
                    />
                  </div>
                </React.Fragment>
              );
            } else if (field.FieldType == "9") {
              return (
                <React.Fragment key={"field-" + index}>
                  {newLine}
                  <div className="form-group">
                    <label htmlFor={field.FieldName}>{field.Caption}:</label>
                    <input
                      key={index}
                      className="form-control input-lg number-input"
                      type="number"
                      id={field.FieldName}
                      name={field.FieldName}
                      onChange={this.handleInputChange}
                      maxLength={field.MaxLen}
                      size={field.MaxLen}
                      min={-1 * Math.pow(10, field.MaxLen - 1)}
                      max={Math.pow(10, field.MaxLen - 1)}
                      tabIndex={tabIndex}
                      ref={this.fieldRefs[index]}
                      onBlur={(e) => {
                        this.handleBlur("DECIMAL", e, field);
                      }}
                      value={this.state.fieldValues[field.FieldName]}
                      autoComplete="none"
                      onFocus={(e) => e.target.select()}
                    />
                  </div>
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment key={"field-" + index}>
                  {newLine}
                  <div className="form-group">
                    <label htmlFor={field.FieldName}>{field.Caption}:</label>
                    <input
                      key={index}
                      className="form-control input-lg"
                      type="text"
                      id={field.FieldName}
                      name={field.FieldName}
                      defaultValue={this.state.fieldValues[field.FieldName]}
                      onChange={(evt) => this.handleInputChange(evt, allCaps)}
                      maxLength={field.MaxLen}
                      size={field.MaxLen}
                      tabIndex={tabIndex}
                      ref={this.fieldRefs[index]}
                      onFocus={(e) => e.target.select()}
                      onBlur={(e) => {
                        this.handleBlur("OTHER", e, field);
                      }}
                      autoComplete="none"
                    />
                  </div>
                </React.Fragment>
              );
            }
          })}
        </form>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onModifyHeader: (payload) => dispatch(doModifyHeader(payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    priorEntries: state.priorEntryState.priorEntries,
    elogs: state.elogState.elogs,
    selectedElog: state.elogState.currentElogId,
    refFiles: state.refFileState.refFiles,
    refFileId: state.refFileState.currentRefId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderPage);
