import { call, put, select, take } from "redux-saga/effects";
import { doShowShiftInfoPrompt } from "../actions/global";
import {
  //doFetchRefFileSuccess,
  doFetchErrorRefFile,
  doGetRefFilesIdbSuccess,
  doSaveRefFileSuccess,
  doSaveRefFileError,
  doGetRefFilesIdbError,
  doDeleteRefFileSuccess,
  doDeleteRefFileError,
  // doSaveRefFile,
  // doLoadRefFile,
  doFetchRefCombinationsSuccess,
  doDeleteAllRefFilesSuccess,
  doDeleteAllRefFilesError,
  doDeleteAllRefs,
  doAddBulkRefFiles,
  doFetchRefFileSuccess,
  doSelectRef,
} from "../actions/refFile";

import {
  fetchRefFilesFromIdb,
  saveRefFileToIdb,
  deleteRefFileIdb,
  fetchRefsGraphql,
  fetchRefCombinationsGraphql,
  deleteAllRefFilesIdb,
  saveBulkRefFiles,
} from "../api/refFile";
import { generateElogForExport, getRefFileIdForElog } from "../services/Utilities";

const getRefFiles = (state) => state.refFileState?.refFiles;
const getElogs = (state) => state.elogState?.elogs;
const getCurrentElogId = (state) => state.elogState?.currentElogId;

// When elogs are successfully fetched from IDB, we get the selected elog, and select suitable ref
function* handleRefFileSelectOnElogLoad(action) {

  //make sure this has triggered before continuing. Else we will have no refs to select from.
  yield take(["REFFILES_FROM_IDB_SUCCESS"]);

  if (action?.elogs?.length > 0) {

    const largestId = Math.max(...action.elogs.map(elog => elog.id));

    const refFiles = yield select(getRefFiles);
    const elog = action.elogs.find((obj) => obj.id == largestId)
    const elogData = generateElogForExport(elog, 'json');

    const refId = getRefFileIdForElog(elogData, elog?.refFile, refFiles)

    if (refId !== null) {
      yield put(doSelectRef(refId));
    }
  }

}

// When elogs are successfully fetched from IDB, we get the selected elog, and select suitable ref
function* handleRefFileSelectOnRefSync() {

  const elogs = yield select(getElogs);
  const currentElogId = yield select(getCurrentElogId);

  if (Object.keys(elogs)?.length > 0) {

    const elog = elogs[currentElogId];
    const elogData = generateElogForExport(elog, 'json');
    const refFiles = yield select(getRefFiles);
    const refId = getRefFileIdForElog(elogData, elog?.refFile, refFiles)

    if (refId != null) {
      yield put(doSelectRef(refId));
    }
  }

}

function* handleFetchRefCombinationsGraphql(action) {
  try {
    const result = yield call(fetchRefCombinationsGraphql, action.token);

    if (result && result.length > 0) {
      const uniqueJobs = [...new Set(result.map((item) => item.ref_file_job))]
        .filter((job) => job != null)
        .sort();
      const uniqueRigs = [...new Set(result.map((item) => item.ref_file_rig))]
        .filter((rig) => rig != null)
        .sort();
      const uniqueShifts = [
        ...new Set(result.map((item) => item.ref_file_shift)),
      ]
        .filter((shift) => shift != null)
        .sort();

      const shiftDetails = {
        jobs: uniqueJobs,
        rigs: uniqueRigs,
        shifts: uniqueShifts,
      };

      yield put(doFetchRefCombinationsSuccess(shiftDetails));
      yield put(doShowShiftInfoPrompt());

      //yield put(doFetchRefFileSuccess());
    } else {
      //yield put(doFetchErrorRefFile(result));
    }
  } catch (error) {
    yield put(doFetchErrorRefFile(error));
  }
}

function* handleFetchRefsGraphql(action) {
  try {
    //const refFiles = yield select(getRefFiles);
    const nextRefId = parseInt(action.newId);
    //yield put(doFetchRefFileSuccess());

    const result = yield call(
      fetchRefsGraphql,
      action.token,
      action.shiftDetails
    );

    const refResults = result?.data?.ref_files;

    if (refResults && refResults.length > 0) {

      yield call(deleteAllRefs);

      //yield all(
      //Object.values(refFiles).map((ref) => {
      //if (!ref.localUpload) { //won't delete locally uploaded ref files
      //return call(delRef, parseInt(ref.id));
      //}
      //})
      //);

      // yield all(
      //   refResults.map((ref, index) => {
      //     const newId = (nextRefId + index).toString();
      //     let refFile = {
      //       refFile: ref.ref_file_data,
      //       name: ref.ref_file_name,
      //       downloaded: ref.created_at,
      //       id: newId,
      //     };
      //     return call(addRef, refFile);
      //   })
      // );

      // console.log("REF FILE SHOULD BE SUCCESSFUL")
      const newRefState = {};

      refResults.forEach((ref, index) => {
        const newId = (nextRefId + index).toString();
        let refFile = {
          refFile: ref.ref_file_data,
          name: ref.ref_file_name,
          downloaded: ref.updated_at,
          id: newId,
        };
        newRefState[newId] = refFile;
      });

      yield put(doAddBulkRefFiles({ refFiles: newRefState, selectedRef: nextRefId }))

      yield put(doFetchRefFileSuccess());
    } else {
      yield put(doFetchErrorRefFile(result));
    }

  } catch (error) {
    yield put(doFetchErrorRefFile(error));
  }

}


// function* delRef(id) {
//   yield put(doDeleteRef(id));
// }

function* deleteAllRefs() {
  yield put(doDeleteAllRefs())
}

// function* addRef(ref) {
//   //yield put(doFetchRefFileSuccess(ref));
//   yield put(doLoadRefFile(ref));
//   yield put(doSaveRefFile(ref));
// }

function* handleFetchRefFilesIdb() {
  try {
    const result = yield call(fetchRefFilesFromIdb);
    yield put(doGetRefFilesIdbSuccess(result));
  } catch (error) {
    yield put(doGetRefFilesIdbError(error));
  }
}

function* handleSaveBulkRefFiles(action) {
  try {
    yield call(saveBulkRefFiles, action.payload.refFiles);
  } catch (error) {
    alert("Error saving ref files. Please contact support.")
  }
}

function* handleSaveRefFileIdb(action) {
  try {
    yield call(saveRefFileToIdb, action.payload);
    yield put(doSaveRefFileSuccess());
  } catch (error) {
    yield put(doSaveRefFileError(error));
  }
}

function* handleDeleteRefFile(action) {
  try {
    yield call(deleteRefFileIdb, action.id);
    yield put(doDeleteRefFileSuccess());
  } catch (error) {
    yield put(doDeleteRefFileError(error));
  }
}

function* handleDeleteAllRefFiles() {
  try {
    yield call(deleteAllRefFilesIdb);
    yield put(doDeleteAllRefFilesSuccess());
  } catch (error) {
    yield put(doDeleteAllRefFilesError(error));
  }
}

export {
  //handleFetchRefFileApi,
  handleFetchRefFilesIdb,
  handleSaveRefFileIdb,
  handleDeleteRefFile,
  handleDeleteAllRefFiles,
  handleFetchRefsGraphql,
  handleFetchRefCombinationsGraphql,
  handleSaveBulkRefFiles,
  handleRefFileSelectOnElogLoad,
  handleRefFileSelectOnRefSync
};
