import {
  REFFILES_FROM_GRAPHQL,
  REFFILE_LOAD,
  REFFILE_FETCH_ERROR,
  REFFILE_FETCH_SUCCESS,
  REFFILES_FROM_IDB,
  REFFILES_FROM_IDB_SUCCESS,
  REFFILE_SELECT,
  REFFILE_DELETE,
  CHANGE_PAGE,
  CHANGE_PRODUCT,
  REFFILE_TO_IDB,
  REFFILE_TO_IDB_SUCCESS,
  REFFILE_TO_IDB_ERROR,
  IMPORT_REF_STATE,
  REFFILE_COMBINATIONS_FROM_GRAPHQL_SUCCESS,
  REFFILE_COMBINATIONS_FROM_GRAPHQL_ERROR,
  REFFILE_COMBINATIONS_FROM_GRAPHQL,
  REFFILE_DELETE_ALL,
  REFFILE_BULK_ADD,
} from "../constants/actionTypes";

const INITIAL_STATE = {
  refFiles: {},
  currentRefId: null,
  fetchingRefFiles: false,
  fetchingRefFilesIdb: false,
  savingRefFiles: false,
  currentPage: null,
  currentProduct: null,
  saveError: false,
  fetchError: null,
  fetchingRefFileCombinations: false,
  refFileCombinations: {},
  fetchRefCombinationsError: null,
};

const applyFetchRefFiles = (state) => {
  return {
    ...state,
    fetchingRefFiles: true,
  };
};

const applyFetchRefFilesSuccess = (state) => {
  return {
    ...state,
    fetchingRefFiles: false,
    fetchError: null,
  };
};

const applyFetchRefFilesError = (state, action) => {
  return {
    ...state,
    fetchingRefFiles: false,
    fetchError: action.error,
  };
};

const applyGetAllRefFiles = (state) => ({
  ...state,
  fetchingRefFilesIdb: true,
});

const applyGetAllRefFilesSuccess = (state, action) => {
  //converts array of objects to single object with id as key
  const refRepo = Object.assign(
    {},
    ...action.refFiles.map((refFile) => ({ [refFile["id"]]: refFile }))
  );

  const refKeys = Object.keys(refRepo);
  let largestKey = null;

  if (refKeys.length > 0) {
    largestKey = Math.max(...refKeys);
  }

  return {
    ...state,
    refFiles: refRepo,
    currentRefId: largestKey, //set to largest key as per refreshRefFiles()
    fetchingRefFilesIdb: false,
  };
};

const applyLoadRefFile = (state, action) => ({
  ...state,
  currentRefId: action.payload.id,
  refFiles: {
    ...state.refFiles,
    [action.payload.id]: action.payload,
  },
});

const applyLoadBulkRefFiles = (state, action) => ({
  ...state,
  currentRefId: action.payload.selectedRef,
  refFiles: action.payload.refFiles,
});

const applySelectRefFile = (state, action) => ({
  ...state,
  currentRefId: action.id,
});

const applyChangePage = (state, action) => ({
  ...state,
  currentPage: action.id,
});

const applyChangeProduct = (state, action) => {
  return {
    ...state,
    currentProduct: action.id,
  };
};

const applyDeleteRefFile = (state, action) => {
  let clone = Object.assign({}, state.refFiles);
  delete clone[action.id];

  return {
    ...state,
    refFiles: clone,
    currentRefId: null,
  };
};

const applyDeleteAllRefFiles = (state) => {
  return {
    ...state,
    refFiles: {},
    currentRefId: null,
  };
};

const applySaveRefFile = (state) => ({
  ...state,
  savingRefFiles: true,
});

const applySaveRefFileSuccess = (state) => ({
  ...state,
  saveError: false,
  savingRefFiles: false,
});

const applySaveRefFileError = (state) => ({
  ...state,
  saveError: true,
  savingRefFiles: false,
});

const applyImportState = (action) => {
  return action.payload;
};

const applyFetchRefFileCombinationsSuccess = (state, action) => ({
  ...state,
  fetchingRefFileCombinations: false,
  refFileCombinations: action.payload,
});

const applyFetchRefFileCombinationsError = (state, action) => ({
  ...state,
  fetchingRefFileCombinations: false,
  fetchRefCombinationsError: action.payload,
});

const applyFetchRefFileCombinations = (state) => ({
  ...state,
  fetchingRefFileCombinations: true,
});

function refFileReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REFFILE_COMBINATIONS_FROM_GRAPHQL_SUCCESS: {
      return applyFetchRefFileCombinationsSuccess(state, action);
    }

    case REFFILE_COMBINATIONS_FROM_GRAPHQL_ERROR: {
      return applyFetchRefFileCombinationsError(state, action);
    }

    case REFFILE_COMBINATIONS_FROM_GRAPHQL: {
      return applyFetchRefFileCombinations(state, action);
    }

    case REFFILES_FROM_GRAPHQL: {
      return applyFetchRefFiles(state, action);
    }
    case REFFILE_FETCH_SUCCESS: {
      return applyFetchRefFilesSuccess(state, action);
    }
    case REFFILE_FETCH_ERROR: {
      return applyFetchRefFilesError(state, action);
    }
    case REFFILE_LOAD: {
      return applyLoadRefFile(state, action);
    }
    case REFFILE_BULK_ADD: {
      return applyLoadBulkRefFiles(state, action);
    }
    case REFFILE_SELECT: {
      return applySelectRefFile(state, action);
    }
    case REFFILE_DELETE: {
      return applyDeleteRefFile(state, action);
    }
    case REFFILE_DELETE_ALL: {
      return applyDeleteAllRefFiles(state, action);
    }
    case REFFILES_FROM_IDB: {
      return applyGetAllRefFiles(state, action);
    }
    case REFFILES_FROM_IDB_SUCCESS: {
      return applyGetAllRefFilesSuccess(state, action);
    }
    case REFFILE_TO_IDB_SUCCESS: {
      return applySaveRefFileSuccess(state, action);
    }
    case REFFILE_TO_IDB_ERROR: {
      return applySaveRefFileError(state, action);
    }
    case REFFILE_TO_IDB: {
      return applySaveRefFile(state, action);
    }
    case CHANGE_PAGE: {
      return applyChangePage(state, action);
    }

    case CHANGE_PRODUCT: {
      return applyChangeProduct(state, action);
    }

    case IMPORT_REF_STATE: {
      return applyImportState(action);
    }

    default:
      return state;
  }
}

export default refFileReducer;
