import React from "react";
import { connect } from "react-redux";
import metadata from "../metadata.json";

class HeaderBanner extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() { }

  render() {
    const currentElog = this.props.currentElog;

    const refFiles = this.props.refFiles;
    const currentRefId = this.props.currentRefId;

    let refName = "None";

    if (this.props.currentRefId) {
      //const prefMap = refFiles[currentRefId].refFile.PREFfile
      refName = refFiles[currentRefId].name;
    }

    // if (currentRef.name) {
    //   refName = currentRef.name
    // }

    let elogHeaderSection = <></>;

    if (currentElog) {
      if (currentElog.header) {
        elogHeaderSection = (
          <>
            <p className="header-elog">eLog:{currentElog.header.PlodNo}</p>
            <p>RefFile: {refName}</p>
            <hr />
            <p>
              {new Date(currentElog.created)
                .toLocaleString("en-AU", {
                  weekday: "short",
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                })
                .replace(",", "")}
            </p>
            <p>Shift: {currentElog.header.Shift}</p>
            <p>Status: {currentElog.header.Status}</p>
            <hr />
            <p>Job: {currentElog.header.Job}</p>
            <p>Rig: {currentElog.header.Rig}</p>
          </>
        );
      }
    }

    let indicator = this.props.indicator;

    return (
      <header
        style={
          process.env.REACT_APP_HOST_ENV !== "prod"
            ? { backgroundColor: "#005bff" }
            : {}
        }
        className="App-header"
      >
        <div className="left">
          <div className="title-version-container">
            <h1 className="title">UDE</h1>
            <p className="version">{`Ver ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision}`}</p>
          </div>

          {indicator}
        </div>

        <div className="right">{elogHeaderSection}</div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    refFiles: state.refFileState.refFiles,
    currentRefId: state.refFileState.currentRefId,
    isAuthenticated: state.authState.auth.isAuthenticated,
    isLoading: state.authState.auth.isLoading,
    authEmail: state.authState.auth?.user?.email,
    authToken: state.authState?.token,
    //prefMap: state.refFileState.refFiles[state.refFileState.currentRefId].refFile.PREFfile,
  };
};

export default connect(mapStateToProps, null)(HeaderBanner);
