import { combineReducers } from "redux";
import elogReducer from "./elog";
import refFileReducer from "./refFile";
import globalReducer from "./global";
import priorEntryReducer from "./priorEntry";
import authReducer from "./auth";
import adminPanelReducer from "./adminPanel";

const rootReducer = combineReducers({
  elogState: elogReducer,
  refFileState: refFileReducer,
  priorEntryState: priorEntryReducer,
  globalState: globalReducer,
  authState: authReducer,
  adminPanelState: adminPanelReducer,
});

export default rootReducer;
