import { PlodDb } from "../services/DBService";
import { getApiHeaders, getApiUrl } from "../services/Utilities";

const API_URL = getApiUrl();

const fetchRefsGraphql = (token, shiftDetails) => {
  let query = "query GetRefFiles @cached { ref_files { ref_file_data, ref_file_name, updated_at } }";
  let variables = {};

  if (shiftDetails) {
    // query = `query GetRefFiles($job: String!, $rig: String!, $shift: String!) {
    //   ref_files(where: {ref_file_shift: {_eq: $shift}, ref_file_rig: {_eq: $rig}, ref_file_job: {_eq: $job}}) {
    //     ref_file_data
    //     ref_file_name
    //     created_at
    //   }
    // }`;

    // query = `query GetRefFilesByShift($job: String!, $rig: String!, $shift: String!) {
    //   ref_files(where: {
    //     _and: [

    //       {_or: [{ref_file_shift: {_eq: $shift}}, {ref_file_shift: {_is_null: true}}]},
    //       {_or: [{ref_file_rig: {_eq: $rig}}, {ref_file_rig: {_is_null: true}}]},
    //       {_or: [{ref_file_job: {_eq: $job}}, {ref_file_job: {_is_null: true}}]},

    //     ]
    //   }) {
    //     ref_file_data,
    //     ref_file_name,
    //     created_at,
    //   }
    // }`;

    query = `query GetRefFilesByShift($job: String!, $rig: String!) {
      ref_files(where: {
        _and: [
          {_or: [{ref_file_rig: {_eq: $rig}}, {ref_file_rig: {_is_null: true}}]},
          {_or: [{ref_file_job: {_eq: $job}}, {ref_file_job: {_is_null: true}}]},
        
        ]
      }) {
        ref_file_data,
        ref_file_name,
        created_at,
      }
    }`;

    variables = {
      job: shiftDetails.job,
      rig: shiftDetails.rig,
      shift: shiftDetails.shift,
    };
  }

  return fetch(API_URL, {
    method: "POST",
    headers: getApiHeaders(token),
    body: JSON.stringify({ query, variables }),
  })
    .then((res) => res.json())
    .then((res) => res);
};

const fetchRefCombinationsGraphql = (token) =>
  fetch(API_URL, {
    method: "POST",
    headers: getApiHeaders(token),
    body: JSON.stringify({
      query: `{ref_files(distinct_on: [ref_file_job, ref_file_rig, ref_file_shift]) {
            ref_file_job
            ref_file_rig
            ref_file_shift
          }}`,
    }),
  })
    .then((res) => res.json())
    .then((res) => res?.data?.ref_files);

const fetchRefFilesFromIdb = () =>
  PlodDb.getAll("ref_files").then((allRefFiles) => allRefFiles);

const saveRefFileToIdb = (payload) =>
  PlodDb.put("ref_files", payload, parseInt(payload.id));

const deleteRefFileIdb = (id) => PlodDb.delete("ref_files", parseInt(id));

const deleteAllRefFilesIdb = () => PlodDb.deleteAll("ref_files");

const saveBulkRefFiles = (refFiles) => { PlodDb.putMany("ref_files", refFiles) };

export {
  fetchRefFilesFromIdb,
  saveRefFileToIdb,
  deleteRefFileIdb,
  deleteAllRefFilesIdb,
  fetchRefsGraphql,
  fetchRefCombinationsGraphql,
  saveBulkRefFiles,
};
